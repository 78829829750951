import React, { Component } from 'react';
import './nmapintroduction.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class NMAPIntroduction extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper NMAPIntroduction" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>NETWORK ENUMERATION WITH NMAP</b>
                                </p>




                                <p className='c9 list-items image-on-page-nmap-intro'>
                                    <b>היכרות</b>
                                    <span style={{ fontSize: '17pt' }}>התוכנה נכתבה על ידי ליאון גורדון המכונה בשם פיודור.</span>
                                    <span style={{ fontSize: '17pt' }}>נכתבה ב ++C, עם הרחבות בשפות נוספות: C, Perl & Python…</span>
                                    <span style={{ fontSize: '17pt' }}>התוכנה היא חינמית, ניתנת לפיתוח בתור מקור פתוח</span>
                                    <span style={{ fontSize: '17pt' }}>מככבת בסרטים : matrix reloaded, Die Hard 4, The Bourne ult</span>
                                    <span style={{ fontSize: '17pt' }}>גמישה, חזקה, ניידת, קלה לשימוש, מתועדת היטב</span>
                                    <span style={{ fontSize: '17pt' }}>זמינה ללינוקס, Windows ומקינטוש</span>
                                    <span style={{ fontSize: '17pt' }}>הוא נועד לסרוק רשתות ולזהות אילו מארחים זמינים ברשת</span>
                                    <span style={{ fontSize: '17pt' }}>שירותים ויישומים, כולל שם וגרסה</span>
                                    <span style={{ fontSize: '17pt' }}>לזהות את מערכות ההפעלה והגרסאות של מארחים אלה.</span>
                                    <span style={{ fontSize: '17pt' }}>יכולת לקבוע אם  חומות אש או מערכות זיהוי פריצה (IDS) מוגדרים לפי הצורך.</span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                <p className="c5"><span className="c0"></span></p>




                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 10
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
