import React, { Component } from 'react';
import './BettercapModulesHelp.css';
import companyLogo from './../../../../../../assets/Images/bettercap.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class BettercapModulesHelp extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapModulesHelp" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap Modules Help</b>
                                </p>


                                <p className="c9 image-on-page-bettercap-modules-help">
                                    <b>קבלת עזרה על Modules</b>
                                    <span className="c4">
                                        על מנת לקבל עזרה על מודול ספציפי, נקיש help מלווה בשמו של המודל שעליו נרצה לקבל מידע כיצד לתפעלו.
                                    </span>
                                </p>

                                <p className="c9" >
                                    <span className="c4">
                                        נוכל לראות שמודל זה בודק מתארחים חדשים שישנם ברשת על ידי שליחת UDP, נוכל להפעיל את המודל הזה על ידי אתחול
                                    </span>
                                    <span className="c4" style={{ display: 'inline-block !important', padding: '0px 5px' }}>&nbsp;&nbsp;</span>
                                    <span className="c4" style={{ textAlign: 'left', direction: 'ltr', backgroundColor: 'black', color: 'white' }}>net.probe on</span>
                                    <span className="c4" style={{ display: 'inline-block !important', padding: '0px 5px' }}>&nbsp;&nbsp;</span>
                                    <span className="c4">או להפסיקו עם</span>
                                    <span className="c4" style={{ display: 'inline-block !important', padding: '0px 5px' }}>&nbsp;&nbsp;</span>
                                    <span className="c4" style={{ textAlign: 'left', direction: 'ltr', backgroundColor: 'black', color: 'white' }}>net.probe off</span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9" >
                                    <span className="c4">
                                        ישנם גם רשימה של פרמטרים שנוכל לאתחל עם ערכים שונים טרם הפעלת המודל.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                               
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 8
                                    let subjectsAndSubTopicsCompletedIndex = 4
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
