import React, { Component } from 'react';
import './proxychainsconfiguration.css';
import companyLogo from './../../../../../assets/Images/proxy-chains.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class ProxychainsConfiguration extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ProxychainsConfiguration" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>התצורה של Proxychains</b>
                                </p>

                                <p className="c9" >
                                    <span className="c0  c15">
                                        עלינו לבצע כמה שינויים בקבצי התצורה המשמשים את רשתות ה-proxy. הקובץ נמצא בכתובת <span style={{ display: 'inline-block', fontWeight: 'bold' }}>/etc/proxychains.conf/</span>  פתח אותו בעורך טקסט:
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                <p>
                                    <span style={{ display: 'inline-block', color: '#008080' }}>
                                        פתח את הקובץ  proxychains4.conf אשר נמצא בתיקיית etc  :
                                    </span>
                                </p>
                                <p>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="gedit etc/proxychains4.conf" newline="/home/moshe" />
                                </p>

                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>אפשר את התכונה Dynamic Chains על ידי הסרת ה - #</span>
                                    <span style={{ fontSize: '17pt' }}>דאג שהאפשרויות strict_chain, round_robin_chain ו - random_chain יהיו בהערה (סימן ה -# יהיה לפניהם).</span>
                                    <span style={{ fontSize: '17pt' }}>מנע דליפות DNS על ידי הסרת ה - # מ - “proxy_dns”</span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9 proxycahins-servers">
                                    <span className="c4">
                                        ניתן להשתמש בשרתי פרוקסי רבים הכנס לקישור:
                                    </span>
                                    <a href="https://free-proxy-list.net" target='_blank'> https://free-proxy-list.net</a>
                                    <a href="https://www.proxynova.com/proxy-server-list/" target='_blank'>https://www.proxynova.com/proxy-server-list/</a>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 6
                                    let subjectsAndSubTopicsCompletedIndex = 6
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
