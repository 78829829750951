import React, { Component } from 'react';
import './nexpose.css';
import companyLogo from './../../../../../../assets/Images/nexpose-tr.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Nexpose extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper Nexpose" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Nexpose Vulnerability Scanner</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        Nexpose הוא כלי סריקת פגיעות מקיף וקל לשימוש כדי לגלות ולהעריך נקודות תורפה ברחבי הרשת, היישומים והמכשירים שלך. באמצעות Nexpose, אתה יכול לתעדף מאמצי תיקון ולצמצם פריצת מידע יקר.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        Rapid7 היא החברה אשר פיתחה את הכלי הזה ועוד כלים נוספים שתפגוש בהמשך המודולים.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 links">
                                    <a href="https://www.rapid7.com/products/nexpose/download/" target='_blank'>https://www.rapid7.com/products/nexpose/download/</a>
                                </p>


                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 21
                                    let completedIndex = 9
                                    let subjectsAndSubTopicsCompletedIndex = 4
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
