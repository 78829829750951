import React from "react";
import './heading.css';
import Login from './../Login/Login';
import TopMenu from './../TopMenu/TopMenu';
import { Consumer } from "../Context";
export default function Header() {

    return (
        <div className='header'>
            <Login />
            <div className="heading-text">
                <Consumer>
                    {
                        c => {
                            return(<p>{c.pageTitle}</p>)
                        }
                    }
                </Consumer>
                
            </div>

            <TopMenu />
           
        </div>
    );
}