import React, { Component } from "react";
import './topicviewer.css';

import TopicViewerSideBar from "./TopicViewerSideBar/TopicViewerSideBar";
import Questionnaire from './../Questionnaire/Questionnaire'

import { Consumer } from './../Context'

export default class TopicViewer extends Component {

    render() {
        return (

            <Consumer>

                {
                    c => {

                        let docsSubjectAndTopics = c.subjectsAndSubTopics.filter((item, index) => item.section == 'documentation' && item.subIndex == c.choosenCourseSubIndex)
                        let ComponentName = docsSubjectAndTopics.map((item, index) => item.allComponents[0])
                        // console.log(ComponentName, c.choosenCourseSubIndex);
                        ComponentName = ComponentName[0]
                        
                        return (

                            <div className="topicviewer-container">

                                {


                                }
                                <div className="page-content">
                                    {/* <Questionnaire /> */}
                                    {(c.documentSubTopic != '') ? <c.componentName /> : <ComponentName />}
                                </div>
                                <TopicViewerSideBar />
                            </div>

                        )

                    }
                }
            </Consumer >
        )
    }
}