import React, { Component } from 'react';
import './chown.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class CHOWN extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column users-chown">
                                <h4>שינוי בעלים - chown</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b></b>
                                    <span>
                                        כדי לשנות את הבעלים ו / או את המטלות הקבוצתיות של קובץ או ספריה, נוכל להשתמש בפקודה chown. התחביר הוא כדלקמן:
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chown <user>:<group> <file/directory>" />
                                <p>
                                    <span>
                                        בדוגמה זו, ניתן להחליף את "מעטפת" בכל קובץ או תיקיה שרירותיים.
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chown root:root shell && ls -l shell" />
                                <p>
                                    <b>SUID & GUID</b>
                                    <span>
                                        מלבד הקצאת הרשאות ישירות של משתמשים וקבוצות, אנו יכולים גם להגדיר הרשאות מיוחדות לקבצים על ידי הגדרת הביטים (Set User ID (SUID ו- Set Group ID) (GUID). סיביות SUID / GUID אלה מאפשרות, למשל, למשתמשים להריץ תוכניות עם זכויות של משתמש אחר. מנהלים משתמשים לרוב בכך כדי להעניק למשתמשים שלהם זכויות מיוחדות ליישומים או קבצים מסוימים. האות "s" משמשת במקום "x". בעת ביצוע תוכנית כזו, משתמשים ב- SUID / GUID של בעל הקובץ
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 8
                                        let completedIndex = 53
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
