import React, { Component } from 'react';
import './scanningandanalysis.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import ShellTerminal from '../../../../Linux/Shell/ShellTerminal/ShellTerminal';
export default class ScanningAndAnalysis extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ScanningAndAnalysis" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>ביצוע סריקה בפועל וניתוח התוצאות</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        את המכונה של metasploitable2 נגדיר עם הגדרות רשת של bridge, הבה נאמר שכתובת ה IP היא: 192.168.1.163
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>
                                <p className='image-on-page-nmap-metasploit'>
                                    <b>הבה נסרוק את סטטוס הפורטים:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nmap 192.168.1.163" newline="/home/moshe" />
                                </p>

                                <p className="c9">
                                    <span className="c4">
                                        <b style={{ display: 'inline-block', color: 'green' }}>פתוח</b> זה אומר שישנו תהליך שרץ על הפורט הזה (בקשת http זוכרים)
                                        <span></span>
                                        <b style={{ display: 'inline-block', color: 'red' }}>סגור</b> זה אומר שאין תהליך שרץ על הפורט הזה
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        אם נאפשר חומת אש במכונה הפרוצה נראה גם סטטוס של מסונן (<b style={{ display: 'inline-block', color: 'red' }}>Filtered</b>)
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className='image-on-page-nmap-metasploit-ufw'>

                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo ufw enable" newline="/home/moshe" />
                                </p>

                                <p>
                                    <b>ביטול חומת האש</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo ufw disable # will stop the firewall" newline="/home/moshe" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 14
                                    let subjectsAndSubTopicsCompletedIndex = 4
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
