import React, { Component } from "react";
import './Clock.css'

import { Consumer } from "../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Clock extends Component {

    constructor(props) {

        super(props);

        this.time = "00:00"
        this.seconds = 0;
        this.minutes = 0;
        this.t = '';

        // this.timer = document.getElementById('timer')

        // 
    }

    componentDidMount() {

        this.timer = document.getElementById('timer')
        this.timer.textContent = this.time;



    }

    startClock = () => {

        clearTimeout(this.t);
        this.t = setInterval(this.buildTimer, 1000);

    }

    buildTimer = () => {
        this.seconds++;
        if (this.seconds >= 60) {
            this.seconds = 0;
            this.minutes++;
            if (this.minutes >= 60) {
                this.minutes = 0;
                this.seconds = 0;
            }
        }
        this.timer.textContent = (this.minutes < 10 ? "0" + this.minutes.toString() : this.minutes) + ":" + (this.seconds < 10 ? "0" + this.seconds.toString() : this.seconds);
    }

    stopTimer = () => {
        clearTimeout(this.t);
    }

    resetTimer = () => {

        this.timer.textContent = this.time;
        this.seconds = 0; this.minutes = 0;
    }

    render() {


        return (
            <Consumer>
                {
                    c => {
                        
                        if (c.startTest) this.startClock()
                        
                        if (c.stopTest) this.stopTimer()

                        return (
                            <div id="clock-container" >

                                <div id="timer">00:00</div>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
