import React, { Component } from 'react';
import './thebasicsofscanning.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import ShellTerminal from '../../../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class TheBasicsOfScanning extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper TheBasicsOfScanning" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>יסודות הסריקה - TCP 3-Way Handshake</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        על מנת שהלקוח או השרת יתקשרו ביניהם צריכה להיות מה שמכונה לחיצת יד משולשת, שבה :
                                    </span>
                                </p>

                                <p className='c9 list-items image-on-page-nmap-3-way-handshake'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>הלקוח שולח בקשת התחברות (SYN)</span>
                                    <span style={{ fontSize: '17pt' }}>השרת הגיב לבקשת התחברות (SYN ACK)</span>
                                    <span style={{ fontSize: '17pt' }}>הלקוח מאשר את התגובה של השרת (ACK)</span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        שלוש החבילות המהוות את לחיצת היד המשולשת עוברות כנדרש בין הצדדים ויוצרות חיבור TCP שיכול לשמש להעברת נתונים בין השניים.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        <b style={{ display: 'inline-block' }}>NMAP</b> משתמש בסריקה שלו עם לחיצת יד משולשת על מנת לסרוק את מצב הפורטים של המכונות שעליהם אנו מבצעים סריקות.
                                    </span>
                                </p>
                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>אם היעד שלנו שולח חבילת סימון SYN-ACK בחזרה ליציאה הסרוקה, Nmap מגלה שהיציאה פתוחה.</span>
                                    <span style={{ fontSize: '17pt' }}>אם החבילה מקבלת דגל RST, זה אינדיקטור שהיציאה סגורה.</span>
                                    <span style={{ fontSize: '17pt' }}>אם Nmap לא מקבל חבילה בחזרה, הוא מציג אותה כמסוננת. בהתאם לתצורת חומת האש, חבילות מסוימות עשויות להישמט על ידי חומת האש.</span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className='image-on-page-nmpa-output-0'>
                                    <b>הבה נריץ את הפקודה, נתאר את הפלט</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nmap 192.168.1.1" newline="/home/moshe" />
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        בדוגמא זו אנו יכולים לראות שיש לנו ארבע יציאות TCP שונות פתוחות.
                                    </span>
                                </p>

                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>בעמודה הראשונה אנו רואים את<b style={{ display: 'inline-block' }}>מספר היציאה</b></span>
                                    <span style={{ fontSize: '17pt' }}>ואז, בטור השני, אנו רואים את<b style={{ display: 'inline-block' }}>סטטוס השירות</b></span>
                                    <span style={{ fontSize: '17pt' }}>ואז בטור השלישי<b style={{ display: 'inline-block' }}>איזה סוג שירות הוא</b></span>
                                </p>



                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        כשאנחנו צריכים לערוך בדיקת חדירה פנימית לכל הרשת של החברה, למשל, אז עלינו, קודם כל, לקבל סקירה של אילו מערכות מקוונות שנוכל לעבוד איתן. כדי לגלות מערכות כאלה באופן פעיל ברשת, אנו יכולים להשתמש באפשרויות גילוי מארח Nmap שונות. ישנן אפשרויות רבות ש- Nmap מספק כדי לקבוע אם היעד שלנו חי או לא. השיטה היעילה ביותר לגילוי המארח היא שימוש בבקשות הד ICMP, אותן נבדוק.
                                    </span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 12
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
