import React, { Component } from 'react';
import './numericalrepresentation.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class NumericalRepresentation extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ugo-num">
                                <div className='ugo-num-result'>
                                    <h4>יצוג מספרי של ההרשאות</h4>
                                    <div className="img-div"><img src={companyLogo} /></div> 
                                    <p>
                                        <span>
                                            כפי שכבר ראינו ישנן 3 סוגי הרשאות, אבל דע לך שיש להן גם יצוג מספרי
                                        </span>
                                        <p>

                                            <span className='ltr'> (<span className='bold green medium-text'>r</span>) - Read  :  4</span>
                                            <span className='ltr'> (<span className='bold green medium-text'>w</span>) - Write :  2</span>
                                            <span className='ltr'>(<span className='bold green medium-text'>x</span>) - Execute : 1</span>
                                            <span>ראינו שישנם 3 ישויות -  המשתמש, הקבוצה, ואחרים אם נכתוב זאת באנגלית : <span className='bold green medium-text'>User Group Others</span></span>
                                            <span>ראשי תיבות : <span className='bold green medium-text'>UGO</span> (זה כמעט נכתב כמו הדמות HUGO שמופיעה בטרמינל)</span>
                                            <span>אז שלושת התווים הראשונים הם ההרשאות של המשתמש</span>
                                            <span>ו - שלושת התווים האמצעיים הם ההרשאות של הקבוצה</span>
                                            <span>ו - שלושת התווים האחרונים הם ההרשאות של אחרים</span>
                                        </p>


                                    </p>
                                </div>
                                <div className='linux-permisssions-numbers-image'></div>
                                <div className='ugo para-after-image'>

                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 4
                                        let completedIndex = 19
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
