import React, { Component } from 'react';
import './lookarounds.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare, faDisplay } from '@fortawesome/free-solid-svg-icons'
import RegExCompnent from '../RegExCompnent/RegExCompnent';

export default class Lookarounds extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper lookarounds" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>Lookarounds in Regular Expression</b>
                                    <span>
                                        מראה כללי של ביטויים רגילים מאפשרים לך להתאים דפוס רק אם אחריו או לפניו דפוס אחר.
                                    </span>
                                    <span>
                                        כפי שהשם מרמז, מראה סביב יכול להיות להסתכל קדימה או להסתכל מאחור. ישנם ארבעה סוגים של מבטים: מבט חיובי, מבט שלילי, מבט חיובי, מבט שלילי.
                                    </span>
                                </p>
                                <p>
                                    <b>Positive Lookahead</b>
                                    <span>
                                        מבט חיובי תואם את הדפוס רק אם אחריו או לפניו דפוס אחר. הם מסומנים בתחביר <span style={{ direction: 'ltr', display: 'inline-block' }}>x(?=y)</span>, שבו כתוב מצא את x שאחריו מופיע תבנית y.
                                    </span>
                                    <span>
                                        אתה יכול לחשוב על זה כעל דפוס "אחריו".
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="23" />

                                <p>
                                    <span>
                                        הבא מוצא כל תו מילה שאחריו תו רווח לבן.
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="24" />

                                <p>
                                    <b>Negative Lookahead</b>
                                    <span>
                                        מבט שלילי הוא ההפך ממבט חיובי. הוא תואם לתבנית רק אם לא עוקב אחריו או קדם לו דפוס אחר. הם מסומנים בתחביר <span style={{ direction: 'ltr', display: 'inline-block' }}>x(?!y)</span>, שבו כתוב למצוא את x שאחריו לא מופיע תבנית y.
                                    </span>
                                    <span>
                                        אתה יכול לחשוב על זה כעל דפוס "לא אחריו".
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="25" />
                                <p>
                                    <span>
                                        ההודעה הבאה מוצאת כל מילה שאין אחריה סימן שאלה (?)
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="26" />
                                <p>
                                    <span>
                                        בדוגמה שלמעלה, \b משמש לגבול מילה, \w+ משמש לתווית מילה אחת או יותר, אז \b\w+\b מוצא את המילה כולה. דפוס, \? משתמש ברצף בריחה \ עבור ? מכיוון שהוא מטא-תו ב-Regex, אז כדי לגלות מילולית ?, עלינו להשתמש בתו בריחה.
                                    </span>
                                </p>
                                <p>
                                    <b>Positive Lookbehind</b>
                                    <span>
                                        מבט חיובי מאחור מתאים לתבנית רק אם קודמת לה דפוס אחר אך אינו כולל את התבנית הקודמת בתוצאת ההתאמה. הם מסומנים בתחביר <span style={{ direction: 'ltr', display: 'inline-block' }}>(?&lt;=y)x</span>, שבו כתוב למצוא את x שלפניו y.
                                    </span>
                                    <span>
                                        אתה יכול לחשוב על זה כעל דפוס "קודם לו".
                                    </span>

                                </p>

                                <RegExCompnent key={React.key} patternIndex="27" />
                                <p>

                                    <span>
                                        ההודעה הבאה מוצאת כל מילה שאין אחריה סימן שאלה (?)
                                    </span>

                                </p>
                                <RegExCompnent key={React.key} patternIndex="28" />
                                <p>
                                    <span>
                                        בדוגמה לעיל, הוא מוצא מילה שלפניה תו הרווח הלבן. <span style={{ direction: 'ltr', display: 'inline-block' }}>b\w+\b</span> מוצא את כל המילה ו-\s הוא עבור תו רווח לבן.
                                    </span>
                                </p>
                                <p>
                                    <b>Negative Lookbehind</b>
                                    <span>
                                        מבט שלילי מאחור מתאים לתבנית רק אם לא קודמת לה דפוס אחר. הם מסומנים בתחביר <span style={{ direction: 'ltr', display: 'inline-block' }}>(?&lt;!y)x</span>, שבו כתוב למצוא את x שאין לפניו y.
                                    </span>
                                    <span>
                                        אתה יכול לחשוב על זה כעל דפוס "לא קדם לו".
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="29" />
                                <p>

                                    <span>
                                        ההודעה הבאה מוצאת כל מילה שאין אחריה סימן שאלה (?)
                                    </span>

                                </p>
                                <RegExCompnent key={React.key} patternIndex="30" />
                                <p>
                                    <span>
                                        בדוגמה שלמעלה, הוא מוצא מילה שלא מקדימה אותה תו רווח לבן.<span style={{ direction: 'ltr', display: 'inline-block' }}>b\w+\b</span>  מוצא את כל המילה ו-<span style={{ direction: 'ltr', display: 'inline-block' }}>\s</span> הוא עבור תו הרווח הלבן.
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 11
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
