import React, { Component } from 'react';
import './sed.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Sed extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column sed">
                                <h4>סינון תוכן - sed</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>sed</b>
                                    <span>
                                        יבואו רגעים בהם נרצה לשנות שמות ספציפיים בכל הקובץ או בקלט הסטנדרטי. אחד הכלים בהם אנו יכולים להשתמש לצורך כך הוא עורך הזרמים הנקרא sed. אחד השימושים הנפוצים ביותר בכך הוא החלפת טקסט. כאן, sed מחפש דפוסים שהגדרנו בצורה של ביטויים רגולריים (regex) ומחליף אותם בתבנית אחרת שהגדרנו גם כן. בואו נצמד לתוצאות האחרונות ונאמר שאנחנו רוצים להחליף את המילה "basket" ב" webschool ".
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin' | tr ':' ' | awk ' {print $1, $NF}' | sed 's/bin/webschool/g" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 49
                                        let subjectsAndSubTopicsCompletedIndex = 10

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
