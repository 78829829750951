import React, { Component } from 'react';
import './regexintroduction.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "./../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import RegExCompnent from '../RegExCompnent/RegExCompnent';

export default class RegexIntroduction extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        return (
                            <div className="paper regexintroduction" data-sub-index="1">
                                <h4>מה זה ביטוי רגולרי?</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>ביטוי רגולרי</b>
                                    <span>
                                        הידוע גם בשם Regex, הוא כלי רב עוצמה לחיפוש, שינוי ואימות של מחרוזות או נתוני טקסט.
                                    </span>
                                    <span>
                                        חיפוש: Regex מאפשר לך לחפש דפוסים ספציפיים של תווים, כגון מספרי טלפון, כתובות דואר אלקטרוני, תאריכים או כל פורמט אחר בנתוני הטקסט.
                                    </span>
                                    <b>שנה:</b>
                                    <span>
                                        ניתן להשתמש ב-Regex גם כדי לחלץ נתונים מטקסט או להחליף דפוסים מסוימים בטקסט אחר. אתה יכול לציין את הדפוס המדויק שברצונך להתאים, ולאחר מכן לחפש אותו בתוך מחרוזת או קובץ טקסט ולהחליף אותו בטקסט הספציפי. לדוגמה, אתה יכול לחפש את המילה 'שלום' ולהחליף אותה ב'היי' או שאתה יכול לחפש את מספר הטלפון xx-xxxx-xxxx ולהחליף אותו בפורמט xxx-xxx-xxxx.
                                    </span>
                                    <b>אימות:</b>
                                    <span>
                                        ניתן להשתמש בו גם כדי לאמת את הקלט של המשתמש לפני עיבודו. על ידי אימות קלט משתמש, מפתחים יכולים להבטיח שהנתונים המעובדים עומדים בקריטריונים מסוימים, כגון פורמט, אורך או סוג. לדוגמה, אם משתמש נדרש להזין את כתובת האימייל שלו, אימות יכול להבטיח שהקלט מכיל סמל "@" ושם תחום חוקי.
                                    </span>
                                </p>
                                <b>דוגמה לביטוי רגולרי</b>
                                    <RegExCompnent key={React.key} patternIndex="10" />
                                <p>
                                    

                                    <span>
                                        ה-Regex Pattern הוא מחרוזת המגדירה דפוס לחיפוש אימייל הכולל "@" ו-"." (לדוגמה, פורמט xxx@xx.xx). זה יחפש ותקבל את מזהה הדוא"ל ממחרוזת הקלט. מחרוזת הקלט יכולה להיות כל טקסט, או נתונים טקסטואליים.
                                    </span>
                                    <b></b>
                                    <span>
                                        רוב שפות התכנות כוללות את המחלקה Regex ואת הפונקציה Match() אשר מחזירה תוצאה באמצעותה ניתן לחלץ, להחליף, לאמת או להסיר מזהי דוא"ל מנתוני הטקסט.
                                    </span>
                                    <b></b>
                                    <span>
                                        אמנם דפוס הרקס עשוי להיראות מורכב במבט ראשון, אבל אל דאגה. בפרקים הקרובים תלמדו את התחביר להגדרת דפוס בהתאם לדרישות שלכם. אז אל תיבהל מהמורכבות של דפוסי הרקס; עם תרגול וסבלנות, אתה יכול להיות מיומן בשימוש בהם.
                                    </span>
                                    <b>יתרונות השימוש ב-Regex</b>
                                    </p>
                                    <p className='list-items'>
                                        <b></b>
                                        <span>Regex יכול לחסוך לך הרבה זמן ומאמץ בעת חיפוש ומניפולציה של מחרוזות. אחד היתרונות הגדולים ביותר שלו הוא היכולת לחפש דפוסים מורכבים במחרוזת בקלות. זה אומר שאתה יכול להגדיר דפוס פעם אחת ואז להחיל אותו על מחרוזות מרובות, מה שיכול להיות שימושי להפליא בעבודה עם כמויות גדולות של נתונים.</span>
                                        <span>Regex מאפשר לך לבצע פעולות חיפוש מתקדם והחלפה, כגון מציאת כל המופעים של מילה או ביטוי מסוים והחלפתם במשהו אחר.</span>
                                        <span>Regex מקל על קריאה והבנה של דפוסים מסובכים. עם תחביר סטנדרטי המובן באופן נרחב, קל לשתף דפוסים ולשתף פעולה בהם עם מפתחים אחרים.</span>
                                        <span>Regex מספקת דרך קלה לאמת קלט משתמש בהתבסס על קריטריונים ספציפיים, כגון אורך, פורמט, סוג וכו'. זה יכול לשפר את האבטחה והשימושיות של האפליקציה שלך. לדוגמה, אתה יכול להבטיח שסיסמאות עומדות בדרישות מורכבות מסוימות או שכתובות הדוא"ל הן בפורמט הנכון.</span>
                                    </p>
                                    <span>
                                        בסך הכל, אם אתה עובד עם נתוני טקסט על בסיס קבוע, ללמוד כיצד להשתמש ב-Regex יכול להיות מיומנות בעלת ערך להפליא שיחסוך לך זמן ותהפוך את העבודה שלך ליעילה יותר.
                                    </span>
                                    <b>יישום Regex בשפות תכנות</b>
                                    <span>
                                        ישנן שפות תכנות רבות התומכות בביטויים רגולריים, כולל C#, Java, Python, Go ואחרות. הם נמצאים בשימוש נרחב גם בפיתוח אתרים עבור משימות כמו אימות טפסים וניתוח נתונים.
                                    </span>
                                    <b></b>
                                    <span>
                                        דפוסי ביטוי רגולרי (רגקס) הם דרך סטנדרטית לתיאור דפוסי טקסט, כך שהתחביר הבסיסי בדרך כלל עקבי בשפות התכנות. עם זאת, עשויים להיות כמה הבדלים בתחביר הספציפי המשמש עבור תכונות או אפשרויות מסוימות, כמו גם בפונקציות הרגולריות הזמינות. חשוב לעיין בקפידה בתיעוד ובתחביר עבור השפה הספציפית שבה אתה משתמש כדי להבטיח שימוש נכון. לדוגמה, שפות מסוימות עשויות לתמוך בתכונות מתקדמות, באפשרויות או בשינויים, כגון מבטים או מבט אחורי, בעוד שאחרות לא. בנוסף, לשפות מסוימות עשויות להיות הגדרות ברירת מחדל שונות עבור רגישות רישיות או התאמה מרובה שורות.
                                    </span>
                                    <b style={{color:'red'}}>:הערה</b>
                                    <span>
                                        ביטויים רגולריים יכולים להיות מורכבים ולקחת קצת זמן להשתלט עליהם, הם כלי חזק להפליא שיכול לחסוך לך הרבה זמן ומאמץ בטווח הארוך.
                                    </span>
                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 14
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    // console.log(obj);
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
