import React, { Component } from 'react';
import './executionsinoneline.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class ExecutionsInOneLine extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper executionsinoneline">
                                <h4>ביצוע מספר פקודות במקביל</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>שלוש אפשרויות להריץ כמה פקודות, אחת אחרי השנייה.</b>
                                    <span>
                                        ישנן שלוש אפשרויות להריץ כמה פקודות, אחת אחרי השנייה. אלה מופרדים על ידי:
                                    </span>
                                </p>
                                <ol>
                                    <li>Semicolon (;)</li>
                                    <li>Double ampersand characters (&&)</li>
                                    <li>Pipes (|)</li>
                                </ol>
                                <p>
                                    <b>Semicolon (;)</b>
                                    <span>
                                        ההבדל ביניהם נעוץ בטיפול בתהליכים הקודמים ותלוי אם התהליך הקודם הושלם בהצלחה או עם טעויות. נקודה-פסיק (;) היא מפריד פקודות ומבצע את הפקודות על ידי התעלמות מתוצאות הפקודות הקודמות ומהשגיאות.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="echo '1'; echo '2'; echo '3'" />
                                    <span>
                                        לדוגמא, אם נבצע את אותה פקודה אך נחליף אותה במקום השני, הפקודה ls בקובץ שאינו קיים, נקבל שגיאה, והפקודה השלישית בכל זאת תבוצע.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="echo '1'; ls ~; echo '3'" />

                                    <b>Double ampersand characters (&&)</b>
                                    <span>
                                        עם זאת, זה נראה אחרת אם אנו משתמשים בתווים(&&) כדי להריץ את הפקודות אחת אחרי השנייה. אם יש שגיאה באחת הפקודות, הפעולות הבאות לא יבוצעו יותר וכל התהליך יופסק.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="echo '1' && ls ~ && echo '3'" />

                                    <b>Pipes(|)</b>
                                    <span>
                                        צינורות (|) תלויים לא רק בפעולה נכונה ונטולת שגיאות של התהליכים הקודמים אלא גם בתוצאות התהליכים הקודמים. אנו נעסוק בצינורות בהמשך בסעיף מתארי קבצים והפניות מחדש.
                                    </span>
                                    <span>
                                        אנחנו יכולים לעשות את אותו הדבר אם אנחנו רוצים לנווט לספריה. כדי לעבור בספריות, אנו משתמשים ב - cd. בואו נעבור לספרייה dev/ shm/.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cd /dev/shm" />
                                    <span>מכיוון שהיינו בספריית הבית בעבר, נוכל לחזור במהירות לספרייה בה היינו לאחרונה.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cd -" />

                                    <b>פונקציית ההשלמה האוטומטית</b>
                                    <span>
                                        המעטפת מציעה לנו גם את פונקציית ההשלמה האוטומטית, מה שמקל על הניווט. אם כעת נקליד cd /dev/s ואז נלחץ פעמיים על [TAB], נקבל את כל הערכים שמתחילים באות "s" בספריה של /dev/.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cd /dev/test [TAB 2x]" />

                                    <b>לעלות לתיקייה מעלי (תיקיית האב)</b>
                                    <span>
                                        לעלות לספריית האב באמצעות הפקודה הבאה.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cd .." />

                                    <b>ניקוי המעטפת - clear</b>
                                    <span>
                                        מכיוון שה Shell שלנו מלאה בכמה רשומות, אנו יכולים לנקות את shell עם הפקודה clear. עם זאת, בואו נחזור לספרייה / dev / shm לפני כן.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cd shm && clear" />

                                    <b>tree</b>
                                    <span>אנו יכולים להסתכל על כל המבנה לאחר יצירת ספריות האב עם הכלי tree.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="tree ." />
                                    <span className='tree-command'>
                                        <span>.</span>
                                        <span>├── info.txt</span>
                                        <span>└── Storage</span>
                                        <span>└── local</span>
                                        <span>└── user</span>
                                        <span>└── documents</span>
                                        <span>4 directories, 1 file</span>
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 13
                                        let completedIndex = 66
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
