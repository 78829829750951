import React, { Component } from 'react';
import './MITMIntroduction.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class MITMIntroduction extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper MITMIntroduction" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Man In The Middle Attacks Introduction</b>
                                </p>


                                <p className="c9 image-on-page">
                                    <span className="c4">
                                        מתקפת אדם-באמצע (MITM) היא מתקפת סייבר שבה האקר מציב את עצמו באמצע בין שני צדדים, בדרך כלל משתמש ואפליקציה, כדי ליירט את התקשורת וחילופי הנתונים שלהם ולהשתמש בהם למטרות זדוניות כמו ביצוע רכישות לא מורשות או פריצה
                                    </span>
                                </p>

                               
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 23
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
