import React, { Component } from 'react';
import './superuser.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column super-user">
                                <h4>Super User - root</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>root</b>
                                    <span>
                                        במערכות דומות ל-Linux ו-Unix, חשבון משתמש העל, הנקרא 'שורש', הוא למעשה כל יכול, עם גישה בלתי מוגבלת לכל הפקודות, הקבצים, הספריות והמשאבים. Root יכול גם להעניק ולהסיר הרשאות למשתמשים אחרים.
                                    </span>

                                    <b>כיצד להריץ פקודות בתור מנהל מערכת</b>
                                    <span>
                                        ישנן שתי דרכים להפוך למשתמש-על. הראשון הוא להיכנס כ-root ישירות. הדרך השנייה היא לבצע את הפקודה su תוך כדי כניסה לחשבון משתמש אחר. ניתן להשתמש בפקודה su כדי לשנות את החשבון הנוכחי של אדם לחשבון של משתמש אחר לאחר הזנת הסיסמה המתאימה.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="su" />

                                    <b>סיסמה שגוייה</b>
                                    <span>אם תקליד את הסיסמה בצורה שגויה, תקבל הודעת שגיאה ואתה חוזר לשורת הפקודה הרגילה.</span>
                                    <b>יציאה ממשתמש על</b>
                                    <span>אתה יכול לצאת מחשבון משתמש העל באמצעות יציאה או Ctrl-D. אתה יכול להשעות את המעטפת ולהציב אותה ברקע עם הפקודה suspend; אתה יכול לחזור אליו מאוחר יותר באמצעות fg.</span>
                                    <b>ירושת המעטפת</b>
                                    <span>כאשר אתה מפעיל את su, המעטפת החדשה יורשת את הסביבה מסביבת המעטפת הנוכחית שלך במקום ליצור את הסביבה שה-root יקבל לאחר הכניסה.</span>

                                    <div className='warning'>
                                        אַזהָרָה

                                        בניגוד לכמה מערכות הפעלה אחרות, למשתמש-על של יוניקס יש את כל ההרשאות כל הזמן: גישה לכל הקבצים, הפקודות וכו'. לכן, זה לגמרי קל מדי למשתמש-על לקרוס את המערכת, להרוס קבצים חשובים וליצור הרס בשוגג. מסיבה זו, אנשים שיודעים את סיסמת משתמש-על (כולל מנהל המערכת) לא צריכים לעשות את עבודתם השגרתית כמשתמש-על. השתמש בסטטוס משתמש-על רק כאשר יש צורך בכך.

                                    </div>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 8
                                        let completedIndex = 54
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
