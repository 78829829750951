import React, { Component } from 'react';
import './portsstatus.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class PortsStatus extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper PortsStatus" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>מצבי הפורטים</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        יש בסך הכל 6 מצבים שונים עבור פורט סרוק שאנו יכולים להשיג:
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>
                                <div id="nmap-ports-status">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> מצב</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >זה מצביע על כך שהחיבור ליציאה הסרוקה נוצר. חיבורים אלה יכולים להיות חיבורי TCP, דאטאגרמות UDP וכן אסוציאציות SCTP </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> open</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > כאשר היציאה מוצגת כסגורה, פרוטוקול TCP מציין שהחבילה שקיבלנו בחזרה מכילה דגל RST. ניתן להשתמש בשיטת סריקה זו גם כדי לקבוע אם היעד שלנו חי או לא</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>closed </TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >Nmap לא יכול לזהות כראוי אם היציאה הסרוקה פתוחה או סגורה מכיוון שאף תגובה לא מוחזרת מהיעד עבור היציאה או שאנחנו מקבלים קוד שגיאה מהיעד</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> filtered</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מצב זה של יציאה מתרחש רק במהלך סריקת TCP-ACK ומשמעותו שהיציאה נגישה, אך לא ניתן לקבוע אם היא פתוחה או סגורה </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> unfiltered</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > אם לא נקבל תגובה עבור יציאה ספציפית, Nmap תגדיר אותה למצב זה. זה מצביע על כך שחומת אש או פילטר מנות עשויים להגן על היציאה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> open|filtered</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מצב זה מתרחש רק בסריקות סרק של ה- IP ומצביע על כך שלא ניתן היה לקבוע אם היציאה הסרוקה סגורה או מסוננת על ידי חומת אש.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> closed|filtered</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>



                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 13
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
