import React, { Component } from 'react';
import './nmapcmdsyntax.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import ShellTerminal from '../../../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class NMAPCMDSyntax extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper NMAPCMDSyntax" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>תחביר הפקודה</b>
                                </p>

                                <p>
                                    <b></b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nmap <scan types> <options> <target>" newline="/home/moshe" />
                                </p>
                                <p className="c9">
                                    <span className="c4">
                                        אם לא הגדרנו את שיטת הסריקה, ברירת המחדל של סוג הסריקה הינה  (TCP-SYN (-sS והיא גם אחת משיטות הסריקה הפופולריות ביותר. שיטת סריקה זו מאפשרת לסרוק כמה אלפי יציאות בשנייה.
                                    </span>
                                </p>
                                <p>
                                    <b>דוגמה לסוג הסריקה של ברירת המחדל</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nmap 192.168.1.1" newline="/home/moshe" />
                                </p>

                                <p className="c5"><span className="c0"></span></p>


                                <p className="c5"><span className="c0"></span></p>


                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 11
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
