import React, { Component } from 'react';
import './ARPPoisioningPresentation.css';
import companyLogo from './../../../../../../assets/Images/anonymous-messenger.png';


import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class ARPPoisioningPresentation extends Component {



    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper Arp-Poisioning" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} />ARP Poisioning</div>

                                <p>
                                    <b style={{ fontFamily: 'Alfa Slab One', fontSize: "26pt", color: "#ff5722" }}>ARP Poisioning</b>
                                </p>

                                <p className="c9 image-on-page-Arp-Poisioning1">
                                    <span className="c4">
                                        כפי שניתן לראות את הכתובות הפיזיות של כל עמדה ברשת:
                                    </span>
                                </p>


                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>לראוטר ישנה את הכתובת הפיזית R</span>
                                    <span style={{ fontSize: '17pt' }}>לקרבן יש את הכתובת הפיזית V</span>
                                    <span style={{ fontSize: '17pt' }}>להאקר יש את הכתובת הפיזית H</span>
                                </p>
                                <hr />
                                <p className="c9 image-on-page-Arp-Poisioning2">
                                    <span className="c4">
                                        בשלב זה טבלאות המטמון של ARP הן תקינות
                                    </span>
                                </p>

                                <hr />

                                <p className="c9 image-on-page-Arp-Poisioning3 list-items">
                                    <span className="c4">
                                        ההאקר "מרעיל" את טבלאות המטמון של ARP בכך שהוא משנה את המידע בטבלאות הללו:
                                    </span>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>אצל הקרבן הכתובת הפיזית של הראוטר היא של ההאקר</span>
                                    <span style={{ fontSize: '17pt' }}>אצל הראוטר הכתובת הפיזית של הקרבן היא של ההאקר</span>
                                </p>
                                <hr />
                                <p className="c9 image-on-page-Arp-Poisioning4">
                                    <span className="c4">
                                        כך שבכל פעם שהקרבן יוצא לאתר מסויים דרך שער היציאה, הוא בעצם עובר דרך ההאקר, ההאקר מעביר אותו לראוטר, כאשר התגובה מגיעה חזרה מהראוטר לקרבן, היא תגיע קודם להאקר והוא יעביר את ההודעה לקרבן!
                                    </span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 24
                                    let completedIndex = 2
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
