import React, { Component } from 'react';
import './cve.css';
import companyLogo from './../../../../../../assets/Images/dbexploit.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class CVE extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper CVE" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Common Vulnerabilities & Exposures (CVE)</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">

                                        CVE, קיצור של Common Vulnerabilities and Exposures, הוא רשימה של פגמים באבטחת מחשבים שנחשפו בפומבי. כאשר מישהו מפנה ל- CVE, הוא מתכוון לפגם אבטחה שהוקצה לו מספר מזהה CVE.

                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        ייעוץ אבטחה שהונפק על-ידי ספקים וחוקרים מזכיר כמעט תמיד לפחות מזהה CVE אחד. CVEs מסייעים למומחי IT לתאם את מאמציהם לתעדף ולטפל ב פגיעויות אלה כדי להפוך את מערכות המחשב ל - מאובטחות יותר.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>


                                <p className="c9">
                                    <b style={{ direction: 'ltr', textAlign: 'left' }}>
                                        [PROBLEM TYPE] IN [PRODUCT/VERSION] CAUSES [IMPACT] WHEN [ATTACK]
                                    </b>
                                    <p className="c5"><span className="c0"></span></p>
                                    <span className="c4">
                                        [סוג הבעיה] ב-[מוצר/גירסה] גורם ל [השפעה] כאשר [התקפה הזאת מתבצעת]
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9 image-on-page-dbexploit-cve">
                                    <span className="c4">
                                        מסד הנתונים של Exploit הוא מאגר לניצול לרעה ול - הוכחת מושגים ולא לייעוץ, מה שהופך אותו ל - משאב יקר ערך עבור אלה הזקוקים לנתונים הניתנים לפעולה באופן מיידי. מאגר זה מתעדכן מדי יום עם ההגשות האחרונות שנוספו.
                                    </span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 21
                                    let completedIndex = 6
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
