import React, { Component } from "react";
import './questionnaire.css';
import Qustion from "../Qustion/Qustion";
import QuizRadio from "../QuizRadio/QuizRadio";
import Terminal from "../Terminal/Terminal";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Consumer } from './../Context'
import { v4 as uuidv4 } from "uuid"

export default class Questionnaire extends Component {

    render() {
        return (
            <Consumer>
                {
                    //className={(c.qustions.length != 0) ? 'show' : 'hide'}

                    c => {
                        // console.log(c.qustions);
                        if (c.qustions.length !== 0) {
                            if (c.qustions[0].qustionType === 'radio') {
                                let id = c.qustions[0].id
                                let difficulty =  c.qustions[0].difficulty
                                let qustion = c.qustions[c.qustionNumber]
                                return (
                                    <div key={uuidv4()}>
                                        <QuizRadio key={uuidv4()} id={id} qustion={qustion}  difficulty={difficulty} radioButtonValue={c.radioButtonValue} helperText={c.helperText} error={c.error} qustionNumber={c.qustionNumber} correctAnswerIndex={c.correctAnswerIndex} setQustionPoints={c.actions.setQustionPoints} subTopic={c.subTopic} />
                                    </div>
                                )
                            }
                            else
                                return (
                                    <div key={uuidv4()} id="questionnaire-container" className={(c.qustions.length != 0 && c.subTopic != '') ? 'show' : 'hide'}>
                                        <p id="sub-topic">{c.subTopic}</p>
                                        <Table className="styled-table" style={{ width: '100%' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" style={{ width: '2%' }}> מס </TableCell>
                                                    <TableCell align="center" style={{ width: '33%' }}> שאלה </TableCell>
                                                    <TableCell align="center" style={{ width: '32%' }}> תשובה </TableCell>
                                                    <TableCell align="center" style={{ width: '32%' }}> תשובתך </TableCell>
                                                    <TableCell align="center" style={{ width: '2%' }}> צדקת </TableCell>
                                                    <TableCell align="center" style={{ width: '2%' }}> ניקוד </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {c.qustions.map((item, index) => {

                                                    let icon = ''

                                                    if (item.correct == true)
                                                        icon = <CheckIcon />

                                                    else if (item.correct == false && item.userAnswer != '')
                                                        icon = <CloseIcon />

                                                    return (
                                                        <TableRow key={`trow${item}-${index}`} style={{ backgroundColor: (c.qustionNumber == item.qustionNumber) ? '#CCDAFF' : '' }}>
                                                            {

                                                                <React.Fragment>
                                                                    <TableCell align="center" > {item.qustionNumber} </TableCell>
                                                                    <TableCell align="right"> {item.qustion} </TableCell>
                                                                    <TableCell align="left" className={item.answerVisibility} style={{ direction: 'ltr' }}> {item.answer} </TableCell>
                                                                    <TableCell align="left" style={{ direction: 'ltr' }}> {item.userAnswer} </TableCell>
                                                                    <TableCell align="center"> {icon}</TableCell>
                                                                    <TableCell align="center"> {item.points} </TableCell>
                                                                </React.Fragment>


                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                        <Qustion />

                                        <Terminal />
                                    </div>
                                )
                        }

                    }
                }
            </Consumer>
        )

    }

}

