import React, { Component } from 'react';
import './maxdepth.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Maxdepth extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column find-maxdepth">
                                <h4>חיפוש בעומק תיקיות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>הפרמטר -maxdepth</b>
                                    <span>
                                        לפעמים תרצה למצוא קובץ או תיקייה שמופיעים בעומק מסוים, נוכל להשתמש בפרמטר -maxdepth על מנת לדייק את החיפוש, שים לב שאם מופיע לך / בתחילת תוצאות החיפוש זה עומק 1!
                                    </span>
                                    <span>בדוגמה הזאת אנו מחפשים קבצים מסוג html במיקום הנוכחי בעומק תיקיות 3 והתעלמות מאותיות קטנות או גדולות</span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -maxdepth 3 -iname '*.html'" />
                                <span>תוצאות שנוכל לקבל לדוגמה:</span>
                                <p>
                                    <span className='ltr'>/index.html</span>
                                    <span className='ltr'>/test/about.html</span>
                                    <span className='ltr'>/test/con/contact.html</span>
                                    <span>אבל תוצאה כזאת אנו לא אמורים לקבל</span>
                                    <span className='ltr'>/test/con/dir/contact.html</span>
                                    <span>וזאת משום שאם תספור את מספר ה /, תגיע ל - 4!!!</span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 27
                                        let subjectsAndSubTopicsCompletedIndex = 6

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
        )
    }
}
            </Consumer >)
    }

}
