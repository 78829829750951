import React, { Component } from 'react';
import './systemctl.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class Systemctl extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper process-systmectl">
                                <h4>Systemctl</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>ssh process</b>
                                    <span>
                                        לאחר התקנת OpenSSH ב- VM שלנו, אנו יכולים להתחיל את השירות באמצעות הפקודה הבאה.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="systemctl start ssh" />

                                    <span>לאחר שהתחלנו את השירות, כעת נוכל לבדוק אם הוא פועל ללא שגיאות.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="systemctl status ssh" />
                                    
                                    <span>ניתן לאפשר את התהליך</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="systemctl enable ssh" />

                                    <span>לאחר שנאתחל את המערכת מחדש, שרת OpenSSH יפעל אוטומטית. אנו יכולים לבדוק - ps.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="ps -aux | grep ssh" />
                                    
                                    <span>אנו יכולים גם להשתמש ב- systemctl כדי לרשום את כל השירותים.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="systemctl -list-units --type=service" />


                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 10
                                        let completedIndex = 62
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
