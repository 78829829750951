import React, { Component } from 'react';
import './worldwideweb.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class WorldWideWeb extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper WorldWideWeb" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>הרשת העולמית (World Wide Web)</b>
                                </p>
                                <p className='c9'>
                                    <span className='c4'>מרחב של מסמכי</span>
                                    <span className='c1'>היפרטקסט</span>
                                    <span className='c0'>
                                        מקושרים המכונים דפי אינטרנט או דפי Web, והוא כולל משאבים אלקטרוניים אחרים כמו תמונות, קובצי וידאו וצליל ותוכניות. הווב נגיש דרך האינטרנט.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className='c9'>
                                    <span className='c0'>
                                        הקישור בין הדפים מתקיים באמצעות היפרטקסט - טקסט שבחירה בו מובילה את המשתמש לדף אינטרנט אחר.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className='c9'>
                                    <span className='c0'>
                                        דפי אינטרנט וההיפרטקסט נקראים באמצעות תוכנת דפדפן, שקוראת את דפי האינטרנט משרתי אינטרנט ומציגה אותם על המסך. מכלול של דפי אינטרנט שנוצרו על ידי גורם אחד, קשורים לאותו נושא ושמורים תחת שם דומיין אחד נקראים אתר אינטרנט.
                                    </span>
                                </p>
                                <div className='html-img'></div>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 16
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
