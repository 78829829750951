import React, { Component } from 'react';
import './whois.css';
import companyLogo from './../../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Whois extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper Whois" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}></b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        'Whois' הוא שמו של הפרוטוקול המשמש לחקירת השרתים המופעלים על ידי רישום אינטרנט אזורי, המחזיקים מידע על כל משאב (כתובת IP או שם תחום) הרשום באינטרנט. המידע שאתה יכול לקבל על משאב הוא: שם החברה הבעלים.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 links image-on-page-whois">
                                   
                                        <a href="https://who.is/" target='_blank'>https://who.is/</a>
                                   
                                </p>


                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 20
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
