import React, { Component } from 'react';
import './head.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Head extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column">
                                <h4>סינון תוכן - head</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>head</b>
                                    <span>
                                        לעיתים אנו מעוניינים בסוגיות ספציפיות רק בתחילת הקובץ או בסופו. אם אנחנו רק רוצים לקבל את השורות הראשונות של הקובץ, אנחנו יכולים להשתמש בראש הכלי. כברירת מחדל, ראש מדפיס את עשר השורות הראשונות של הקובץ או הקלט הנתון, אם לא צוין אחרת.
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="head /etc/passwd" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 41
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
