import React, { Component } from 'react';
import './cut.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Cut extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column cut">
                                <h4>סינון תוכן - cut</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>cut</b>
                                    <span>
                                        ניתן להפריד בין תוצאות ספציפיות עם תווים שונים כמפרידים. כאן זה שימושי לדעת כיצד להסיר תוחמים ספציפיים ולהראות את המילים בשורה במיקום מוגדר. אחד הכלים שניתן להשתמש בהם לכך הוא גזירה. לכן אנו משתמשים באופציה "-d" ומכוונים את המפריד לתו המעי הגס (:) ומגדירים עם האפשרות "-f" את המיקום בשורה אותה אנו רוצים להפיק.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin' | cut -d':' -f1" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 46
                                        let subjectsAndSubTopicsCompletedIndex = 7

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
