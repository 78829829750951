import GoogleSignIn from "../GoogleSignIn/GoogleSignIn";
import UserProfile from "../UserProfile/UserProfile";
import './login.css'

export default function Login() {

    return (
        <div id="login">
            < GoogleSignIn />
            {/* <UserProfile /> */}
        </div>
    )

}