import React, { Component } from 'react';
import './recursion.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Recursion extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ls-R">
                                <div className="img-div"><img src={companyLogo} /></div> 
                            <div className='ls-R-result'>
                                <h4>ls -R הפקודה</h4>
                                
                                <p>
                                    <b>ls -R</b>
                                    <span>
                                        אם תפתח את הטרמינל ותריץ את הפקודה <span className='bold green medium-text'>ls -R</span>, תוכל לראות את גם את רשימת הקבצים והתיקיות בכל תתי התיקיות גם כן
                                    </span>
                                    <span> האם אפשר לשלב 3 פרמטרים ביחד? הכוונה היא, האם ניתן לראות גם באופן מפורט את הקבצים והתיקיות הנסתרים? גם בתתי התיקיות?</span> 
                                    <span>פתח את מסוף הפקודה והקלד ls -laR</span>

                                </p>

                            </div>
                            <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 2
                                        let completedIndex = 12
                                        let subjectsAndSubTopicsCompletedIndex = 4

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                        </div>
                        )
                    }
                }
            </Consumer>)
    }

}
