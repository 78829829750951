import React, { Component } from 'react';
import './search.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class Search extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper search">
                                <h4>חיפוש קבצים או תיקיות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>חיפוש קבצים או תיקיות</b>
                                    <span>
                                        זה חיוני להיות מסוגל למצוא את הקבצים והתיקיות שאנחנו צריכים. לאחר שקיבלנו גישה למערכת מבוססת לינוקס, יהיה זה חיוני למצוא קבצי תצורה, סקריפטים שנוצרו על ידי משתמשים או מנהל המערכת וקבצים ותיקיות אחרים. איננו חייבים לדפדף ידנית בכל תיקיה אחת ולבדוק מתי שונה בפעם האחרונה. יש כמה כלים שבהם נוכל להשתמש כדי להקל על העבודה הזו.
                                    </span>

                                </p>

                                <p>
                                    <b>Which</b>
                                    <span>
                                        אחד הכלים הנפוצים הוא Which. כלי זה מחזיר את הנתיב לקובץ או לקישור שיש לבצע. זה מאפשר לנו לקבוע אם תוכניות ספציפיות, כמו cURL, netcat, wget, python, gcc, זמינות במערכת ההפעלה. הבה נשתמש בו לחיפוש פיתון במופע האינטראקטיבי שלנו.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="which python" />
                                </p>

                                <p>
                                    <b>Locate</b>
                                    <span>
                                        ייקח זמן רב לחפש במערכת כולה את הקבצים והספריות שלנו כדי לבצע חיפושים רבים ושונים. איתור הפקודה מציע לנו דרך מהירה יותר לחפש במערכת. בניגוד לפקודת find, אתר עבודות עם בסיס נתונים מקומי המכיל את כל המידע אודות קבצים ותיקיות קיימים. אנו יכולים לעדכן מסד נתונים זה באמצעות הפקודה הבאה.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo updatedb" />

                                    <span>אם כעת נחפש את כל הקבצים עם סיומת ".conf", תגלה כי חיפוש זה מניב תוצאות הרבה יותר מהר מאשר שימוש ב- find.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="locate *.conf" />

                                    <p>
                                        <span>
                                            עם זאת, לכלי זה אין כל כך הרבה אפשרויות סינון בהן אנו יכולים להשתמש. לכן תמיד כדאי לשקול אם נוכל להשתמש בפקודה לאתר או במקום זאת להשתמש בפקודה למצוא. זה תמיד תלוי במה שאנחנו מחפשים.
                                        </span>
                                    </p>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 13
                                        let completedIndex = 68
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
