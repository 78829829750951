import React, { Component } from 'react';
import './dpkg.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class DPKG extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column packages-dpkg">
                                <h4>dpkg</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>

                                    <span>
                                        אנו יכולים גם להוריד את התוכניות והכלים מהמאגרים בנפרד. בדוגמה זו אנו מורידים 'strace' עבור אובונטו 18.04 LTS.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="wget http://archive.ubuntu.com/ubuntu/pool/main/strace/strace_4.21-1ubuntu1_amd64.deb" />
                                <p>

                                    <span>
                                        עבדנו עם apt, אנו נפנה ל- dpkg בדוגמה הבאה.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo dpkg -i strace_4.21-1ubuntu1_amd64.deb" />
                                <p>

                                    <span>
                                        עם זאת, כבר התקנו את הכלי ויכולים לבדוק אם הוא עובד כמו שצריך.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="strace -h" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 9
                                        let completedIndex = 58
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
