import React, { Component } from 'react';
import './BettercapIntroduction.css';
import companyLogo from './../../../../../../assets/Images/bettercap.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class BettercapIntroduction extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapInstallation" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap Introduction</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        <i style={{ fontWeight: 'bold' }}>bettercap</i> היא מסגרת חזקה, ניתנת להרחבה וניידת שנכתבה ב-Go, שמטרתה להציע לחוקרי אבטחה, צוותים אדומים ומהנדסים לאחור פתרון קל לשימוש, הכל-ב-אחד עם כל התכונות שהם עשויים להזדקק לביצוע סיור ותקיפה. רשתות WiFi, התקני Bluetooth Low Energy, התקני HID אלחוטיים, רשתות CAN-bus ו-IPv4/IPv6.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>
                                <p className='c9 list-items'>
                                    <b style={{ fontSize: '17pt' }}>תכונות עיקריות</b>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>סריקת רשתות WiFi, התקפת ביטול אימות, התקפת שיוך PMKID ללא לקוח ולכידת לחיצות ידיים של לקוח WPA/WPA2 אוטומטית.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>מכשירי Bluetooth Low Energy סריקה, ספירת מאפיינים, קריאה וכתיבה.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>התקנים אלחוטיים של 2.4Ghz סורקים והתקפות MouseJacking עם הזרקת מסגרות HID באוויר (עם תמיכה ב-DuckyScript).</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>רשת IP פסיבית ואקטיבית מארח חיטוט וריקול.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>מזויפים ARP, DNS, DHCPv6 ו- NDP עבור התקפות MITM על רשתות מבוססות IPv4 ו-IPv6.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>פרוקסי ברמת מנות, רמת TCP ורמת יישום HTTP/HTTPS ניתנים לסקריפט מלא עם תוספים קלים ליישום javascript.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>רחרח רשת רב עוצמה עבור קצירת אישורים אשר יכול לשמש גם כמשוך פרוטוקול רשת.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>סורק יציאות מהיר מאוד.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>תמיכת CAN-bus לקריאה, הזרקה, טשטוש, טעינת DBC מותאם אישית ומנתח PIDs מובנה של OBD2.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '7px' }}>REST API רב עוצמה עם תמיכה בהתראה על אירועים אסינכרוניים ב-websocket כדי לתזמן את ההתקפות שלך בקלות.</span>
                                    <span style={{ fontSize: '17pt', 'marginBottom': '1px' }}>ממשק משתמש אינטרנטי קל לשימוש יוֹתֵר!</span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 4
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
