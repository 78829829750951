import React, { Component, createContext } from "react";

import { faLaptopCode, faGear, faTable, faBug, faCheckSquare, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


import CorePrinciples from "./../Documentation/Cyber/Linux/Introduction/CorePrinciples/CorePrinciples";
import Architecture from "./../Documentation/Cyber/Linux/Introduction/Architecture/Architecture";
import FolderStructure from "./../Documentation/Cyber/Linux/Introduction/FolderStructure/FolderStructure";
import Components from "../Documentation/Cyber/Linux/Introduction/Components/Components";

import ShellIntroduction from "./../Documentation/Cyber/Linux/Shell/ShellIntroduction/ShellIntroduction"
import Structural from "./../Documentation/Cyber/Linux/Shell/Structural/Structural"
import Help from "./../Documentation/Cyber/Linux/Shell/ShellHelp/ShellHelp"
import SystemInfo from "./../Documentation/Cyber/Linux/Shell/SystemInfo/SystemInfo"

import LsIntroduction from "./../Documentation/Cyber/Linux/LsCommand/LsIntroduction/LsIntroduction";
import PWD from "./../Documentation/Cyber/Linux/LsCommand/PWD/PWD";
import Long from "./../Documentation/Cyber/Linux/LsCommand/Long/Long"
import Hidden from "./../Documentation/Cyber/Linux/LsCommand/Hidden/Hidden"
import Recursion from "./../Documentation/Cyber/Linux/LsCommand/Recursion/Recursion"

import Files from './../Documentation/Cyber/Linux/FoldersAndFiles/Files/Files'
import Folders from './../Documentation/Cyber/Linux/FoldersAndFiles/Folders/Folders'


import Entities from './../Documentation/Cyber/Linux/Permissions/Entities/Entities'
import NumericalRepresentation from './../Documentation/Cyber/Linux/Permissions/NumericalRepresentation/NumericalRepresentation'
import CHMOD from './../Documentation/Cyber/Linux/Permissions/CHMOD/CHMOD'

import Location from './../Documentation/Cyber/Linux/FindCommand/Location/Location'
import Type from './../Documentation/Cyber/Linux/FindCommand/Type/Type'
import Iname from './../Documentation/Cyber/Linux/FindCommand/iname/Iname'
import Maxdepth from './../Documentation/Cyber/Linux/FindCommand/Maxdepth/Maxdepth'
import Size from './../Documentation/Cyber/Linux/FindCommand/Size/Size'
import Perm from './../Documentation/Cyber/Linux/FindCommand/perm/Perm'
import FindSummary from "../Documentation/Cyber/Linux/FindCommand/FindSummary/FindSummary";

import Cat from './../Documentation/Cyber/Linux/Content/Cat/Cat'
import FileDescriptors from './../Documentation/Cyber/Linux/Content/FileDescriptors/FileDescriptors'
import Redirect from './../Documentation/Cyber/Linux/Content/Redirect/Redirect'
import Pipes from "../Documentation/Cyber/Linux/Content/Pipes/Pipes";

import Column from './../Documentation/Cyber/Linux/Filtering/Column/Column'
import Cut from "../Documentation/Cyber/Linux/Filtering/Cut/Cut";
import Grep from './../Documentation/Cyber/Linux/Filtering/Grep/Grep'
import Head from './../Documentation/Cyber/Linux/Filtering/Head/Head'
import Less from './../Documentation/Cyber/Linux/Filtering/Less/Less'
import More from './../Documentation/Cyber/Linux/Filtering/More/More'
import Sed from './../Documentation/Cyber/Linux/Filtering/Sed/Sed'
import Sort from './../Documentation/Cyber/Linux/Filtering/Sort/Sort'
import Tail from './../Documentation/Cyber/Linux/Filtering/Tail/Tail'
import Tr from './../Documentation/Cyber/Linux/Filtering/Tr/Tr'
import WC from './../Documentation/Cyber/Linux/Filtering/WC/WC'

import UserIntroduction from './../Documentation/Cyber/Linux/Users/UsersIntroduction/UsersIntroduction'
import UsersActions from './../Documentation/Cyber/Linux/Users/UsersActions/UsersActions'
import CHOWN from './../Documentation/Cyber/Linux/Users/CHOWN/CHOWN'
import SuperUser from './../Documentation/Cyber/Linux/Users/SuperUser/SuperUser'

// 'Introduction', 'Applications', 'Actions', 'dpkg'
import PackegesIntroduction from './../Documentation/Cyber/Linux/Packages/PackagesIntroduction/PackagesIntroduction'
import Applications from './../Documentation/Cyber/Linux/Packages/Applications/Applications'
import PackagesActions from './../Documentation/Cyber/Linux/Packages/PackagesActions/PackagesActions'
import DPKG from './../Documentation/Cyber/Linux/Packages/DPKG/DPKG'

import ProcessIntroduction from './../Documentation/Cyber/Linux/Process/ProcessIntroduction/ProcessIntroduction'
import BackgroundAProcess from './../Documentation/Cyber/Linux/Process/BackgroundAProcess/BackgroundAProcess'
import ForegroundAProcess from './../Documentation/Cyber/Linux/Process/ForegroundAProcess/ForegroundAProcess'
import Systemctl from './../Documentation/Cyber/Linux/Process/Systemctl/Systemctl'
import Kill from './../Documentation/Cyber/Linux/Process/Kill/Kill'

import Security from './../Documentation/Cyber/Linux/Security/Security'

import Shortcuts from './../Documentation/Cyber/Linux/Shortcuts/Shortcuts'

// Miscellaneous
import ExecutionsInOneLine from "../Documentation/Cyber/Linux/Miscellaneous/ExecutionsInOneLine/ExecutionsInOneLine";
import FileEditors from "../Documentation/Cyber/Linux/Miscellaneous/FileEditors/FileEditors";
import Search from "../Documentation/Cyber/Linux/Miscellaneous/Search/Search";



import RegexIntroduction from "./../Documentation/Cyber/RegularExpresion/RegExIntroduction/RegExIntroduction";
import Characters from "../Documentation/Cyber/RegularExpresion/Characters/Characters";
import Metacharacters from './../Documentation/Cyber/RegularExpresion/Metacharacters/Metacharacters'
import Quantifiers from './../Documentation/Cyber/RegularExpresion/Quantifiers/Quantifiers'
import CharacterClasses from './../Documentation/Cyber/RegularExpresion/CharacterClasses/CharacterClasses'
import Grouping from "../Documentation/Cyber/RegularExpresion/Grouping/Grouping";
import Lookarounds from '../Documentation/Cyber/RegularExpresion/Lookarounds/Lookarounds'
import Substitution from "../Documentation/Cyber/RegularExpresion/Substitution/Substitution";

import WorldWideWeb from "../Documentation/Cyber/darknet/WorldWideWeb/WorldWideWeb";
import DeepWeb from "../Documentation/Cyber/darknet/DeepWeb/DeepWeb";
import DarkNet from "../Documentation/Cyber/darknet/Darknet/DarkNet";
import Tor from "../Documentation/Cyber/darknet/Tor/Tor";

import HackersTypes from "../Documentation/Cyber/3HackersType/HackersTypes/HackersTypes";
import HackersOS from "../Documentation/Cyber/3HackersType/HackersOS/HackersOS";

import SurfingAnonymouslyIntro from "../Documentation/Cyber/SurfingAnonymously/SurfingAnonymouslyIntro/SurfingAnonymouslyIntro";
import Whonix from "../Documentation/Cyber/SurfingAnonymously/Whonix/Whonix";
import WhonixInstallation from "../Documentation/Cyber/SurfingAnonymously/WhonixInstallation/WhonixInstallation";
import WhonixConfiguration from "../Documentation/Cyber/SurfingAnonymously/WhonixConfiguration/WhonixConfiguration";
import Proxychains from "../Documentation/Cyber/SurfingAnonymously/Proxychains/Proxychains";
import ProxychainsInstallation from "../Documentation/Cyber/SurfingAnonymously/ProxychainsInstallation/ProxychainsInstallation";
import ProxychainsConfiguration from "../Documentation/Cyber/SurfingAnonymously/ProxychainsConfiguration/ProxychainsConfiguration";

import VPN from "../Documentation/Cyber/SurfingAnonymously/VPN/VPN";
import MacSpoofing from "../Documentation/Cyber/SurfingAnonymously/MacSpoofing/MacSpoofing";

import ReconnaissanceIntroduction from "../Documentation/Cyber/Reconnaissance/ReconnaissanceIntroduction/ReconnaissanceIntroduction";
import SearchEngines from "../Documentation/Cyber/Reconnaissance/Person/SearchEngines/SearchEngines";
import GoogleAlerts from "../Documentation/Cyber/Reconnaissance/Person/GoogleAlerts/GoogleAlerts";
import Whois from "../Documentation/Cyber/Reconnaissance/Person/Whois/Whois";
import Maltego from "../Documentation/Cyber/Reconnaissance/Person/Maltego/Maltego";
import ExploitDatabase from "../Documentation/Cyber/Reconnaissance/Organization/ExploitDatabase/ExploitDatabase";
import CVE from "../Documentation/Cyber/Reconnaissance/Organization/CVE/CVE";
import WaybackMachine from "../Documentation/Cyber/Reconnaissance/Organization/WaybackMachine/WaybackMachine";
import WPScan from "../Documentation/Cyber/Reconnaissance/Organization/WPScan/WPScan";
import Nexpose from "../Documentation/Cyber/Reconnaissance/Organization/Nexpose/Nexpose";
import NMAPIntroduction from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/NMAPIntroduction/NMAPIntroduction";
import NMAPCMDSyntax from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/NMAPCMDSyntax/NMAPCMDSyntax";
import TheBasicsOfScanning from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/TheBasicsOfScanning/TheBasicsOfScanning";
import PortsStatus from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/PortsStatus/PortsStatus";
import ScanningAndAnalysis from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/ScanningAndAnalysis/ScanningAndAnalysis";
import ScanningParamerters from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/ScanningParamerters/ScanningParamerters";
import NMAPCheatSheet from "../Documentation/Cyber/Reconnaissance/Organization/NMAP/NMAPCheatSheet/NMAPCheatSheet";


import MITMIntroduction from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMIntroduction/MITMIntroduction";
import ARPPresentation from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMARP/ARPPresentation/ARPPresentation";
import ARPPoisioningPresentation from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMARP/ARPPoisioningPresentation/ARPPoisioningPresentation";
import ArpspoofCommand from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMARP/ArpspoofCommand/ArpspoofCommand";

import BettercapIntroduction from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapIntroduction/BettercapIntroduction";
import BettercapInstallation from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapInstallation/BettercapInstallation";
import BettercapSystemOverview from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapSystemOverview/BettercapSystemOverview";
import BettercapModules from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapModules/BettercapModules";
import BettercapModulesHelp from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapModulesHelp/BettercapModulesHelp";
import BettercapRunAModule from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapRunAModule/BettercapRunAModule";
import BettercapShowingInformation from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapShowingInformation/BettercapShowingInformation";
import BettercapARPSpoofing from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapARPSpoofing/BettercapARPSpoofing";
import BettercapInitParams from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapInitParams/BettercapInitParams";
import BettercapSniffingData from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapSniffingData/BettercapSniffingData";
import BettercapCaplets from "../Documentation/Cyber/ManInTheMiddleAttacks/Bettercap/BettercapCaplets/BettercapCaplets";
import DHCPPresentation from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMDHCP/DHCPPresentation/DHCPPresentation";
import DHCPStarvationPresentation from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMDHCP/DHCPStarvationPresentation/DHCPStarvationPresentation";
import Yersinia from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMDHCP/Yersinia/Yersinia";
import Metasploit from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMDHCP/Metasploit/Metasploit";
import SniffingWithWireshark from "../Documentation/Cyber/ManInTheMiddleAttacks/MITMDHCP/SniffingWithWireshark/SniffingWithWireshark";

import Quizer from "../Quizer/Quizer";
import Quiz from "../Quiz/Quiz";

import TestInstructions from "../Test/TestInstructions/TestInstructions";
import LinuxNoviceLevel from "../Test/LinuxNoviceLevel/LinuxNoviceLevel";
import LinuxIntermediateLevel from "../Test/LinuxIntermediateLevel/LinuxIntermediateLevel";
import LinuxExpertLevel from "../Test/LinuxExpertLevel/LinuxExpertLevel";
import RegExNoviceLevel from "../Test/RegExNoviceLevel/RegExNoviceLevel";
import RegExIntermediateLevel from "../Test/RegExIntermediateLevel/RegExIntermediateLevel";
import RegExExpertLevel from "../Test/RegExExpertLevel/RegExExpertLevel";

import TestInstructionsNetworking from "../Test/TestInstructionsNetworking/TestInstructionsNetworking";

//images
import tcpHandShake from './../../assets/Images/3way.png'


export const Context = createContext();
export class Provider extends Component {

    componentDidUpdate(prevProps) {

        // console.log('componentDidUpdate');


    }

    componentDidMount() {
        try {

            let obj1 = document.querySelectorAll("[aria-selected='true']")
            let prefix = obj1[0].id.substring(0, 5)
            let obj = document.querySelectorAll(".paper")[0]

            // in each subject put in the first sub topic:
            //  <div className="paper medium-display-column core-principles" data-sub-index="0" > this is in linux in regex 1
            let docsSubjectAndTopics = this.state.subjectsAndSubTopics.filter((item, index) => item.section == 'documentation' && item.subIndex == obj.dataset.subIndex)

            let itemIdArray = []
            docsSubjectAndTopics.map((item, index) => {
                item.subTopics.map((i, index) => {
                    let itemId = `${i}${index}`
                    itemIdArray.push(itemId)
                })
            })
            this.setItemIdArray(itemIdArray)
        } catch {

        }
    }

    constructor() {

        super();
        this.state = {
            pageTitle: 'Welcome To Web School 26 ACADEMY The Way To Be A Pro',
            checkmarkVisiability: 'no-visible',
            userProfileVisiability: 'hidden',
            clearTerminal: false,
            email: '',
            family_name: '',
            given_name: '',
            name: '',
            picture: '',
            sub: '',


            radioButtonValue: 0, //the properties in the testQustion array of radio buttons quiz
            helperText: "בחר בחכמה 🦉",
            error: false,
            id: 0, // the properties in the testQustion array, this is the id property in the qustions 12 rows bellow belongs to this
            Course: '',
            subject: '',
            subTopic: '',
            qustionNumber: 0,
            qustion: '',
            answers: [],
            userAnswerIndex: null,
            correctAnswerIndex: 0, // pay attention the 0 is the correct answer index in the first qustion (in qustionType:radio), if the correct answer index is 2, we need to change the value here to 2 
            points: 0,
            difficulty: '',
            qustionType: '',
            quizImage: '',

            totalPoints: [0],
            startTest: false,
            stopTest: false,

            documentSubTopic: '',
            componentName: '',
            completedIndex: 0,
            subTopicIndex: '',
            subjectsAndSubTopicsIndex: '',
            subjectsAndSubTopicsCompletedIndex: 0,
            terminalShellCommand: [],
            choosenCourse: { course: '', courseIndex: '' }, // acording to the  course we will build the TopicViewerSideBar menu from subjectsAndSubTopics in state
            choosenCourseSubIndex: '',

            courseVerticalMenu: [
                { index: 0, item: 'Networking', icon: faLaptopCode },
                { index: 1, item: 'Cyber', icon: faBug },
                { index: 2, item: 'Web', icon: faLaptopCode },
                { index: 3, item: 'SQL', icon: faTable },
                { index: 4, item: 'Algorithems', icon: faGear },
                { index: 5, item: 'Terminal Test', icon: faQuestionCircle },
                { index: 6, item: 'Quiz Test', icon: faQuestionCircle },
            ],
            menuItems: ['Linux', 'Regular Expression', 'darknet', 'hackers-type', 'Surfing Anonymously', 'Reconnaissance', 'Man In The Middle Attacks', 'Encryptions', 'SSH', 'Login Brute Forcing', 'Backdoors', 'Social engineering', 'SQL injection', 'Wi-Fi Hijacking', 'XSS Attack'],

            menuItemsCubes: [

                { subject: 'Storage-And-Measurement-Units', status: 'Comming up soon ✈', index: 0, subIndex: 0 },
                { subject: 'Bases-Conversion', status: 'Comming up soon ✈', index: 0, subIndex: 1 },
                { subject: 'Two-Computers-Communicate', status: 'Comming up soon ✈', index: 0, subIndex: 2 },
                { subject: 'More-Than-Two-Computers-Communicate', status: 'Comming up soon ✈', index: 0, subIndex: 3 },
                { subject: 'VLAN', status: 'Comming up soon ✈', index: 0, subIndex: 4 },
                { subject: 'Router', status: 'Comming up soon ✈', index: 0, subIndex: 5 },
                { subject: 'DHCP', status: 'Comming up soon ✈', index: 0, subIndex: 6 },
                { subject: 'Static-Routing', status: 'Comming up soon ✈', index: 0, subIndex: 7 },
                { subject: 'Classful-Net-Masks', status: 'Comming up soon ✈', index: 0, subIndex: 8 },
                { subject: 'CIDR-Notation', status: 'Comming up soon ✈', index: 0, subIndex: 9 },
                { subject: 'Magic-Number', status: 'Comming up soon ✈', index: 0, subIndex: 10 },
                { subject: 'Server-And-Cleint', status: 'Comming up soon ✈', index: 0, subIndex: 11 },
                { subject: 'DNS', status: 'Comming up soon ✈', index: 0, subIndex: 12 },
                { subject: 'PORTS', status: 'Comming up soon ✈', index: 0, subIndex: 13 },
                { subject: 'Public-and-Private-IP', status: 'Comming up soon ✈', index: 0, subIndex: 14 },
                { subject: 'Special-IP-Addresses', status: 'Comming up soon ✈', index: 0, subIndex: 15 },
                { subject: 'TCP-IP', status: 'Comming up soon ✈', index: 0, subIndex: 16 },
                { subject: 'Wireshark', status: 'Comming up soon ✈', index: 0, subIndex: 17 },
                { subject: 'WiFi-Handshake', status: 'Comming up soon ✈', index: 0, subIndex: 18 },

                { subject: 'Linux', status: '', index: 1, subIndex: 0 },
                { subject: 'Regular Expression', status: '', index: 1, subIndex: 1 },
                { subject: 'darknet', status: '', index: 1, subIndex: 2 },
                { subject: 'hackers-type', status: '', index: 1, subIndex: 3 },
                { subject: 'Surfing Anonymously', status: '', index: 1, subIndex: 4 },
                { subject: 'Reconnaissance', status: '', index: 1, subIndex: 5 },
                { subject: 'Man In The Middle Attacks', status: '', index: 1, subIndex: 6 },
                { subject: 'Encryptions', status: 'Comming up soon ✈', index: 1, subIndex: 7 },
                { subject: 'SSH', status: 'Comming up soon ✈', index: 1, subIndex: 8 },
                { subject: 'Login Brute Forcing', status: 'Comming up soon ✈', index: 1, subIndex: 9 },
                { subject: 'Backdoors', status: 'Comming up soon ✈', index: 1, subIndex: 10 },
                { subject: 'Social engineering', status: 'Comming up soon ✈', index: 1, subIndex: 11 },
                { subject: 'SQL injection', status: 'Comming up soon ✈', index: 1, subIndex: 12 },
                { subject: 'Wi-Fi Hijacking', status: 'Comming up soon ✈', index: 1, subIndex: 13 },
                { subject: 'XSS Attack', status: 'Comming up soon ✈', index: 1, subIndex: 14 },

                { subject: 'HTML-AND-CSS', status: 'Comming up soon ✈', index: 2, subIndex: 0 },
                { subject: 'JavaScript', status: 'Comming up soon ✈', index: 2, subIndex: 1 },
                { subject: 'React', status: 'Comming up soon ✈', index: 2, subIndex: 2 },
                { subject: 'NodeJS', status: 'Comming up soon ✈', index: 2, subIndex: 3 },
                { subject: 'PHP', status: 'Comming up soon ✈', index: 2, subIndex: 4 },


                { subject: 'MYSQL', status: 'Comming up soon ✈', index: 3, subIndex: 0 },
                { subject: 'MSSQL', status: 'Comming up soon ✈', index: 3, subIndex: 1 },
                { subject: 'MongoDB', status: 'Comming up soon ✈', index: 3, subIndex: 2 },

                { subject: 'Swap', status: 'Comming up soon ✈', index: 4 },
                { subject: 'Programs-for-printing-pyramid-patterns', status: 'Comming up soon ✈', index: 4, subIndex: 0 },
                { subject: 'Bubble-Sort', status: 'Comming up soon ✈', index: 4, subIndex: 1 },
                { subject: 'Game-Of-Life', status: 'Comming up soon ✈', index: 4, subIndex: 2 },
                { subject: 'Stacks', status: 'Comming up soon ✈', index: 4, subIndex: 3 },
                { subject: 'Binary-Tree-BST', status: 'Comming up soon ✈', index: 4, subIndex: 4 },
                { subject: 'DFS-Depth-First-Search', status: 'Comming up soon ✈', index: 4, subIndex: 5 },
                { subject: 'BFS-Breadth-First-Search', status: 'Comming up soon ✈', index: 4, subIndex: 6 },


                { subject: 'Linux-RegEx-Terminal-Test', status: '', index: 5, subIndex: 100 },
                { subject: 'Networking-Quiz-Test', status: '', index: 6, subIndex: 101 },
            ],

            selectedMenuItemsCubes: [

            ],
            subjectsAndSubTopics: [

                // the allCopnents contains all the components aranged by the subTopic array order here
                // subIndex: 0 for instance is the state.menuItemsCubes index key
                { id: 0, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'introduction', subTopics: ['Core principles', 'Linux Architecture', 'The folder structure in Linux', 'Components'], allComponents: [CorePrinciples, Architecture, FolderStructure, Components], completed: ['', '', '', ''] },
                { id: 1, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Shell', subTopics: ['Shell Introduction', 'Structural', 'Help', 'System Information'], allComponents: [ShellIntroduction, Structural, Help, SystemInfo], completed: ['', '', '', ''] },
                { id: 2, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'ls Command', subTopics: ['pwd', 'ls Introduction', 'long (-l)', 'Hidden files (-a)', 'Recursion (-R)', 'ls Quiz'], allComponents: [PWD, LsIntroduction, Long, Hidden, Recursion, Quizer], completed: ['', '', '', '', '', ''] },
                { id: 3, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Folders & Files', subTopics: ['File Commands', 'Working with files Quiz', 'Folders Commands', 'Working with folders Quiz'], allComponents: [Files, Quizer, Folders, Quizer], completed: ['', '', '', ''] },
                { id: 4, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Permissions', subTopics: ['Entities', 'Numerical Representation', 'chmod'], allComponents: [Entities, NumericalRepresentation, CHMOD], completed: ['', '', ''] },
                { id: 5, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Find', subTopics: ['Location', 'location Quiz', 'Type', 'type parameter Quiz', 'iname', 'iname parameter Quiz', 'maxdepth', 'maxdepth parameter Quiz', 'size', 'size parameter Quiz', 'perm', 'perm parameter Quiz', 'Find Summary'], allComponents: [Location, Quizer, Type, Quizer, Iname, Quizer, Maxdepth, Quizer, Size, Quizer, Perm, Quizer, FindSummary], completed: ['', '', '', '', '', '', '', '', '', '', '', '', ''] },
                { id: 6, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Content', subTopics: ['cat', 'cat Command Quiz', 'File Descriptors', 'Redirect', 'Pipes'], allComponents: [Cat, Quizer, FileDescriptors, Redirect, Pipes], completed: ['', '', '', '', ''] },
                { id: 7, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Filtering', subTopics: ['more', 'less', 'head', 'tail', 'sort', 'grep', 'grep Command Quiz', 'Cut', 'tr', 'column', 'sed', 'wc'], allComponents: [More, Less, Head, Tail, Sort, Grep, Quizer, Cut, Tr, Column, Sed, WC], completed: ['', '', '', '', '', '', '', '', '', '', '', ''] },
                { id: 8, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Users', subTopics: ['Users Introduction', 'Actions', 'chown', 'Super User'], allComponents: [UserIntroduction, UsersActions, CHOWN, SuperUser], completed: ['', '', '', ''] },
                { id: 9, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Packages', subTopics: ['Packages Introduction', 'Applications', 'Package Actions', 'dpkg'], allComponents: [PackegesIntroduction, Applications, PackagesActions, DPKG], completed: ['', '', '', ''] },
                { id: 10, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Process', subTopics: ['Process Introduction', 'Background A Process', 'Foreground A Process', 'Systemctl', 'Kill a process'], allComponents: [ProcessIntroduction, BackgroundAProcess, ForegroundAProcess, Systemctl, Kill], completed: ['', '', '', '', ''] },
                { id: 11, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Linux Security', subTopics: ['Security'], allComponents: [Security], completed: [''] },
                { id: 12, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Linux Shortcuts', subTopics: ['Shortcuts'], allComponents: [Shortcuts], completed: [''] },
                { id: 13, Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'Miscellaneous', subTopics: ['Executions In One Line', 'File Editors', 'Search'], allComponents: [ExecutionsInOneLine, FileEditors, Search], completed: ['', '', ''] },



                { id: 14, Course: 'Regular Expression', subIndex: 1, section: 'documentation', subject: 'Regex Introduction', subTopics: ['Regex Introduction'], allComponents: [RegexIntroduction], completed: [''] },
                { id: 15, Course: 'Regular Expression', subIndex: 1, section: 'documentation', subject: 'RegEx Syntax', subTopics: ['Characters', 'Characters Quiz', 'Metacharacters', 'Metacharacters Quiz', 'Quantifiers', 'Quantifiers Quiz', 'Character Classes', 'Character Classes Quiz', 'Grouping', 'Grouping Quiz', 'Lookarounds', 'Lookarounds Quiz', 'Substitution'], allComponents: [Characters, Quizer, Metacharacters, Quizer, Quantifiers, Quizer, CharacterClasses, Quizer, Grouping, Quizer, Lookarounds, Quizer, Substitution], completed: ['', '', '', '', '', '', '', '', '', '', '', '', ''] },

                { id: 16, Course: 'darknet', subIndex: 2, section: 'documentation', subject: 'The Darknet', subTopics: ['The World Wide Web', 'The Deep Web', 'The Dark Net', 'Tor Browser'], allComponents: [WorldWideWeb, DeepWeb, DarkNet, Tor], completed: ['', '', '', ''] },
                { id: 17, Course: 'hackers-type', subIndex: 3, section: 'documentation', subject: '3 Hackers Types', subTopics: ['3 Hackers Types', 'Hackers OS'], allComponents: [HackersTypes, HackersOS], completed: ['', ''] },
                { id: 18, Course: 'Surfing Anonymously', subIndex: 4, section: 'documentation', subject: 'Surfing Anonymously', subTopics: ['Surfing Anonymously Intro', 'Whonix', 'Whonix Installation', 'Whonix Configuration', 'Proxychains', 'Proxychains Installation', 'Proxychains Configuration', 'VPN', 'MacSpoofing'], allComponents: [SurfingAnonymouslyIntro, Whonix, WhonixInstallation, WhonixConfiguration, Proxychains, ProxychainsInstallation, ProxychainsConfiguration, VPN, MacSpoofing], completed: ['', '', '', '', '', '', '', '', ''] },


                { id: 19, Course: 'Reconnaissance', subIndex: 5, section: 'documentation', subject: 'Reconnaissance Introduction', subTopics: ['Reconnaissance Introduction'], allComponents: [ReconnaissanceIntroduction], completed: [''] },
                { id: 20, Course: 'Reconnaissance', subIndex: 5, section: 'documentation', subject: 'Reconnaissance on Person', subTopics: ['Search Engines', 'Google Alerts', 'Whois', 'Maltego'], allComponents: [SearchEngines, GoogleAlerts, Whois, Maltego], completed: ['', '', '', ''] },
                { id: 21, Course: 'Reconnaissance', subIndex: 5, section: 'documentation', subject: 'Reconnaissance on Organization', subTopics: ['ExploitDatabase', 'CVE', 'WaybackMachine', 'WPScan', 'Nexpose'], allComponents: [ExploitDatabase, CVE, WaybackMachine, WPScan, Nexpose], completed: ['', '', '', '', ''] },
                { id: 22, Course: 'Reconnaissance', subIndex: 5, section: 'documentation', subject: 'NMAP', subTopics: ['NMAP Introduction', 'NMAP CMD Syntax', 'The Basics Of Scanning', 'Ports Status', 'Scanning And Analysis', 'Scanning Paramerters', 'NMAP CheatSheet'], allComponents: [NMAPIntroduction, NMAPCMDSyntax, TheBasicsOfScanning, PortsStatus, ScanningAndAnalysis, ScanningParamerters, NMAPCheatSheet], completed: ['', '', '', '', '', '', ''] },

                { id: 23, Course: 'Man In The Middle Attacks', subIndex: 6, section: 'documentation', subject: 'MITM Introduction', subTopics: ['Man In The Middle Attacks Introduction'], allComponents: [MITMIntroduction], completed: [''] },
                { id: 24, Course: 'Man In The Middle Attacks', subIndex: 6, section: 'documentation', subject: 'MITM ARP', subTopics: ['ARP Presentation', 'ARP Poisioning Presentation', 'arpspoof command'], allComponents: [ARPPresentation, ARPPoisioningPresentation, ArpspoofCommand], completed: ['', '', ''] },
                { id: 25, Course: 'Man In The Middle Attacks', subIndex: 6, section: 'documentation', subject: 'bettercap', subTopics: ['bettercap Introduction', 'bettercap installation', 'System overview', 'Modules', 'Modules Help', 'run a module', 'showing information', 'ARP spoofing', 'init params', 'sniffing data', 'caplets'], allComponents: [BettercapIntroduction, BettercapInstallation, BettercapSystemOverview, BettercapModules, BettercapModulesHelp, BettercapRunAModule, BettercapShowingInformation, BettercapARPSpoofing, BettercapInitParams, BettercapSniffingData, BettercapCaplets], completed: ['', '', '', '', '', '', '', '', '', '', ''] },
                { id: 26, Course: 'Man In The Middle Attacks', subIndex: 6, section: 'documentation', subject: 'MITM DHCP', subTopics: ['DHCP Presentation', 'DHCP Starvation Presentation', 'yersinia', 'metasploit', 'sniffing with wireshark'], allComponents: [DHCPPresentation, DHCPStarvationPresentation, Yersinia, Metasploit, SniffingWithWireshark], completed: ['', '', '', '', ''] },

                // { id: 14, Course: 'Linux', subIndex: 0, section: 'quiz', subject: 'Files & Folders', subTopics: ['ls Command', 'Working with files', 'Working with folders'], completed: ['', '', ''] },
                // { id: 15, Course: 'Linux', subIndex: 0, section: 'quiz', subject: 'Find Command', subTopics: ['location', 'type parameter', 'iname parameter', 'maxdepth parameter', 'size parameter', 'perm parameter'], completed: ['', '', '', '', '', ''] },
                // { id: 16, Course: 'Linux', subIndex: 0, section: 'quiz', subject: 'Text manipulation', subTopics: ['cat Command', 'grep Command'], completed: ['', ''] },

                // { Course: 'Regular Expression', subIndex: 1, section: 'quiz', subject: 'RegEx characters(metacharacters) Quiz', subTopics: ['RegEx characters(metacharacters)'], completed: [''] },
                // { Course: 'Regular Expression', subIndex: 1, section: 'quiz', subject: 'Regular Expression', subTopics: ['RegEx characters(metacharacters)', 'RegEx Patterns', 'RegEx Quantifiers', 'Character classes'], completed: ['', '', '', ''] },
                { id: 27, Course: 'Linux-RegEx-Terminal-Test', subIndex: 100, section: 'documentation', subject: 'Test In Linux And RegEx', subTopics: ['Test Instructions Linux & RegEX', 'Linux Novice level', 'Linux Intermediate level', 'Linux Expert level', 'RegEx Novice level', 'RegEx Intermediate level', 'RegEx Expert level'], allComponents: [TestInstructions, LinuxNoviceLevel, LinuxIntermediateLevel, LinuxExpertLevel, RegExNoviceLevel, RegExIntermediateLevel, RegExExpertLevel], completed: ['', '', '', '', '', '', ''] },
                { id: 28, Course: 'Networking-Quiz-Test', subIndex: 101, section: 'documentation', subject: 'Test In Networking', subTopics: ['Test Instructions Networking', 'Base Convertion Quiz', 'IP Address Quiz', 'Pysical Address Quiz', 'ARP Quiz', 'Router Quiz', 'DHCP Quiz', 'Static Routing Quiz', 'Classful Net Masks Quiz', 'CIDR Notation Quiz', 'Server & Cleint Quiz', 'DNS Quiz', 'Public and Private IP Quiz', 'TCP Quiz Handshake', 'TCP Quiz RTT', 'TCP Quiz Sequence Number', 'TCP Quiz Acknowledgement Number', 'TCP Quiz Sequence Number Starts', 'TCP Quiz Ghost Byte', 'TCP Quiz Availability', 'TCP Quiz Availability RTT', 'TCP Quiz Duplications', 'TCP Quiz when its a duplicated ack', 'TCP Quiz Buffer is full', 'TCP Quiz cant talk', 'TCP Quiz lets finsh', 'TCP Quiz Window scale', 'TCP Quiz Window size'], allComponents: [TestInstructionsNetworking, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz, Quiz], completed: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''] },
            ],
            allQustions: [
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'ls Quiz', qustionNumber: 0, qustion: 'הצג את כל הקבצים בתיקיות במיקומך הנוכחי', answer: 'ls', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'ls Quiz', qustionNumber: 1, qustion: 'הצג את הקבצים והתיקיות עם ההרשאות שלהם במיקומך הנוכחי', answer: 'ls -l', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'ls Quiz', qustionNumber: 2, qustion: 'הצג את הקבצים והתיקיות הנסתרים  במיקומך הנוכחי', answer: 'ls -a', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'ls Quiz', qustionNumber: 3, qustion: 'הצג את הקבצים והתיקיות הנסתרים עם ההרשאות שלהם  במיקומך הנוכחי', answer: 'ls -la', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'ls Quiz', qustionNumber: 4, qustion: 'הצג את הקבצים והתיקיות הנסתרים עם ההרשאות שלהם גם בתתי תיקיות של מיקומך', answer: 'ls -laR', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },

                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 0, qustion: 'צור 3 קבצים בפקודה אחת בשם test1 test2 test3 באמצעות הפקודה touch', answer: 'touch test1 test2 test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 1, qustion: 'מחוק את הקובץ test1', answer: 'rm test1', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 2, qustion: 'שנה את מיקומו של הקובץ test2 לתיקיית שולחן העבודה ( כמו ctrl + x )', answer: 'mv test2 Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 3, qustion: 'שנה את שמו של הקובץ test3 ל - test13', answer: 'mv test3 test13', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 4, qustion: 'העתק את הקובץ tets13  לשולחן העבודה ( כמו ctrl + v )', answer: 'cp test13 Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },

                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 0, qustion: 'צור תיקייה בשם unix', answer: 'mkdir unix', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 1, qustion: 'כנס לתיקייה unix שיצרת', answer: 'cd unix', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 2, qustion: 'כתוב פקודה אשר מוציאה אותך מהתיקייה שבה אתה נמצא', answer: 'cd ..', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 3, qustion: 'שנה את שם התיקייה ל - linux', answer: 'mv unix linux', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 4, qustion: 'מחק את התיקייה linux', answer: 'rm -r linux', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 5, qustion: 'העתק את תיקיית Videos לשולחן העבודה', answer: 'cp -r Videos Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'location Quiz', qustionNumber: 0, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find', answer: "find . -type f -name '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'location Quiz', qustionNumber: 1, qustion: 'חפש בספריית השורש קבצים מסוג html בעזרת הפקודה find (בצע הפנייה לשגיאות)', answer: "find / -type f -name '*.html' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'type parameter Quiz', qustionNumber: 0, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find', answer: "find . -type f -name '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'type parameter Quiz', qustionNumber: 1, qustion: 'חפש במיקומך הנוכחי תיקיות אשר מתחילות במילה test בעזרת הפקודה find', answer: "find . -type d -name 'test*'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'iname parameter Quiz', qustionNumber: 0, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות', answer: "find . -type f -iname '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'iname parameter Quiz', qustionNumber: 1, qustion: 'חפש בתיקיית השורש תיקיות אשר מתחילות במילה test html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות (בצע הפנייה לשגיאות)', answer: "find / -type d -iname 'test*' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'maxdepth parameter Quiz', qustionNumber: 0, qustion: 'חפש בתיקיית השורש קבצים מסוג html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות בעומק 3 תיקיות מקסימום (בצע הפנייה לשגיאות)', answer: "find / -type f -maxdepth 3 -iname '*.html' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'maxdepth parameter Quiz', qustionNumber: 1, qustion: 'חפש בתיקיית השורש תיקיות אשר מתחילות במילה test בעזרת הפקודה find (שלא יתייחס לאותיות גדולות או קטנות בעומק תיקייה אחת (בצע הפנייה לשגיאות', answer: "find / -type d -maxdepth 1 -iname 'test*' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 0, qustion: 'מציאת קבצים בגודל מדויק: חפש קבצים בגודל 6MB בדיוק בספרייה הנוכחית שלך (השמט את -type)', answer: 'find . -size 6M', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 1, qustion: 'קבצים גדולים מסף: חפש קבצים שגודלם עולה על 2 גיגה-בייט. (השמט את -type)', answer: 'find . -size +2G', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 2, qustion: 'מציאת קבצים קטנים יותר: אתר את כל הקבצים מתחת ל-10 קילובייט. (השמט את -type)', answer: 'find . -size -10k', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 3, qustion: 'חיפוש טווח גדלים: גלה קבצים גדולים מ-10MB אך קטנים מ-20MB. (השמט את -type)', answer: 'find . -size +10M -size -20M', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 0, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר ושל הקבוצה ושל אחרים הם קריאה וכתיבה וביצוע  ( בצע הפנייה לשגיאות )', answer: 'find / -type f -perm 777 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 1, qustion: 'חפש תיקיות בספריית השורש בעזרת הפקודה find ( כאשר ההרשאות של היוזר ושל הקבוצה  הם קריאה וכתיבה וביצוע, ושל אחרים הם קריאה ( בצע הפנייה לשגיאות ', answer: 'find / -type d -perm 774 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 2, qustion: 'חפש תיקיות בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר ושל הקבוצה  הם קריאה וכתיבה , ושל אחרים הם קריאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 664 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 3, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר הם קריאה וכתיבה, והקבוצה עם הרשאת כתיבה, ולאחרים רק הרשאת קריאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 624 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 4, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר הם קריאה וכתיבה, והקבוצה עם הרשאת קריאה, ולאחרים אין שום הרשאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 640 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },

                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 0, qustion: 'הדפס Hello World למסך', answer: 'echo Hello World', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 1, qustion: 'בצע הפנייה מחדש לטקסט Hello World לקובץ בשם test1', answer: 'echo Hello World > test1', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 2, qustion: 'בצע הפנייה מחדש לטקסט Good לקובץ בשם test2', answer: 'echo Good > test2', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 3, qustion: 'מזג את תוכנו של test1  לקובץ test3', answer: 'cat test1 > test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 4, qustion: 'מזג את תוכנו של test2  לקובץ test3 (שים לב לא לדרוס את תוכן הקובץ test3)', answer: 'cat test2 >> test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 0, qustion: 'הדפס את תוכנו של קובץ בשם x  בצע עליו grep, שינסה למצוא האם EF קיימת בתוך הקובץ.', answer: "cat x | grep 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 1, qustion: 'הדפס את תוכנו של קובץ בשם x  בצע עליו grep, שינסה למצוא האם EF קיימת בתוך הקובץ  ללא רגישות לאותיות תחיליות.', answer: "cat x | grep -i 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 2, qustion: 'הדפס את תוכנו של קובץ בשם x ובצע grep שימצא את מה שלא תואם בתבנית החיפוש', answer: "cat x | grep -v 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 3, qustion: 'הדפס את תוכנו של קובץ בשם x בצע grep שיחזיר רק את התבנית התואמת ולא את כל המחרוזת', answer: "cat x | grep -o 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },


                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 0, qustion: 'מה התו המיוחד ללכידת ירידת שורה (אינטר)', answer: '\\r', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 1, qustion: 'מה התו המיוחד ללכידת תו NULL', answer: '\\0', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 2, qustion: 'מה התו המיוחד ללכידת תו ASCII', answer: '\\nnn', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 3, qustion: 'כתוב תבנית ללכידת Hello $', answer: 'Hello\\s\\$', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 4, qustion: 'מה התו שמסמל רווח', answer: '\\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },


                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 0, qustion: 'מה התו שמסמל תחילת מחרוזת', answer: '^', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 1, qustion: 'מה התו שמסמל סוף מחרוזת', answer: '$', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 2, qustion: 'מה התו שמסמל כל תו', answer: '.', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 3, qustion: 'כיצד אתה מבריח את התו שמסמל כל תו', answer: '\\.', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 4, qustion: 'מה התו שמסמל רווח', answer: '\\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 5, qustion: 'צור תבנית שתייצג כל אות באנגלית', answer: '[a-zA-Z]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 6, qustion: 'צור תבנית שתייצג סיפרה', answer: '[0-9]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },


                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 0, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית', answer: '[a-zA-Z]+', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 1, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית רווח', answer: '[a-zA-Z]+\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 2, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית רווח אות אחת או יותר באנגלית', answer: '[a-zA-Z]+\s[a-zA-Z]+', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 3, qustion: 'צור תבנית שתייצג מספר בין הופעה אחת ל 3 הופעות', answer: '[0-9]{1,3}', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 4, qustion: 'צור תבנית של כתובות IP', answer: '[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },


                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 0, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות אותיות גדולות באנגלית בתוכן', answer: 'cat password | grep [[:upper:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 1, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות אותיות קטנות באנגלית בתוכן', answer: 'cat password | grep [[:lower:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 2, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות רווחים בתוכן ', answer: 'cat password | grep [[:space:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 3, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות ספרות בתוכן', answer: 'cat password | grep [[:digit:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 4, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות ערכים הקסדצימלים בתוכן', answer: 'cat password | grep [[:xdigit:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 5, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות סימני פיסוק בתוכן', answer: 'cat password | grep [[:punct:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 6, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות תווים אלפאביתים בתוכן', answer: 'cat password | grep [[:alpha:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 7, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות תווים אלפאביתים וספרות בתוכן', answer: 'cat password | grep [[:alnum:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 0, qustion: 'צור קבוצה התואמת את הרצף המדויק "xyz"', answer: '(xyz)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 1, qustion: 'צור קבוצה שמוצאת קבוצת xyz ואחריה x', answer: '(xyz)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 2, qustion: 'צור קבוצה שתמצא רצפים של yz או zy', answer: '(yz|zy)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 0, qustion: 'Positive Lookahead: צור תבנית שבה יש אחרי x את האות y אבל שהאות y לא תהיה בתוצאה', answer: 'x(?=y)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 1, qustion: 'Negative Lookahead: צור תבנית שתלכוד את האות x אבל שאין אחריה את האות y', answer: 'x(?!y)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 2, qustion: 'Positive Lookbehind: צור תבנית שתלכוד את האות x שלפניה יש את האות y אבל האות y לא תהיה כלולה בתוצאה', answer: '(?<=y)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 3, qustion: 'Negative Lookbehind: צור תבנית שתלכוד את האות x שלפניה אין את האות y, האות y לא תכלל בתוצאה', answer: '(?<!y)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },


            ],

            testResults: [
                { id: 0, Course: 'Linux', difficulty: 'novice', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },
                { id: 1, Course: 'Linux', difficulty: 'intermediate', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },
                { id: 2, Course: 'Linux', difficulty: 'expert', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },

                { id: 3, Course: 'Regular Expresion', difficulty: 'novice', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },
                { id: 4, Course: 'Regular Expresion', difficulty: 'intermediate', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },
                { id: 5, Course: 'Regular Expresion', difficulty: 'expert', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },

                { id: 6, Course: 'Networking', difficulty: 'novice', totalPoints: 0, took: 0, maxTestTotalPoints: 0 },
            ],
            testQustions: [

                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 0, qustion: 'צור 3 קבצים בפקודה אחת בשם test1 test2 test3 באמצעות הפקודה touch', answer: 'touch test1 test2 test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 1, qustion: 'מחוק את הקובץ test1', answer: 'rm test1', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 2, qustion: 'שנה את מיקומו של הקובץ test2 לתיקיית שולחן העבודה ( כמו ctrl + x )', answer: 'mv test2 Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 3, qustion: 'שנה את שמו של הקובץ test3 ל - test13', answer: 'mv test3 test13', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with files Quiz', qustionNumber: 4, qustion: 'העתק את הקובץ tets13  לשולחן העבודה ( כמו ctrl + v )', answer: 'cp test13 Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 5, qustion: 'צור תיקייה בשם unix', answer: 'mkdir unix', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 6, qustion: 'כנס לתיקייה unix שיצרת', answer: 'cd unix', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 0, Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 7, qustion: 'כתוב פקודה אשר מוציאה אותך מהתיקייה שבה אתה נמצא', answer: 'cd ..', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                // { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 8, qustion: 'שנה את שם התיקייה ל - linux', answer: 'mv unix linux', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},
                // { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 9, qustion: 'מחק את התיקייה linux', answer: 'rm -r linux', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},
                // { Course: 'Linux', subject: 'Files & Folders', subTopic: 'Working with folders Quiz', qustionNumber: 10, qustion: 'העתק את תיקיית Videos לשולחן העבודה', answer: 'cp -r Videos Desktop', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 11, qustion: 'הדפס Hello World למסך', answer: 'echo Hello World', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 12, qustion: 'בצע הפנייה מחדש לטקסט Hello World לקובץ בשם test1', answer: 'echo Hello World > test1', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 13, qustion: 'בצע הפנייה מחדש לטקסט Good לקובץ בשם test2', answer: 'echo Good > test2', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 14, qustion: 'מזג את תוכנו של test1  לקובץ test3', answer: 'cat test1 > test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice' , qustionType: ''},


                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'location Quiz', qustionNumber: 0, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find', answer: "find . -type f -name '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'location Quiz', qustionNumber: 1, qustion: 'חפש בספריית השורש קבצים מסוג html בעזרת הפקודה find (בצע הפנייה לשגיאות)', answer: "find / -type f -name '*.html' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'type parameter Quiz', qustionNumber: 2, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find', answer: "find . -type f -name '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'type parameter Quiz', qustionNumber: 3, qustion: 'חפש במיקומך הנוכחי תיקיות אשר מתחילות במילה test בעזרת הפקודה find', answer: "find . -type d -name 'test*'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'iname parameter Quiz', qustionNumber: 4, qustion: 'חפש במיקומך הנוכחי קבצים מסוג html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות', answer: "find . -type f -iname '*.html'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'iname parameter Quiz', qustionNumber: 5, qustion: 'חפש בתיקיית השורש תיקיות אשר מתחילות במילה test html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות (בצע הפנייה לשגיאות)', answer: "find / -type d -iname 'test*' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'maxdepth parameter Quiz', qustionNumber: 6, qustion: 'חפש בתיקיית השורש קבצים מסוג html בעזרת הפקודה find שלא יתייחס לאותיות גדולות או קטנות בעומק 3 תיקיות מקסימום (בצע הפנייה לשגיאות)', answer: "find / -type f -maxdepth 3 -iname '*.html' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'maxdepth parameter Quiz', qustionNumber: 7, qustion: 'חפש בתיקיית השורש תיקיות אשר מתחילות במילה test בעזרת הפקודה find (שלא יתייחס לאותיות גדולות או קטנות בעומק תיקייה אחת (בצע הפנייה לשגיאות', answer: "find / -type d -maxdepth 1 -iname 'test*' 2>/dev/null", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 1, Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 8, qustion: 'מציאת קבצים בגודל מדויק: חפש קבצים בגודל 6MB בדיוק בספרייה הנוכחית שלך (השמט את -type)', answer: 'find . -size 6M', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                // { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 9, qustion: 'קבצים גדולים מסף: חפש קבצים שגודלם עולה על 2 גיגה-בייט. (השמט את -type)', answer: 'find . -size +2G', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate' , qustionType: ''},
                // { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 10, qustion: 'מציאת קבצים קטנים יותר: אתר את כל הקבצים מתחת ל-10 קילובייט. (השמט את -type)', answer: 'find . -size -10k', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate' , qustionType: ''},
                // { Course: 'Linux', subject: 'Find Command', subTopic: 'size parameter Quiz', qustionNumber: 11, qustion: 'חיפוש טווח גדלים: גלה קבצים גדולים מ-10MB אך קטנים מ-20MB. (השמט את -type)', answer: 'find . -size +10M -size -20M', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate' , qustionType: ''},
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'cat Command Quiz', qustionNumber: 12, qustion: 'מזג את תוכנו של test2  לקובץ test3 (שים לב לא לדרוס את תוכן הקובץ test3)', answer: 'cat test2 >> test3', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 13, qustion: 'הדפס את תוכנו של קובץ בשם x  בצע עליו grep, שינסה למצוא האם EF קיימת בתוך הקובץ.', answer: "cat x | grep 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 14, qustion: 'הדפס את תוכנו של קובץ בשם x  בצע עליו grep, שינסה למצוא האם EF קיימת בתוך הקובץ  ללא רגישות לאותיות תחיליות.', answer: "cat x | grep -i 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 15, qustion: 'הדפס את תוכנו של קובץ בשם x ובצע grep שימצא את מה שלא תואם בתבנית החיפוש', answer: "cat x | grep -v 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                // { Course: 'Linux', subject: 'Text manipulation', subTopic: 'grep Command Quiz', qustionNumber: 16, qustion: 'הדפס את תוכנו של קובץ בשם x בצע grep שיחזיר רק את התבנית התואמת ולא את כל המחרוזת', answer: "cat x | grep -o 'EF'", userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { id: 2, Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 0, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר ושל הקבוצה ושל אחרים הם קריאה וכתיבה וביצוע  ( בצע הפנייה לשגיאות )', answer: 'find / -type f -perm 777 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 2, Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 1, qustion: 'חפש תיקיות בספריית השורש בעזרת הפקודה find ( כאשר ההרשאות של היוזר ושל הקבוצה  הם קריאה וכתיבה וביצוע, ושל אחרים הם קריאה ( בצע הפנייה לשגיאות ', answer: 'find / -type d -perm 774 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 2, Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 2, qustion: 'חפש תיקיות בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר ושל הקבוצה  הם קריאה וכתיבה , ושל אחרים הם קריאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 664 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 2, Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 3, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר הם קריאה וכתיבה, והקבוצה עם הרשאת כתיבה, ולאחרים רק הרשאת קריאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 624 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 2, Course: 'Linux', subject: 'Find Command', subTopic: 'perm parameter Quiz', qustionNumber: 4, qustion: 'חפש קבצים בספריית השורש בעזרת הפקודה find כאשר ההרשאות של היוזר הם קריאה וכתיבה, והקבוצה עם הרשאת קריאה, ולאחרים אין שום הרשאה ( בצע הפנייה לשגיאות )', answer: 'find / -type d -perm 640 2>/dev/null', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },


                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 0, qustion: 'מה התו המיוחד ללכידת ירידת שורה (אינטר)', answer: '\\r', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 1, qustion: 'מה התו המיוחד ללכידת תו NULL', answer: '\\0', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 2, qustion: 'מה התו המיוחד ללכידת תו ASCII', answer: '\\nnn', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 3, qustion: 'כתוב תבנית ללכידת Hello $', answer: 'Hello\\s\\$', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Characters Quiz', qustionNumber: 4, qustion: 'מה התו שמסמל רווח', answer: '\\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 5, qustion: 'מה התו שמסמל תחילת מחרוזת', answer: '^', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 6, qustion: 'מה התו שמסמל סוף מחרוזת', answer: '$', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 7, qustion: 'מה התו שמסמל כל תו', answer: '.', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 8, qustion: 'כיצד אתה מבריח את התו שמסמל כל תו', answer: '\\.', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 9, qustion: 'מה התו שמסמל רווח', answer: '\\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 10, qustion: 'צור תבנית שתייצג כל אות באנגלית', answer: '[a-zA-Z]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Metacharacters Quiz', qustionNumber: 11, qustion: 'צור תבנית שתייצג סיפרה', answer: '[0-9]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 12, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות אותיות גדולות באנגלית בתוכן', answer: 'cat password | grep [[:upper:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 13, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות אותיות קטנות באנגלית בתוכן', answer: 'cat password | grep [[:lower:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 14, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות רווחים בתוכן ', answer: 'cat password | grep [[:space:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 15, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות ספרות בתוכן', answer: 'cat password | grep [[:digit:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },
                { id: 3, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 16, qustion: 'צור קבוצה התואמת את הרצף המדויק "xyz"', answer: '(xyz)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: '' },


                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 0, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית', answer: '[a-zA-Z]+', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 1, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית רווח', answer: '[a-zA-Z]+\s', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 2, qustion: 'צור תבנית שתייצג אות אחת או יותר באנגלית רווח אות אחת או יותר באנגלית', answer: '[a-zA-Z]+\s[a-zA-Z]+', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 3, qustion: 'צור תבנית שתייצג מספר בין הופעה אחת ל 3 הופעות', answer: '[0-9]{1,3}', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Quantifiers Quiz', qustionNumber: 4, qustion: 'צור תבנית של כתובות IP', answer: '[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 5, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות ערכים הקסדצימלים בתוכן', answer: 'cat password | grep [[:xdigit:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 6, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות סימני פיסוק בתוכן', answer: 'cat password | grep [[:punct:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 7, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות תווים אלפאביתים בתוכן', answer: 'cat password | grep [[:alpha:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Character Classes Quiz', qustionNumber: 8, qustion: 'בצע הדפסה של קובץ בשם password שרשר לו grep שיציג רק סיסמאות שמכילות תווים אלפאביתים וספרות בתוכן', answer: 'cat password | grep [[:alnum:]]', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 9, qustion: 'צור קבוצה שמוצאת קבוצת xyz ואחריה x', answer: '(xyz)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },
                { id: 4, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Grouping Quiz', qustionNumber: 10, qustion: 'צור קבוצה שתמצא רצפים של yz או zy', answer: '(yz|zy)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'intermediate', qustionType: '' },

                { id: 5, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 0, qustion: 'Positive Lookahead: צור תבנית שבה יש אחרי x את האות y אבל שהאות y לא תהיה בתוצאה', answer: 'x(?=y)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 5, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 1, qustion: 'Negative Lookahead: צור תבנית שתלכוד את האות x אבל שאין אחריה את האות y', answer: 'x(?!y)', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 5, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 2, qustion: 'Positive Lookbehind: צור תבנית שתלכוד את האות x שלפניה יש את האות y אבל האות y לא תהיה כלולה בתוצאה', answer: '(?<=y)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },
                { id: 5, Course: 'Regular Expresion', subject: 'Regular Expresion', subTopic: 'Lookarounds Quiz', qustionNumber: 3, qustion: 'Negative Lookbehind: צור תבנית שתלכוד את האות x שלפניה אין את האות y, האות y לא תכלל בתוצאה', answer: '(?<!y)x', userAnswer: '', correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'expert', qustionType: '' },

                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Base Convertion Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 0, qustion: 'מה הערך האקסאדצימלי של 52', answers: ['34', '43', '50', '60'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'IP Address Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 1, qustion: 'האם כתובת IP צריכה להיות יחודית', answers: ['אמת', 'שקר'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Pysical Address Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 2, qustion: 'כתובת פיזית מורכבת מ 6 בתים', answers: ['3 הבתים הראשונים זה המזהה היחודי של הכרטיס והאחרונים של היצרן', '4 הבתים הראשונים זה של היצרן ושני הבתים האחרונים זה המזהה היחודי של הכרטיס', '3 הבתים הראשונים הם המזהים של היצרן והאחרונים הם של המזהה היחודי'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'ARP Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 3, qustion: 'פרוטוקל ARP נועד לברר את הכתובת הפיזית של עמדה ברשת', answers: ['הרשת העולמית', 'הרשת המקומית בלבד', 'בכל רשת מקומית'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Router Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 4, qustion: 'שער היציאה של הנתב חייב להיות זהה בכל עמדה', answers: ['אמת', 'שקר'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'DHCP Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 5, qustion: 'שירות ה - DHCP יכול להגדיר', answers: ['כתובת IP יחודית, מיסוך, כתובת שער היציאה, שרת DNS', 'כתובת IP יחודית, שער היציאה', 'שער היציאה, מיסוך, שרת DNS'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Static Routing Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 6, qustion: 'ניתוב סטטי מגדיר את', answers: ['נתיב שלא ידוע לנתב שזה בעצם שער הכניסה לאותו נתב', 'נתיב שלא ידוע שזה בעצם מזהה הרשת של אותו נתיב לא ידוע', 'נתיב ידוע שרוצים להגיע אליו בדרך שונה'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Classful Net Masks Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 7, qustion: 'המיסוך שמוגדר לארגון בינוני הוא:', answers: ['255.255.255.0', '255.0.0.0', '255.255.0.0'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'CIDR Notation Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 8, qustion: 'היצוג של המיסוך 255.255.255.128 הוא:', answers: ['/24', '/26', '/28', '/25'], userAnswerIndex: 0, correctAnswerIndex: 3, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Server & Cleint Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 9, qustion: 'שרת Web הוא:', answers: ['שרת אינטרנט הוא תוכנת מחשב וחומרה בסיסית אשר מאזינה על פורט מסויים ומגיבה לבקשות של הלקוח באמצעות פרוטוקול HTTP (פרוטוקול הרשת שנוצר להפצת תוכן אינטרנט).', 'שרת אינטרנט הוא תוכנת מחשב וחומרה בסיסית אשר מאזינה על פורט מסויים ומגיבה לבקשות של הלקוח באמצעות פרוטוקול SSH (פרוטוקול הרשת שנוצר להפצת תוכן אינטרנט).', 'שרת אינטרנט הוא תוכנת מחשב וחומרה בסיסית אשר מאזינה על פורט מסויים ומגיבה לבקשות של הלקוח באמצעות פרוטוקול ARP (פרוטוקול הרשת שנוצר להפצת תוכן אינטרנט).', 'שרת אינטרנט הוא תוכנת מחשב וחומרה בסיסית אשר מאזינה על פורט מסויים ומגיבה לבקשות של הלקוח באמצעות פרוטוקול DHCP (פרוטוקול הרשת שנוצר להפצת תוכן אינטרנט).'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'DNS Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 10, qustion: 'שרת DNS ממיר:', answers: ['כתובת לוגית לכתובת פיזית', 'כתובת פיזית לכתובת לוגית', 'שם מרחב לכתובת IP', 'כתובת IP לשם מרחב'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'Public and Private IP Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 11, qustion: 'כתובת ציבורית יכולה לתקשר עם כתובת פרטית כיצד?', answers: ['באמצעות ספק האינטרנט שלך', 'באמצעות טבלת NAT', 'באמצעות DNS', 'כתובת ציבורית לא יכולה לתקשר עם כתובת ציבורית'], userAnswerIndex: 0, correctAnswerIndex: 3, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },

                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Handshake', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 12, qustion: 'לחיצת היד המשולשת היא', answers: ['SYN, SYN-ACK,ACK', 'SYN,ACK,SYN', 'SYN,SYN,ACK', 'SYN,SYN,ACK'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'tcpHandShake' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz RTT', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 13, qustion: 'ב - wireshark בעמודת הזמן באיזה שלב של לחיצת היד המשולשת רואים את זמן התגובה של השרת (Round Trip Time)?', answers: ['SYN', 'SYN-ACK', 'ACK'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'rtt' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Sequence Number', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 14, qustion: 'מה תפקידו של המספר סידורי (Sequence Number) בפרוטוקול TCP ?', answers: ['לעקוב אחרי המידע שנשלח', 'אין לו תפקיד ממשי', 'לעקוב אחרי המידע המתקבל'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Acknowledgement Number', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 15, qustion: 'מה תפקידו של מספר האישור (acknowledgement number) בפרוטוקול TCP ?', answers: ['לעקוב אחרי המידע שנשלח', 'אין לו תפקיד ממשי', 'לעקוב אחרי המידע שהתקבל'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Sequence Number Starts', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 16, qustion: 'לאחר שנוצר הקשר בין 2 העמדות, האם ספרור הפקטות מתחיל מ - 0?', answers: ['אמת', 'שקר'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Ghost Byte', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 17, qustion: 'מה שמו של הספרור הראשוני ?', answers: ['first', 'The number', 'The Byte', 'Ghost Byte'], userAnswerIndex: 0, correctAnswerIndex: 3, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'Ghost-Byte' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Still in conversation', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 18, qustion: 'כאשר אתה מבקש מידע משרת, ישנו עיכוב מסויים, איזה פקטה TCP ישלח ?', answers: ['RST', 'Keep-Alive', 'Wait', 'Checking'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Round Trip Time', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 19, qustion: 'כאשר הלקוח מברר אם ישנו קשר השרת זמן התגובה יהיה בקירוב לזמן שלקח לשרת להגיב בלחיצת היד המשולשת ?', answers: ['אמת', 'שקר'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Duplications', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 20, qustion: 'הבה נאמר שהיה חביון רשתי, פקטה 4 לא הגיעה ליעד, בינתיים השרת שלח עוד 3 פקטות 5, 6, 7: איזה הודעת TCP תהיה על הפקטות הללו שהם יגיעו לשרת?', answers: ['Failed', 'Inforamtion Lost', 'DUP-ACKS'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'dup-acks' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz when its a duplicated ack', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 21, qustion: 'אם אראה אישור כפול אך מעודכן בו שהיה עדכון לגודל החלון , האם זה נחשב לאישור כפול ?', answers: ['כן', 'לא'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'dup-acks' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Buffer is full', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 22, qustion: 'איזה פקטה תשלח אם המאגר לא יכול לקבל מידע', answers: ['Stop Sending', 'Buffer is Full', 'TCP ZeroWindow'], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'ZeroWindow' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz cant talk', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 23, qustion: 'חבילת איפוס (RST) משמשת את מי ששלח אותה כדי לציין:', answers: ['שהיא לא זמינה לשוחח כעת', 'אני מבצע אתחול למערכת', 'אני שולח מידע מחדש המתן'], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz lets finsh', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 24, qustion: 'מהם הדגלים הדולקים בכל שלב ב - לחיצת היד המשולשת בפרוטוקול TCP לסיום הקשר?', answers: ['FIN-ACK, FIN-ACK, FIN-ACK', 'FIN-ACK,FIN-ACK,ACK', 'SYN,SYN,FIN-ACK', 'FIN-SYN,SYN,FIN-ACK'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'tcpHandShake' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Window scale', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 25, qustion: 'ב - wireshark בחלונית של פרטי המנה תחת Options, יהיה את השדה הבא: TCP Option - Window scale: 7 (multiply by 128), המספר 128 נובע מ:', answers: ['128 בתים שהיו בלחיצת היד הראשונית', '128 בתים של גודל החלון המקורי', 'שתיים בחזקת אַמַת מִדָת החלון '], userAnswerIndex: 0, correctAnswerIndex: 2, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'wireshark' },
                { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz Window size', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 26, qustion: 'אם אַמַת מִדָת החלון הייתה 7 וגודל החלון היה 10 בתים מה גודלו של החלון כעת ?', answers: ['800 Bytes', '1280 Bytes', '12800 Bytes'], userAnswerIndex: 0, correctAnswerIndex: 1, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: 'window-scale' },

                // { id: 6, Course: 'Networking-Quiz-Test', subject: 'Networking', subTopic: 'TCP Quiz', radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionNumber: 13, qustion: '', answers: ['', '', '', ''], userAnswerIndex: 0, correctAnswerIndex: 0, correct: '', points: 0, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: 'novice', qustionType: 'radio', image: '' },




            ],
            qustions: [

            ],
            // this is for the next button click in TreeItem it's need th itemId
            itemIdArray: [

            ],

            nextItemId: '',

            // this will contain the regular expression pattern, inputString, number of matheces 
            RegEx: [
                { id: 0, pattern: '.', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 1, pattern: '^He', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 2, pattern: 'ld!$', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 3, pattern: 'e|o', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },

                { id: 4, pattern: 'colou+r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 5, pattern: 'colou?r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 6, pattern: 'colou*r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 7, pattern: 'colou{1}r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 8, pattern: 'colou{1,}r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 9, pattern: 'colou{1,4}r', inputString: 'color colour collar colouuuur', numberOfMatheces: 0, matchSpans: '' },
                { id: 10, pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}', inputString: 'Hello, my email id is myemail@email.com', numberOfMatheces: 0, matchSpans: '' },

                { id: 11, pattern: 'H', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 12, pattern: 'Hello', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 13, pattern: 'orl', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 14, pattern: 'Hi', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 15, pattern: 'hello', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 16, pattern: '\\s', inputString: 'Hello World!', numberOfMatheces: 0, matchSpans: '' },
                { id: 17, pattern: '\\$', inputString: 'Total Profit $5000', numberOfMatheces: 0, matchSpans: '' },

                { id: 18, pattern: '(xyz)', inputString: 'xyz yz xyzx xzy', numberOfMatheces: 0, matchSpans: '' },
                { id: 19, pattern: '(xyz)x', inputString: 'xyz yz xyzx xzy', numberOfMatheces: 0, matchSpans: '' },
                { id: 20, pattern: '(yz|zy)', inputString: 'xyz yz xyzx xzy', numberOfMatheces: 0, matchSpans: '' },
                { id: 21, pattern: '(\\d{2})-(\\d{4})', inputString: '2-2321 55-99878 456-545', numberOfMatheces: 0, matchSpans: '' },
                { id: 22, pattern: '(?:xyz)', inputString: 'xyz yz xyzx xzy', numberOfMatheces: 0, matchSpans: '' },

                { id: 23, pattern: 'x(?=y)', inputString: 'xyzabyxzbxyab', numberOfMatheces: 0, matchSpans: '' },
                { id: 24, pattern: '\\w(?=\\s)', inputString: 'What is your name?', numberOfMatheces: 0, matchSpans: '' },
                { id: 25, pattern: 'x(?!y)', inputString: 'xyzabyxzbxyab', numberOfMatheces: 0, matchSpans: '' },
                { id: 26, pattern: '\\b\\w+\\b(?!\\?)', inputString: 'What is your name?', numberOfMatheces: 0, matchSpans: '' },
                { id: 27, pattern: '(?<=y)x', inputString: 'xyzabyxzbxyab', numberOfMatheces: 0, matchSpans: '' },
                { id: 28, pattern: '(?<=\\s)\\b\\w+\\b', inputString: 'What is your name?', numberOfMatheces: 0, matchSpans: '' },
                { id: 29, pattern: '(?<!y)x', inputString: 'xyzabyxzbxyab', numberOfMatheces: 0, matchSpans: '' },
                { id: 30, pattern: '(?<!\\s)\\b\\w+\\b', inputString: 'What is your name?', numberOfMatheces: 0, matchSpans: '' },

            ],
            matchSpanActive: false,

            codeExmple: [
                { id: 0, language: 'js', code: "var regexPattern = /(Hello)/;var str = 'Hello World!';//replaces Hello with Hi;var newstr = str.replace(regexPattern, 'Hi');console.log(newstr);  //output: 'Hi World!';" },
                { id: 1, language: 'js', code: "//captures first digit as group#1, second three digits as group#2 ;//and four digits as group#3 and last four digits as group#4 ;var regexPattern = /(\d)(\d{3})(\d{3})(\d{4})/;var str = '+12015645555';//adding - between each group;var newstr = str.replace(regexPattern, '$1-$2-$3-$4');console.log(newstr);//output: +1-201-564-5555;" }
            ]

        };
    }
    setCompletedIndex = (completedIndex) => {
        this.setState({ completedIndex: completedIndex })
    }


    setQuizQustions = (Course, qustionType) => {

        let qustions = this.state.testQustions.filter(item => item.Course === Course && item.qustionType === qustionType)
        console.log(qustions);
        // check the next qustion the user sholud be at, 
        // iterate until this.stats.qustions.userAnswer != ''
        let qustionNumber = 0;

        for (let i = 0; i < qustions.length; i++) {
            if (qustions[i].userAnswer == '') {
                qustionNumber = qustions[i].qustionNumber
                break;
            }
        }

        this.setState({ qustions: qustions, qustionNumber: qustionNumber, startTest: true })
    }
    setTestQustions = (Course, difficulty, qustionType) => {

        let qustions = this.state.testQustions.filter(item => item.Course === Course && item.difficulty === difficulty)
        console.log('====================================');
        console.dir(`qustions:${qustions}`);
        console.log('====================================');
        // check the next qustion the user sholud be at, 
        // iterate until this.stats.qustions.userAnswer != ''
        let qustionNumber = 0;

        for (let i = 0; i < qustions.length; i++) {
            if (qustions[i].userAnswer == '') {
                qustionNumber = qustions[i].qustionNumber
                break;
            }
        }
console.log('====================================');
console.log(`setTestQustions`);
console.log('====================================');
        // show the timer and start the timer ticking
        this.setState({ qustions: qustions, qustionNumber: qustionNumber, startTest: true })
    }
    setQustionPoints = (radioButtonValue, helperText, error, id, Course, subject, subTopic, qustionNumber, qustion, answers, userAnswerIndex, correctAnswerIndex, points, difficulty, qustionType, image) => {

        console.log('====================================');
        console.log('setQustionPoints',qustion );
        console.log('====================================');
        console.log(`radioButtonValue:${radioButtonValue}, helperText:${helperText}, error:${error}, id:${id}, Course:${Course}, subject:${subject}, subTopic:${subTopic}, qustionNumber:${qustionNumber}, qustion:${qustion}, answers:${answers}, userAnswerIndex:${userAnswerIndex}, correctAnswerIndex:${correctAnswerIndex}, points:${points}, difficulty:${difficulty}, qustionType:${qustionType}, image:${image}`);

        this.setState(prevState => ({
            qustions: prevState.qustions.map(obj => (obj.qustionNumber === qustionNumber) ? Object.assign(obj, { radioButtonValue: radioButtonValue, helperText: helperText, error: error, id: id, Course: Course, subject: subject, subTopic: subTopic, qustionNumber: qustionNumber, qustion: qustion, answers: answers, userAnswerIndex: userAnswerIndex, correctAnswerIndex: correctAnswerIndex, correct: '', points: points, checkmarkShape: '', answerVisibility: 'hide-answer', difficulty: difficulty, qustionType: qustionType }) : obj)
        }));

        this.setState({ radioButtonValue: radioButtonValue, helperText: helperText, error: error,qustion:qustion })
        
    }

    setMaxTestTotalPoints = () => {

        let initialValue = 0;
        let id = this.state.testQustions[0].id
        let maxTestTotalPointsArray = []

        this.state.testQustions.map(i => {

            if (i.id === id)
                initialValue += 10
            else {
                maxTestTotalPointsArray.push(initialValue)
                initialValue = 0
                id = i.id
                initialValue += 10
            }

        })
        maxTestTotalPointsArray.push(initialValue)
        // console.log(maxTestTotalPointsArray);
        this.setState(prevState => ({
            testResults: prevState.testResults.map(
                (obj, index) => ({ id: obj.id, Course: obj.Course, difficulty: obj.difficulty, totalPoints: obj.totalPoints, took: obj.took, maxTestTotalPoints: maxTestTotalPointsArray[index] })
            )
        }));
    }

    setTestDone = (id, Course, difficulty, took) => {

        // calculate all the scores : 90
        let qustions = this.state.testQustions.filter(item => item.id === id && item.Course === Course && item.difficulty === difficulty)
        const initialValue = 0;
        let totalPoints = qustions.reduce((accumulator, currentValue) => accumulator + currentValue.points, initialValue)
        // console.log(qustions, id, Course, difficulty, took);
        // save the qustiones array answers in db
        // the time the test took : 26 minutes
        // stop the watch
        this.setState({ stopTest: true })
        this.setState(prevState => ({
            testResults: prevState.testResults.map(
                obj => (obj.id === id ? Object.assign(obj, { Course: Course, difficulty: difficulty, totalPoints: totalPoints, took: took }) : obj)
            )
        }));
        // disable the terminal in this 

    }


    setCredentials = (Credentials) => {

        this.setState({ userProfileVisiability: '', name: Credentials.name, email: Credentials.email, family_name: Credentials.family_name, given_name: Credentials.given_name, picture: Credentials.picture, sub: Credentials.sub });
    }

    resetComponentName = () => {
        this.setState({ documentSubTopic: '', pageTitle: 'Welcome To Web School 26 ACADEMY The Way To Be A Pro' })
    }

    setDocumentSubTopic = (item, subTopic, qustionType = null) => {

        let id, subject, Course, qustions, radioButtonValue, helperText, error, qustionNumber, qustion, answers, userAnswerIndex, points, difficulty, correctAnswerIndex
        let componentName, componentIndex = 0, quizImage // CorePrinciples, Architecture

        qustionType = (qustionType != null) ? qustionType : this.state.qustionType
        Course = this.state.choosenCourse.course

        console.log("item", item, "subTopic", subTopic);

        item.subTopics.map((i, index) => {
            if (i == subTopic)
                componentIndex = index
            componentName = item.allComponents[componentIndex]
        })


        if (qustionType === 'terminal') {

            qustions = this.state.allQustions.filter(item => item.subTopic === subTopic)
            qustionNumber = 0;

            for (let i = 0; i < qustions.length; i++) {
                if (qustions[i].userAnswer == '') {
                    qustionNumber = qustions[i].qustionNumber
                    break;
                }
            }
        }
        else {
            qustions = this.state.testQustions.filter(item => item.Course === Course && item.qustionType === qustionType)

            // the correct qustionNumber is to iterate threw the itemIdArray in the state
            // find the index of the subTopic
            // console.log(this.state.itemIdArray, subTopic);

            qustionNumber = this.state.itemIdArray.findIndex(x => x.includes(subTopic))
            qustionNumber = (qustionNumber > 0) ? qustionNumber - 1 : qustionNumber

            if (qustions.length != 0) {
                radioButtonValue = qustions[qustionNumber].radioButtonValue
                helperText = qustions[qustionNumber].helperText
                error = qustions[qustionNumber].error
                id = qustions[qustionNumber].id
                Course = qustions[qustionNumber].Course
                subject = qustions[qustionNumber].subject
                subTopic = qustions[qustionNumber].subTopic
                qustion = qustions[qustionNumber].qustion
                answers = qustions[qustionNumber].answers
                userAnswerIndex = qustions[qustionNumber].userAnswerIndex
                correctAnswerIndex = qustions[qustionNumber].correctAnswerIndex
                points = qustions[qustionNumber].points
                difficulty = qustions[qustionNumber].difficulty
                qustionType = qustions[qustionNumber].qustionType
                quizImage = (qustions.length != 0) ? qustions[qustionNumber].image : ''

            } else {
                radioButtonValue = 0
                helperText = "בחר בחכמה 🦉"
                error = false
                id = ''
                Course = ''
                subject = ''
                qustion = ''
                answers = []
                userAnswerIndex = null
                correctAnswerIndex = null
                points = 0
                difficulty = ''

            }


        }

        // this.hideCheckmark()
        // check the next qustion the user sholud be at, 
        // iterate until this.stats.qustions.userAnswer != ''


        // we will find the index of the menu item that was pressed by the documentSubTopic for example:
        // if we pressed on Characters Quiz, find this partial text in itemIdArray, and update its index in the
        // subTopicIndex 

        //this code returns the index of the subTopic if it finds it in the itemdArray
        let subTopicIndex
        var BreakException = {};

        try {
            this.state.itemIdArray.forEach((i, index) => {
                // console.log(i, subTopic, i.includes(subTopic), index);
                if (i.includes(subTopic)) {
                    subTopicIndex = index
                    throw BreakException;
                }

            })
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        this.setState({ documentSubTopic: subTopic, subTopicIndex: subTopicIndex, subjectsAndSubTopicsIndex: item.id, subjectsAndSubTopicsCompletedIndex: subTopicIndex - 1, subTopic: subTopic, componentName: componentName, pageTitle: subTopic, id: id, Course: Course, subject: subject, qustion: qustion, radioButtonValue: radioButtonValue, helperText: helperText, error: error, answers: answers, userAnswerIndex: userAnswerIndex, points: points, difficulty: difficulty, qustionType: qustionType, qustions: qustions, qustionNumber: qustionNumber, correctAnswerIndex: correctAnswerIndex, quizImage: quizImage })

    }

    setSubTopicNew = (subTopic) => {
        try {
            let qustionType = (subTopic.includes('Quiz')) ? 'radio' : 'terminal'
            this.setState({ subject: subTopic, subTopic: subTopic, qustionType: qustionType })
        } catch (e) {
            console.log(e);
        }

    }
    setChoosenCourse = (course, courseIndex) => {
        this.setState({ choosenCourse: { course: course, courseIndex: courseIndex } })
    }
    setChoosenCourseSubIndex = (courseSubIndex) => {
        this.setState({ choosenCourseSubIndex: courseSubIndex })
    }
    setItemIdArray = (itemIdArray) => {
        // alert(this.state.qustionType)
        this.setState({ itemIdArray: itemIdArray })
    }
    setMatchSpanActive = () => {
        this.setState({ matchSpanActive: true })
    }

    // the first index is the array of objects in state.subjectsAndSubTopics, 
    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
    setCompletedSubTopic = (subjectsAndSubTopicsIndex, completedIndex, subjectsAndSubTopicsCompletedIndex = null, qustionType = '', qustionNumber = 0, correctAnswerIndex = null, id = null, difficulty) => {

        let obj = document.querySelectorAll("[aria-selected='true']")
        let subTopicIndex = (this.state.subTopicIndex != null && this.state.subTopicIndex != "") ? completedIndex : 0;
        let qustion = this.state.qustions[qustionNumber]?.qustion

        console.log('====================================');
        console.log(`subjectsAndSubTopicsIndex:${subjectsAndSubTopicsIndex}, completedIndex:${completedIndex}, subjectsAndSubTopicsCompletedIndex:${subjectsAndSubTopicsCompletedIndex} , qustionType = ${qustionType}, qustionNumber = ${qustionNumber}, correctAnswerIndex = ${correctAnswerIndex}, id = ${id}`);
        console.log('====================================');


        let prefix = obj[0].id.substring(0, 4)
        if (completedIndex < this.state.itemIdArray.length - 1)
            completedIndex++
        let nextItemId = this.state.itemIdArray[completedIndex]
        let elementID = `${prefix}-${nextItemId}`
        let nextElement = document.getElementById(elementID)
        let allChildren = nextElement.querySelectorAll("div.MuiTreeItem-content");
        allChildren[0].click()

        if (completedIndex < this.state.itemIdArray.length - 1)
            subTopicIndex++;

        subjectsAndSubTopicsCompletedIndex = (subjectsAndSubTopicsCompletedIndex != null) ? subjectsAndSubTopicsCompletedIndex + 1 : null;

        let startTest = (qustionType === 'radio') ? true : false;


        if (startTest) {
            this.setTestQustions('Networking-Quiz-Test', 'novice')
        }

        this.setState({ qustion:qustion, radioButtonValue: 0, helperText: "בחר בחכמה 🦉", error: false, qustionType: qustionType, qustionNumber: qustionNumber, correctAnswerIndex: correctAnswerIndex, startTest: startTest, stopTest: false, subTopicIndex: subTopicIndex, subjectsAndSubTopicsIndex: subjectsAndSubTopicsIndex, subjectsAndSubTopicsCompletedIndex: subjectsAndSubTopicsCompletedIndex,id:id })
        this.setState({difficulty:difficulty, })
    }


    setSubTopic = (subTopic) => {
        let qustions = this.state.allQustions.filter(item => item.subTopic === subTopic)
        this.hideCheckmark()
        // check the next qustion the user sholud be at, 
        // iterate until this.stats.qustions.userAnswer != ''
        let qustionNumber = 0;

        for (let i = 0; i < qustions.length; i++) {
            if (qustions[i].userAnswer == '') {
                qustionNumber = qustions[i].qustionNumber
                break;
            }
        }
        this.setState({ documentSubTopic: '', subTopic: subTopic, qustions: qustions, qustionNumber: qustionNumber })
    }


    showCheckmark = (answerData) => {

        let qustionNumber = answerData.qustionNumber
        let checkmarkShape = answerData.checkmarkShape
        let points = answerData.points
        let correct = answerData.correct
        let userAnswer = answerData.userAnswer
        // console.log(answerData);

        this.setState(prevState => ({
            qustions: prevState.qustions.map(
                obj => (obj.qustionNumber === qustionNumber ? Object.assign(obj, { userAnswer: userAnswer, correct: correct, checkmarkShape: checkmarkShape, points: points, answerVisibility: 'show-answer' }) : obj)
            )
        }));
        this.setState({ checkmarkVisiability: 'visible' })
        document.querySelector('#Terminal-ID').value = ''
        this.setState(prevState => ({
            totalPoints: prevState.allQustions.map(i => i.points)
        }))
        this.nextQustion()

    }

    nextQustion = () => {

        this.setState(prevState => ({ qustionNumber: (prevState.qustionNumber < prevState.qustions.length - 1) ? prevState.qustionNumber + 1 : prevState.qustionNumber + 0 }))
        this.hideCheckmark()

    }
    previosQustion = () => {
        this.setState(prevState => ({ qustionNumber: (prevState.qustionNumber > 0) ? prevState.qustionNumber - 1 : prevState.qustionNumber - 0 }))
        this.hideCheckmark()
    }
    hideCheckmark = () => {
        this.setState({ checkmarkVisiability: 'no-visible', clearTerminal: true })
        document.querySelector('#Terminal-ID').value = ''
        document.querySelector('#Terminal-ID').value = ''
    }


    // regex handlers : RegEx: [{ pattern: '', inputString: '', numberOfMatheces: 0 }]
    setRegEx = (RegEx, index) => {

        // this.setState({ RegEx: [{ pattern: RegEx.pattern, inputString: RegEx.inputString, numberOfMatheces: RegEx.numberOfMatheces, matchSpans: RegEx.matchSpans }] })
        // console.log(RegEx, index);
        this.setState(prevState => ({
            RegEx: prevState.RegEx.map(
                (obj, ind) => (ind == index) ? Object.assign(RegEx, { pattern: RegEx.pattern, inputString: RegEx.inputString, numberOfMatheces: RegEx.numberOfMatheces, matchSpans: RegEx.matchSpans }) : obj
            )
        }));
    }

    setSelectedMenuItemsCubes = (menuName, index) => {
        // console.log(menuName, index);
        let selectedMenuItemsCubes = []
        this.setState({ selectedMenuItemsCubes: [] });
        selectedMenuItemsCubes = this.state.menuItemsCubes.filter(item => item.index == index)
        // console.log(selectedMenuItemsCubes);
        this.setState({ selectedMenuItemsCubes: selectedMenuItemsCubes });


    }


    render() {

        // scrolls to top each page we pass
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });


        return (
            <Context.Provider value={{
                pageTitle: this.state.pageTitle,
                userProfileVisiability: this.state.userProfileVisiability,
                email: this.state.email,
                family_name: this.state.family_name,
                given_name: this.state.given_name,
                name: this.state.name,
                picture: this.state.picture,
                sub: this.state.sub,
                checkmarkVisiability: this.state.checkmarkVisiability,
                // answer: this.state.answer,
                menuItems: this.state.menuItems,
                choosenCourse: this.state.choosenCourse,
                choosenCourseSubIndex: this.state.choosenCourseSubIndex,
                itemIdArray: this.state.itemIdArray,
                courseVerticalMenu: this.state.courseVerticalMenu,
                menuItemsCubes: this.state.menuItemsCubes,
                selectedMenuItemsCubes: this.state.selectedMenuItemsCubes,
                componentName: this.state.componentName,

                radioButtonValue: this.state.radioButtonValue,
                helperText: this.state.helperText,
                error: this.state.error,
                id: this.state.id,
                Course: this.state.Course,
                subject: this.state.subject,
                subTopic: this.state.subTopic,
                qustionNumber: this.state.qustionNumber,
                qustion: this.state.qustion,
                subTopicIndex: this.state.subTopicIndex,
                subjectsAndSubTopicsIndex: this.state.subjectsAndSubTopicsIndex,
                subjectsAndSubTopicsCompletedIndex: this.state.subjectsAndSubTopicsCompletedIndex,
                qustions: this.state.qustions,
                answers: this.state.answers,
                userAnswerIndex: this.state.userAnswerIndex,
                correctAnswerIndex: this.state.correctAnswerIndex,
                points: this.state.points,
                difficulty: this.state.difficulty,
                qustionType: this.state.qustionType,
                image: this.state.image,
                testResults: this.state.testResults,
                clearTerminal: this.state.clearTerminal,
                allQustions: this.state.allQustions,
                subjectsAndSubTopics: this.state.subjectsAndSubTopics,
                totalPoints: this.state.totalPoints,
                documentSubTopic: this.state.documentSubTopic,
                componentName: this.state.componentName,
                terminalShellCommand: this.state.terminalShellCommand,
                RegEx: this.state.RegEx,
                codeExmple: this.state.codeExmple,
                startTest: this.state.startTest,
                stopTest: this.state.stopTest,

                completedIndex: this.state.completedIndex,
                actions: {
                    setCredentials: this.setCredentials,
                    hideLogin: this.hideLogin,
                    hideSignOut: this.hideSignOut,
                    signOut: this.signOut,
                    setSubTopic: this.setSubTopic,
                    setSubTopicNew: this.setSubTopicNew,
                    setChoosenCourse: this.setChoosenCourse,
                    setChoosenCourseSubIndex: this.setChoosenCourseSubIndex,
                    setItemIdArray: this.setItemIdArray,
                    setCompletedSubTopic: this.setCompletedSubTopic,
                    setDocumentSubTopic: this.setDocumentSubTopic,
                    setSelectedMenuItemsCubes: this.setSelectedMenuItemsCubes,
                    resetComponentName: this.resetComponentName,
                    showCheckmark: this.showCheckmark,
                    hideCheckmark: this.hideCheckmark,
                    nextQustion: this.nextQustion,
                    previosQustion: this.previosQustion,
                    setQustionPoints: this.setQustionPoints,
                    setTestQustions: this.setTestQustions,
                    setMaxTestTotalPoints: this.setMaxTestTotalPoints,
                    setTestDone: this.setTestDone,
                    setCompletedIndex: this.setCompletedIndex,
                    setQuizQustions: this.setQuizQustions,
                    // regex
                    setRegEx: this.setRegEx,
                    setMatchSpanActive: this.setMatchSpanActive,

                }
            }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
export const Consumer = Context.Consumer;