import React, { Component } from 'react';
import './long.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Long extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ls-l">
                                <div className="img-div"><img src={companyLogo} /></div>
                                <div className='ls-l-result'>
                                    <h4>הפקודה ls -l</h4>

                                    <p>
                                        <b>ls -l</b>
                                        <span>
                                            אם תפתח את הטרמינל ותריץ את הפקודה <span className='bold green medium-text'>ls -l</span>, תוכל לראות את רשימת הקבצים והתיקיות במיקומך הנוכחי, ממש כפי שהיית פותח סייר קבצים, אבל הפעם באופן מפורט!
                                        </span>

                                    </p>

                                </div>
                                <div className='para-after-image'>
                                    <p>כאן תוכל לראות תיקיות <span className='bold blue medium-text'>( פונט בצבע כחול )</span> <span className='bold orange medium-text'>אבל הפעם ישנה את האות d אשר נותנת אינדיקציה ברורה לגבי כך שזו תיקייה (Directory)</span></p>
                                    <p> כאן תוכל לראות קבצים ( פונט בצבע לבן או בצבע אחר) <span className='bold green medium-text'>כאן האינדקציה שבעמודה הראשונה ישנו -</span></p>
                                    <p> אבל מה זאת שורת התווים המפחידה הזאת: <span className='bold red medium-text'>rxwexwr-x -</span>? אל דאגה בתפריט Permissions נפרט על כך!</p>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 2
                                        let completedIndex = 10
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
