import React, { Component } from 'react';
import './chmod.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class CHMOD extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ugo-chmod">
                                
                                <div className='ugo-chmod-result'>
                                    <h4>שינוי הרשאות - chmod</h4>
                                    <div className="img-div"><img src={companyLogo} /></div> 
                                    <p>

                                        <span>
                                            אנו יכולים לשנות את ההרשאות באמצעות הפקודה <span className='bold green medium-text'>chmod</span>, הפניות לקבוצת ההרשאות
                                            (u - owner, g - Group, o - others, a - All users), או [+] או [-] כדי להוסיף להסיר את ההרשאות המיועדות. בדוגמה הבאה, משתמש יוצר קובץ בשם shell  בבעלותו של אותו משתמש, שאינו ניתן להפעלה, ומוגדר עם הרשאות קריאה / כתיבה לכל המשתמשים.
                                            לאחר מכן נוכל להחיל הרשאות קריאה על כל המשתמשים ולראות את התוצאה.

                                        </span>

                                        <b> הוספת הרשאת קריאה לכל המשתמשים</b>
                                        <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chmod a+r shell" />

                                        <b> הסרת הרשאת קריאה מכל המשתמשים</b>
                                        <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chmod a-r shell" />

                                        <b> הוספת כל ההרשאות, למשתמש לקבוצה ולאחרים</b>
                                        <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chmod ugo+rwx shell" />

                                        <b>הוספת כל ההרשאות, למשתמש לקבוצה ולאחרים - אוקטלי</b>
                                        <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="chmod 777 shell" />
                                        
                                    </p>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 4
                                        let completedIndex = 20
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
