import React, { Component } from 'react';
import './hackerstypes.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class HackersTypes extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper HackersTypes" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>3 סוגים של האקרים</b>
                                </p>

                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        ישנם 3 סוגים של האקרים כפי שתראה בטבלה הבאה:
                                    </span>
                                </p>
                                <div className='threehackerstype'></div>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        <b>3 סוגים של קבוצות האקרים</b>
                                    </span>
                                    <span className='c4'>
                                        כמו שבעולם הפיתוח באינטרנט ישנה חלוקה ל 3 קבוצות :
                                    </span>
                                </p>

                                <p className='c9 list-items frontend-backend-img'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>פרונטאנד - פיתוח בצד לקוח</span>
                                    <span style={{ fontSize: '17pt' }}>באקאנד - פיתוח בצד שרת</span>
                                    <span style={{ fontSize: '17pt' }}>פולסטאק - שילוב של שניהם</span>
                                </p>

                                <p className="c9">
                                    <span className='c4'>
                                        כך גם בעולם הסייבר ניתן לחלק את הלימודים ל 3 קבוצות:
                                    </span>
                                </p>

                                <p className='c9 list-items three-hackers-team'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>  <span style={{ display: 'inline-block' }} className='c13'>הצוות האדום</span>מתמקד באבטחה התקפית <span style={{ display: 'inline-block' }} className='c13'>(התקפה)</span></span>
                                    <span style={{ fontSize: '17pt' }}><span style={{ display: 'inline-block' }} className='c14'>הצוות הכחול </span> עובד על הגנה על הארגון </span>
                                    <span style={{ fontSize: '17pt' }}><span style={{ display: 'inline-block', color: 'purple' }} className='c14'>והצוות הסגול</span>שיתוף בין הצוות האדום לצוות הכחול.</span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 17
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
