import React, { Component } from 'react';
import './location.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Location extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper find-location">
                                <h4>find פקודת החיפוש</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>חיפוש באופן גרפי</b>
                                    <span>
                                        כאשר אתה מחפש קובץ או תיקייה מסוימת, אתה פותח את סייר הקבצים במקום מסוים, וביתבת הקלט אתה מקליד את שם הקובץ, או את הסיומת של הקבצים שאותם תרצה למצוא
                                    </span>
                                    <span>לדוגמה אם פתחת את סייר הקבצים ואתה נמצא בתיקיית הבית, אזי החיפוש יתבצע בתיקיית הבית ובכל תתי התיקיות שם!</span>
                                    <span>אם תתחיל את החיפוש בתיקיית השורש (כמו חיפוש בכל המחשב) אז החיפוש יהיה בכל התיקיות ותתי התיקיות במחשבך!</span>

                                    <b>. , ..</b>
                                    <span>אם תריץ את הפקודה ls -a תוכל לראות תמיד את הסימונים נקודה ושתי נקודות (., ..)</span>
                                    <span>. אומר כאן</span>
                                    <span>.. זה קישור לתיקיית האב שמכילה את התיקייה שבה אתה נמצא כעת</span>
                                    <span>אם לדוגמה אני נמצא בתיקייה b שנמצאת בתוך תיקייה a, וארצה לצאת מתיקייה b לתיקייה a, אוכל לבצע את הפקודה הבאה:</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~/a/b]" cursor="cd .." />
                                    <span>לאחר ביצוע הפקודה אני יהיה בתיקייה a</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~/a]" cursor="" />
                                    
                                    <b>חיפוש במיקום הנוכחי, או בתיקיית השורש</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find ." />
                                    <span>חיפוש בתיקיית השורש</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 21
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
