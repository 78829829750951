import React, { Component } from 'react';
import './metacharacters.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import RegExCompnent from '../RegExCompnent/RegExCompnent';
import { Consumer } from "./../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Metacharacters extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex
                        return (
                            <div className="paper ">
                                <h4>Metacharacters in Regex</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>metacharacter</b>
                                    <span>
                                        בביטויים רגולריים, מטא-תו הוא תו מיוחד בעל משמעות ספציפית באמצעותה ניתן לבנות תבניות מורכבות שיכולות להתאים למגוון רחב של שילובים.
                                    </span>
                                </p>
                                <div id="linux-components-metacharacters">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}>מטא-תווים</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  כל תו בודד </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  . </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  התאם את תחילתה של שורה </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ^  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >רווח כולל שורה חדשה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  $ </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > התאם בין a או b </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  a|b </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > כל ספרה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \d </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו שאינו ספרתי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \D </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תו כל מילה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \w </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו שאינו מילה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> \W </TableCell>
                                            </TableRow>

                                        </TableBody>

                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תואם לכל תו של רווח לבן</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \s </TableCell>
                                            </TableRow>

                                        </TableBody>

                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >התאם כל תו שאינו רווח לבן</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \S </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תואם גבול מילה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \b </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >אסור להתרחש התאמה על גבול \b.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \B </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תו Backspace</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [\b] </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >התאם כל תו הקסאדצימלי YY</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> \xYY </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תו אוקטלי ddd</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  \ddd </TableCell>
                                            </TableRow>

                                        </TableBody>

                                    </Table>
                                </div>

                                <p>
                                    <b>כל תו בודד - <span className='metacharacters'>.</span>התבנית /./g מחזירה את המחרוזת עצמה מכיוון שהיא מתאימה לכל תו, כפי שמוצג להלן.</b>
                                </p>

                                <RegExCompnent key={React.key} patternIndex="0" />

                                <p>
                                    <b> ה-^ ו-$ מכונים גם עוגנים<span className='metacharacters'>^</span>מחפש מתחילת מחרוזת.</b>
                                </p>

                                <RegExCompnent key={React.key} patternIndex="1" />

                                <p>
                                    <b>ה - <span className='metacharacters'>$</span>מתאים לקצה המחרוזת.</b>
                                </p>
                                <RegExCompnent patternIndex="2" />

                                <p>
                                    <b> ה - <span className='metacharacters'>|</span>מייצג או בביטוי רגולרי. אז, דפוס 'e|o' מחפש את התו 'e' או 'o' בטקסט.</b>
                                </p>
                                <RegExCompnent patternIndex="3" />
                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>


                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
