import React, { Component } from 'react';
import './foregroundaprocess.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class ForegroundAProcess extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper process-foreground">
                                <h4>Foreground a Process</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>העברת תהליך לקידמה</b>
                                    <span>
                                        אנו יכולים להשתמש בפקודה jobs כדי לרשום את כל תהליכי הרקע. תהליכים על רקע אינם דורשים אינטראקציה של המשתמש, ואנחנו יכולים להשתמש באותה הפעלת מעטפת מבלי להמתין עד לסיום התהליך הראשון. לאחר שהסריקה או התהליך יסיים את עבודתו, נקבל הודעה על ידי המסוף כי התהליך הסתיים.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="jobs" />

                                    <b>fg</b>
                                    <span>אם נרצה להכניס את תהליך הרקע לחזית ולפעול איתו שוב, נוכל להשתמש בפקודה fg.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="fg <PID>" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 10
                                        let completedIndex = 61
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
