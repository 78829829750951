import React, { Component } from 'react';
import './macspoofing.css';
import companyLogo from './../../../../../assets/Images/spoofed-mac.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class MacSpoofing extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper MacSpoofing" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>להישאר אנונימי באמצעות זיוף הכתובת הפיזית שלך</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        אם אתה רוצה לבצע מתקפת סייבר, אתה מתחיל לסרוק את היעד שלך, ואתה לומד עליו מספר דברים, אבל הוא גם לומד עלייך, בכל פעולת סריקה יש את המידע של כתובת ה IP של מי שביצע את הסריקה (&lt;From IP Address&gt;)
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        אם אתה רוצה להיות אנונימי כשאתה סורק אתר אינטרנט, או כשאתה פורץ, אתה יכול לעשות זאת עם זיוף הכתובת הפיזית שלך.
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9">
                                    <span className="c4">
                                        מתקפת זיוף של MAC היא כאשר האקר מחקה את הכתובת הפיזית  שלך כדי להפנות מחדש נתונים שנשלחים למכשיר שלך למכשיר אחר.
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9">
                                    <span className="c4">
                                        זה מאפשר לתוקף לקבל גישה לא מורשית לרשת כדי להפעיל מתקפה של איש-באמצע. זיוף MAC מאפשר לתוקף לעקוף את אמצעי האבטחה שהגדרת, כגון סינון MAC.
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9">
                                    <span className="c4">
                                        אז כאשר ינסו לעקוב אחרי הכתובת הפיזית של ההאקר, זה יוביל אותם למבוי סתום!
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className='c9 list-items'>
                                    <b style={{ fontSize: '17pt' }}>היתרונות של זיוף הכתובת הפיזית </b>
                                    <span style={{ fontSize: '17pt' }}>אינטנרט חופשי ( היכן שישנה הגבלה על כתובת פיזית )</span>
                                    <span style={{ fontSize: '17pt' }}>הגנה על הזהות שלך ברשת ( להישאר אנונימי )</span>
                                    <span style={{ fontSize: '17pt' }}>למנוע מעקב אחרייך</span>
                                </p>
                                <p>
                                    <b style={{ fontSize: '17pt' }}>זיוף הכתובת הפיזית - macchanger</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="ifconfig wlan0 down" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="macchanger -r wlan0" newline="/home/moshe" />
                                </p>
                                <p style={{direction:'ltr', textAlign:'left'}}>
                                    <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#880000' }}>Current MAC:</span> <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#008800' }}>a2:a6:e6:25:f0:05 (unknown)</span>
                                    <br />
                                    <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#880000' }}>Permanent MAC:</span> <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#008800' }}>00:c0:ca:75:ff:fb(ALFA, INC.)</span>
                                    <br />
                                    <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#880000' }}>New MAC:</span> <span style={{ display: 'inline-block', fontSize: '17pt', textAlign: 'left', direction: 'ltr', color: '#008800' }}>ca:ab:ec:61:44:cd(unknown)</span>
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="ifconfig wlan0 up" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b style={{ fontSize: '17pt' }}>ניתן גם להגדיר את הכתובת הפיזית החדשה באופן ידני:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="macchanger -m <new mac address> wlan0" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="ifconfig wlan0 up" newline="/home/moshe" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 8
                                    let subjectsAndSubTopicsCompletedIndex = 8
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
