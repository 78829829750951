import React, { Component } from 'react';
import './iname.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Iname extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column find-iname">
                                <h4>iname - Ignore Case name</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>-name</b>
                                    <span>
                                        עכשו אני צריך לציין את שם הקובץ או התיקייה שאני מחפש
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -name 'user.txt'" />
                                    <b>-iname</b>
                                    <span>
                                       מה הקטע הזה עם ה - i? 
                                    </span>
                                    <span>צריך להבין שישנו הבדל מהותי בין מערכת ההפעלה של לינוקס לחלונות, במערכת ההפעלה חלונות אם תנסה לייצר תיקייה בשם Dog פעמיים באותו המקום אפילו אם אותיות תחיליות שונות זה לא יאפשר זאת, משום שאי אפשר לייצר שתי תיקיות בעלות שם זהה באותו המיקום, חלונות אינה רגישה לכך אם אלו אותיות גדולות או קטנות, לעומתה לינוקס היא רגישה לכך, במערכת ההפעלה של לינוקס Dog ו - dog אלו שתי שמות שונים, לכן אם נשתמש בפרמטר name עם הערך dog הוא לא ימצא תיקייה בשם Dog, אם נרצה שמערכת ההפעלה של לינוקס תתעלם מאותיות קטנות או גדולות כמו במערכת ההפעלה של חלונות נשתמש בפרמטר iname שזה בעצם Igonore case </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -iname 'user.txt'" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 25
                                        let subjectsAndSubTopicsCompletedIndex = 4

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
