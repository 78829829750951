import React, { Component } from 'react';
import './tr.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Tr extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column tr">
                                <h4>סינון תוכן - tr</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>tr</b>
                                    <span>
                                        אפשרות נוספת להחליף תווים מסוימים משורה בתווים שהוגדרו על ידינו היא הכלי tr. כאפשרות הראשונה, אנו מגדירים איזו דמות אנו רוצים להחליף, וכאופציה שנייה, אנו מגדירים את הדמות בה אנו רוצים להחליף אותה. בדוגמה הבאה אנו מחליפים את דמות הנקודתיים במרחב.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin' | tr ':' '" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 47
                                        let subjectsAndSubTopicsCompletedIndex = 8

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
