import React, { Component } from 'react';
import './packagesintroduction.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class PackagesIntroduction extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4>ניהול חבילות - 1</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>תכונות של מנהלי החבילות</b>
                                    <span>
                                        בין אם עובדים כמנהל מערכת, מתחזקים מכונות לינוקס משלנו בבית, או בונים / משדרגים / שומרים על הפצת בדיקות החדירה שלנו לפי בחירה, יש צורך להבין היטב את מנהלי חבילות הלינוקס הזמינים ואת הדרכים השונות להשתמש בהם כדי להתקין, לעדכן או להסיר חבילות. חבילות הן ארכיונים המכילים קבצים בינאריים של תוכנה, קבצי תצורה, מידע על תלות ועוקבים אחר עדכונים ושדרוגים. התכונות שרוב מערכות ניהול החבילות מספקות הן:
                                    </span>
                                </p>
                                <p className='list-items'>


                                    <span>הורדת חבילה</span>
                                    <span>רזולוציית תלות</span>
                                    <span>פורמט חבילה בינארית סטנדרטית</span>
                                    <span>מיקומי התקנה ותצורה נפוצים</span>
                                    <span>תצורה ופונקציונליות נוספות הקשורות למערכת</span>
                                    <span>בקרת איכות</span>

                                </p>
                                <span>
                                    אנו יכולים להשתמש במערכות ניהול חבילות רבות ושונות המכסות סוגים שונים של קבצים כמו ".deb", ".rpm", ואחרים. דרישת ניהול החבילה היא ש - התוכנה להתקנה תהיה זמינה כ - חבילה מתאימה. בדרך כלל זה נוצר, מוצע ומתוחזק באופן מרכזי תחת הפצות לינוקס. באופן זה התוכנה משולבת ישירות במערכת וספריותיה השונות מופצות בכל המערכת. תוכנות ניהול החבילות שינויים במערכת להתקנת החבילה נלקחות מהחבילה ומיושמות על ידי תוכנת ניהול החבילה. אם תוכנת ניהול החבילה מזהה כי נדרשות חבילות נוספות לצורך תפקודה התקין של החבילה שטרם הותקנה, כלולה תלות והיא מזהירה את המנהל או מנסה לטעון מחדש את התוכנה החסרה ממאגר, למשל, ולהתקין זה מראש.
                                </span>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 9
                                        let completedIndex = 55
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
