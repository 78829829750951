import React, { Component } from 'react';
import './size.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Size extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper find-size">
                                <h4>שימוש בפקודה Find לחיפוש קבצים לפי גודל</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b></b>
                                    <span>
                                        ההבנה כיצד לאתר ביעילות קבצים לפי גודל במערכת לינוקס היא משימה קריטית הן למנהלי מערכת והן למשתמשים מזדמנים. אנו מתעמקים בשימוש המפורט בפקודת Linux find לחיפוש קבצים על סמך גודלם - מיומנות רבת עוצמה לניהול קבצים יעיל ואופטימיזציה של שטח הדיסק.
                                    </span>
                                    <b>מציאת קבצים בגודל מדויק: חפש קבצים בגודל 6 מגה בייט בדיוק בספרייה הנוכחית שלך</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size 6M" />
                                    <span>כאן, 'M' מסמל מגה-בייט, השווה ל-1,048,576 בתים. סיומות חלופיות כוללות 'b' (בלוקים של 512 בתים), 'c' (בתים), 'w' (מילים של שני בתים), 'k' (קילובייט), 'G' (ג'יגה-בייט).</span>

                                    <b>קבצים גדולים מסף: חפש קבצים שגודלם עולה על 2 גיגה-בייט.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size +2G" />
                                    <span>האופרטור '+' עוזר לזהות קבצים גדולים מהגודל שצוין.</span>

                                    <b>מציאת קבצים קטנים יותר: אתר את כל הקבצים מתחת ל-10 קילובייט.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size -10k" />
                                    <span>כאן, '-' מרמז שאנחנו מעוניינים בקבצים קטנים יותר מפרמטר הגודל הנתון.</span>

                                    <b>חיפוש טווח גדלים: גלה קבצים גדולים מ-10MB אך קטנים מ-20MB.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size +10M -size -20M" />
                                    <span>שלב פרמטרים של גודל כדי להגדיר חיפושי טווח ביעילות.</span>

                                    <b>תצוגת רשימה וגודל קובץ: חפש ב-/etc עבור קבצים מעל 5 מגה בייט ורשום אותם עם גדלים.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc -size +5M -exec ls -sh {} +" />
                                    <span>הדגל '-exec' מאפשר פקודות נוספות, כמו 'ls -sh', להצגת פרטי קובץ.</span>

                                    <b>שלושת הקבצים הגדולים ביותר: זהה את שלושת המסמכים הגדולים ביותר בספרייה שלך, מדורגים לפי גודל.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -exec ls -s {} + | sort -n -r | head -3" />
                                    <span>שלב את 'מצא' עם 'sort' ו'head' כדי לאתר את הקבצים הגדולים ביותר.</span>

                                    <b>מחפש את הקטן ביותר: שחרר את הקבצים הקטנים ביותר בספרייה שלך.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -type f -exec ls -s {} + | sort -n | head -3" />
                                    <span>גישה הפוכה זו מחפשת את נכסי הקבצים הקטנים ביותר.</span>

                                    <b>איפוס על קבצים ריקים: חפש קבצים ריקים לחלוטין.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -size 0b" />
                                    <span>הפרמטר '0b' מפורש עבור קבצים אפס בתים (ריקים). האפשרות '-ריק' מפשטת עוד יותר את המשימה הזו.</span>

                                    <b>מציאת קבצים בגודל מדויק: מקד לקבצים שהם בדיוק 1024 בתים.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size 1024c" />
                                    <span>הסיומת 'c' ממחישה חיפוש מדויק בתים, אופטימלי עבור נתונים או יומנים מסוימים.</span>

                                    <b>מחיקת קבצים זמניים גדולים: נקה קבצים זמניים שעולים על 100 מגה בייט.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /path/to/tmp -type f -name '*.tmp' -size +100M -delete" />
                                    <span>פתרון אידיאלי לניהול שטח דיסק על ידי טיהור קבצים זמניים משמעותיים.</span>

                                    <b>רישום קבצים משופר: הצג קבצים מעל 1 מגה בייט, עם גודל ותאריכי שינוי לבדיקה קלה.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size +1M -exec ls -lh {} \; | awk '{ print $5, $6, $7, $9 }'" />
                                    <span>התדפיס נוח יותר לעיכול ומתאים לבדיקה ידנית.</span>

                                    <b>שינויים בהרשאות קבצים קטנים: תקן הרשאות עבור קבצים מתחת ל-5 קילו בייט.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -size -5k -exec chmod 644 {} \;" />
                                    <span>שנה הרשאות בכמות גדולה, שיפור אבטחת הקבצים שלך.</span>


                                    <b>כימת קבצים גדולים: ספור את כל הקבצים בספרייה שלך מעל 500 מגה בייט.</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f -size +500M | wc -l" />
                                    <span>כלי שימושי לניטור השימוש בדיסק או הכנה לניקויים.</span>

                                    <b>מסקנה</b>
                                    <span>
                                        הפקודה find היא כלי עזר יוצא דופן לניהול קבצים לפי גודלם במערכות לינוקס. עם הדוגמאות המדויקות המפורטות לעיל, משתמשים יכולים לא רק לאתר קבצים אלא גם לבצע פעולות שונות כגון מחיקה, שינוי הרשאות או פשוט הצגת גדלי קבצים בטבלה.
                                    </span>


                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 29
                                        let subjectsAndSubTopicsCompletedIndex = 8

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
