import React, { Component } from 'react';
import './RegExIntermediateLevel.css';
import companyLogo from './../../../assets/Images/regex.png';
import { Consumer } from "../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Questionnaire from '../../Questionnaire/Questionnaire';
import Clock from '../../Clock/Clock';

export default class RegExIntermediateLevel extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper RegExIntermediateLevel" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>RegEx Intermediate Level</b>
                                </p>

                                <p>
                                    <b>Quizer</b>
                                    <span>
                                        <Questionnaire />
                                    </span>
                                </p>

                                <Button variant="contained" color="success" onClick={(e) => {
                                    c.actions.setTestQustions('Regular Expresion', 'intermediate')
                                }}>Start<Clock />  <FontAwesomeIcon icon={faPlay} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                                <Button variant="contained" color="error" onClick={(e) => {
                                    let timer = document.getElementById('timer').textContent
                                    c.actions.setTestDone(4, 'Regular Expresion', 'intermediate', timer) //id, Course, difficulty, took                                    
                                }}>Finish  <FontAwesomeIcon icon={faStop} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>


                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 27 // this is the index in subjectsAndSubTopics where this item is Test
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = 5
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
