import React, { Component } from "react";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google"
import { jwtDecode } from "jwt-decode";
import { Consumer } from "../Context";

export default class GoogleSignIn extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <Consumer>
                {
                    c => {
                        return <GoogleLogin
                            onSuccess={credentialResponse => {
                                const credentials = jwtDecode(credentialResponse.credential)
                                c.actions.setCredentials(credentials)

                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                            useOneTap
                        />

                    }
                }
            </Consumer >

        )
    }

}
