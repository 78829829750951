import React, { Component } from "react";
import './qustion.css';
import Button from '@mui/material/Button';

import { Consumer } from "../Context";
export default class Qustion extends Component {

    render() {

        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div id="qustion-container">
                                <p> {(c.qustions.length !== 0) ? c.qustions[c.qustionNumber].qustion : ''} </p>
                                
                                <Button variant="contained" onClick={() => {
                                    c.actions.previosQustion()
                                }}>שאלה קודמת </Button>
                                <Button variant="contained" onClick={() => {
                                    c.actions.nextQustion()
                                }}>שאלה הבאה </Button>


                            </div>
                        )
                    }
                }
            </Consumer>
        )



    }


}