import React, { Component } from 'react';
import './perm.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Perm extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper find-perm">
                                <h4>חיפוש לפי הרשאות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>perm Paramter-</b>
                                    <span>
                                        כפי שלמדנו בנושא ההרשאות, ישנו ייצוד מספרי עבור ההרשאות, אם אתם זוכרים:
                                    </span>
                                    <span className='ltr'> (<span className='bold green medium-text'>r</span>) - Read  :  4</span>
                                    <span className='ltr'> (<span className='bold green medium-text'>w</span>) - Write :  2</span>
                                    <span className='ltr'>(<span className='bold green medium-text'>x</span>) - Execute : 1</span>
                                    <span>ראינו שישנם 3 ישויות -  המשתמש, הקבוצה, ואחרים אם נכתוב זאת באנגלית : <span className='bold green medium-text'>User Group Others</span></span>
                                    <span>ראשי תיבות : <span className='bold green medium-text'>UGO</span> (זה כמעט נכתב כמו הדמות HUGO שמופיעה בטרמינל)</span>
                                    <span>אז שלושת התווים הראשונים הם ההרשאות של המשתמש</span>
                                    <span>ו - שלושת התווים האמצעיים הם ההרשאות של הקבוצה</span>
                                    <span>ו - שלושת התווים האחרונים הם ההרשאות של אחרים</span>
                                    <b>דוגמה</b>
                                    <span> ולכן אם נרצה לחפש לדוגמה , בכל המחשב תיקייה שיש למשתמש שמבצע את החיפוש הרשאות של קריאה וכתיבה, לקבוצה יש הרשאות קריאה, ולאחרים אין שום הרשאה, נעשה זאת כך:</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find / -type d -perm 640" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 31
                                        let subjectsAndSubTopicsCompletedIndex = 10

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
