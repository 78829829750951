import React, { Component } from 'react';
import './sort.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Sort extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column">
                                <h4>סינון תוכן - sort</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>sort</b>
                                    <span>
                                        תלוי אילו תוצאות וקבצים מטפלים, הם ממוינים לעיתים רחוקות. לעתים קרובות יש צורך למיין את התוצאות הרצויות לפי סדר אלפביתי או מספרי כדי לקבל סקירה טובה יותר. לשם כך אנו יכולים להשתמש בכלי שנקרא מיון.
                                    </span>

                                    <span>כפי שאנו רואים כעת, הפלט כבר לא מתחיל עם שורש אלא ממוין כעת לפי סדר אלפביתי.</span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="tail /etc/passwd | sort" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 43
                                        let subjectsAndSubTopicsCompletedIndex = 4

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
