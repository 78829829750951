import React, { Component } from "react";
import './coreprinciples.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { useTreeViewApiRef } from '@mui/x-tree-view/hooks/useTreeViewApiRef';

export default class CorePrinciples extends Component {



    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column core-principles" data-sub-index="0" >

                                <h4>עקרונות הליבה של מערכת ההפעלה של לינוקס</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>הכל קובץ</b>
                                    <span>
                                        "הכל קובץ" הוא מושג בסיסי במערכות הפעלה דמויות יוניקס, כולל לינוקס. המשמעות היא שבמערכת הקבצים של לינוקס, לא רק קבצים רגילים, ספריות וקישורים סמליים מיוצגים כקבצים אלא גם מכשירים, תהליכים ואפילו ערוצי תקשורת מסוימים מיוצגים כקבצים.
                                    </span>

                                    <b>תוכניות קטנות חד-תכליתיות</b>
                                    <span>
                                        העיקרון של "תוכניות קטנות חד-תכליתיות" הוא פילוסופיה עיצובית המדגישה יצירת תוכנות קטנות ומתמחות המבצעות משימה או פונקציה ספציפית היטב.
                                    </span>

                                    <b>יכולת שרשרת תוכניות לביצוע משימות מורכבות</b>
                                    <span>
                                        בהמשך לעיקרון של תוכניות חד-שימושיות, היכולת לשרשר תוכניות יחד לביצוע משימות מורכבות היא עיקרון מפתח בפילוסופיית יוניקס/לינוקס. המושג מתייחס לתרגול של שימוש בפלט של תוכנית אחת כקלט עבור תוכנית אחרת, ולכן חיבור שורה של תוכניות יחד כדי להשיג פונקציונליות חזקה ומתוחכמת יותר.
                                    </span>

                                    <b>הימנע ממשקי משתמש גרפיים</b>
                                    <span>
                                        עיקרון זה מונע מיצירת ממשקי משתמש מיותרים שיכולים להיות מגבילים מאוד. במקום זאת יש דגש חזק על השימוש בממשקי שורת הפקודה שבהם צפויים להקליד פקודות וכלים לביצוע. זה מספק למשתמש שליטה רבה יותר על המערכת.
                                    </span>

                                    <b>נתוני תצורה המאוחסנים בטקסט</b>
                                    <span>
                                        עיקרון זה מדגיש את השימוש בקבצי טקסט רגיל לאחסון הגדרות תצורה עבור תוכנה ורכיבי מערכת. לגישה זו מספר יתרונות והיא תואמת את העקרונות של פשטות, שקיפות ותאימות.
                                    </span>

                                    <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        // subjectsAndSubTopicsIndex = 0 in this case we are at this row:
                                        // { Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'introduction', subTopics: ['Core principles', 'Linux Architecture', 'The folder structure in Linux', 'Components'], allComponents: [CorePrinciples, Architecture, FolderStructure, Components], completed: ['', '', '', ''] },
                                        // this row contains 4 components, so the subjectsAndSubTopicsIndex = 0
                                        // will be in those 4 compnents
                                        // in brief this is the row index in subjectsAndSubTopics array of objects
                                        let subjectsAndSubTopicsIndex = 0
                                        let completedIndex = 0
                                        let subjectsAndSubTopicsCompletedIndex = 0
                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                                </p>
                            </div>
                        )
                    }
                }
            </Consumer >)
    }

}
