import React, { Component } from 'react';
import './tor.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Tor extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper Tor" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>🧅 Tor - The Onion Router</b>
                                </p>

                                <p className="c9" >
                                    <span className="c4">
                                        הוא יוצר על ידי חיל הים האמריקאי,
                                        Tor קיצור של The Onion Router, היא תוכנת קוד פתוח בחינם המאפשרת תקשורת אנונימית.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        הוא מכוון את תעבורת האינטרנט דרך רשת שכבת-על מתנדבים חינמית, חובקת עולם,
                                    </span>
                                    <span className='c1'>
                                        המורכבת מיותר משבעת אלפים ממסרים,
                                    </span>
                                    <span className='c4'>
                                        כדי להסתיר את מיקום המשתמש והשימוש בו מכל מי שמבצע מעקב רשת או ניתוח תעבורה 🌐
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4">
                                        השימוש ב-Tor מקשה על המעקב אחר פעילות האינטרנט של המשתמש. השימוש המיועד של Tor הוא להגן על הפרטיות האישית של המשתמשים שלה, כמו גם על החופש והיכולת שלהם לתקשר בסודיות באמצעות אנונימיות כתובות IP באמצעות צמתי יציאה של Tor.
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4">
                                        הורדה: <a href="https://www.torproject.org/download/" target="_blank">Tor Browser Download</a>
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4 c13">
                                        ויקיפדיה מכירים? בואו נכיר את אחיו hidden wiki, מה מוצאים?
                                    </span>
                                    <p className="c5"><span className="c0"></span></p>
                                    <div className='the-hidden-wiki'>
                                        <a href="https://thehiddenwiki.org/" target="_blank" className='wiki-link'>The hidden wiki link</a>
                                    </div>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 16
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
