import React, { Component } from 'react';
import './less.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Less extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column">
                                <h4>סינון תוכן - less</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>less</b>
                                    <span>
                                        אם כעת נסתכל על הכלי less, נבחין בדף ה man שהוא מכיל הרבה יותר תכונות יותר.
                                    </span>


                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="less /etc/passwd" />
                                <p>
                                    <span>המצגת כמעט זהה לזו של more.כאשר סוגרים פחות באמצעות מקש [Q], נבחין כי הפלט שראינו, בניגוד ליותר, אינו נשאר במסוף.</span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 7
                                    let completedIndex = 40
                                    let subjectsAndSubTopicsCompletedIndex = 1

                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
