import React, { Component } from 'react';
import './usersintroduction.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from './../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class UsersIntroduction extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper users">
                                <h4>משתמשים בלינוקס</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>סוגי משתמשים</b>
                                    <span>
                                        לינוקס תומכת בשני סוגים של משתמשים: משתמשי מערכת ומשתמשים רגילים.
                                    </span>
                                    <span><span className='bold red medium-text'>משתמשי מערכת</span> נוצרים על ידי המערכת במהלך ההתקנה ומשמשים להפעלת שירותי מערכת ויישומים</span>
                                    <span>
                                        <span className='bold blue medium-text'> משתמשים רגילים</span> נוצרים על ידי מנהל המערכת ויכולים לגשת למערכת ולמשאביה בהתבסס על ההרשאות שלהם.
                                    </span>

                                    <b>מאפייני חשבון משתמש</b>
                                    <span>
                                        לחשבונות משתמש יש מאפיינים שונים המגדירים את המאפיינים והרשאות הגישה שלהם. הבה נחקור את המאפיינים הללו בהקשר של מקרה השימוש שלנו.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd" />

                                    <p className='list-items'>
                                        <b></b>
                                        <span><span className='bold green medium-text'>שם משתמש:</span> לכל משתמש מוקצה שם משתמש ייחודי המשמש כמזהה שלו במערכת לינוקס. לדוגמה, שם המשתמש של ג'ון הוא "ג'ון".</span>
                                        <span><span className='bold green medium-text'>UID (זיהוי משתמש) ו-GID (מזהה קבוצה):</span> כל חשבון משתמש משויך ל-UID ו-GID. ה-UID הוא ערך מספרי המוקצה למשתמש, בעוד ה-GID מייצג את הקבוצה הראשית שאליה משתייך המשתמש. לדוגמה, ה-UID של ג'ון עשוי להיות 1002, וה-GID של הקבוצה הראשית שלו הוא גם 1002.</span>
                                        <span><span className='bold green medium-text'>ספריית בית:</span> לכל משתמש יש ספריית בית ייעודית שבה נמצאים הקבצים וההגדרות האישיים שלו. ספריית הבית של ג'ון היא /home/john.</span>
                                        <span><span className='bold green medium-text'>מעטפת ברירת מחדל:</span> מעטפת ברירת המחדל קובעת את מתורגמן הפקודות בשימוש כאשר משתמש מתחבר. היא מגדירה את הסביבה האינטראקטיבית של המשתמש. במקרה שלנו, מעטפת ברירת המחדל של ג'ון מוגדרת ל-/bin/bash, שהיא מעטפת פופולרית בלינוקס.</span>
                                        <span><span className='bold green medium-text'>סיסמה:</span> חשבונות משתמש דורשים סיסמאות כדי לאמת ולגשת למערכת. משתמשים, כולל ג'ון, חייבים ליצור סיסמאות חזקות כדי להבטיח אבטחה.</span>
                                        <span><span className='bold green medium-text'>קבוצה:</span> החברות בקבוצה קובעת לאילו משאבי מערכת המשתמש יכול לגשת, כמו גם לאילו משתמשים יכולים לגשת לקבצי המשתמש.</span>
                                    </p>

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 8
                                        let completedIndex = 51
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
