import React, { Component } from "react";
import './topmenu.css';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa0, faAdjust, faAnglesRight, faDashboard, faMenorah } from '@fortawesome/free-solid-svg-icons'


import { Consumer } from "../Context";

export default class TopMenu extends Component {


    render() {
        return (
            <Consumer>
                {
                    c => {

                        return (
                            <div id="topmenu-container">
                                <h1>{c.subTopic}</h1>
                                <Link key={"dashboard-Link"} to="/" ><li key={"dashboard-Link-listitem"} className="mm-active" onClick={(e) => {
                                    c.actions.resetComponentName()
                                }}>
                                    <span>Dashboard</span><FontAwesomeIcon icon={faAdjust} />
                                </li></Link>
                            </div>
                        )

                    }
                }
            </Consumer>
        )


    }
}