import React, { Component } from 'react';
import './ARPPresentation.css';
import companyLogo from './../../../../../../assets/Images/anonymous-messenger.png';

import slide1arp from './../../../../../../assets/Images/slide1-arp.png'
import slide2arp from './../../../../../../assets/Images/slide2-arp.png'
import slide3arp from './../../../../../../assets/Images/slide3-arp.png'
import slide4arp from './../../../../../../assets/Images/slide4-arp.png'
import slide5arp from './../../../../../../assets/Images/slide5-arp.png'
import slide6arp from './../../../../../../assets/Images/slide6-arp.png'
import slide7arp from './../../../../../../assets/Images/slide7-arp.png'
import slide8arp from './../../../../../../assets/Images/slide8-arp.png'
import slide9arp from './../../../../../../assets/Images/slide9-arp.png'
import slide10arp from './../../../../../../assets/Images/slide10-arp.png'
import slide11arp from './../../../../../../assets/Images/slide11-arp.png'
import slide12arp from './../../../../../../assets/Images/slide12-arp.png'
import slide13arp from './../../../../../../assets/Images/slide13-arp.png'
import slide14arp from './../../../../../../assets/Images/slide14-arp.png'
import slide15arp from './../../../../../../assets/Images/slide15-arp.png'
import slide16arp from './../../../../../../assets/Images/slide16-arp.png'
import slide17arp from './../../../../../../assets/Images/slide17-arp.png'
import slide18arp from './../../../../../../assets/Images/slide18-arp.png'
import slide19arp from './../../../../../../assets/Images/slide19-arp.png'
import slide20arp from './../../../../../../assets/Images/slide20-arp.png'
import slide21arp from './../../../../../../assets/Images/slide21-arp.png'
import slide22arp from './../../../../../../assets/Images/slide22-arp.png'
import slide23arp from './../../../../../../assets/Images/slide23-arp.png'
import slide24arp from './../../../../../../assets/Images/slide24-arp.png'
import slide25arp from './../../../../../../assets/Images/slide25-arp.png'
import slide26arp from './../../../../../../assets/Images/slide26-arp.png'

import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class ARPPresentation extends Component {

    constructor(props) {

        super(props)
        this.state = {
            slideIndex: 1,
        }
    }

    componentDidMount() {

        this.showSlides(this.state.slideIndex);
    }



    plusSlides = (n) => {
        this.showSlides(this.state.slideIndex += n);
    }

    currentSlide = (n) => {
        this.showSlides(this.state.slideIndex = n);
    }

    showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) { this.state.slideIndex = 1 }
        if (n < 1) { this.state.slideIndex = slides.length }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }

        slides[this.state.slideIndex - 1].style.display = "block";
        dots[this.state.slideIndex - 1].className += " active";
    }




    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper SurfingAnonymouslyIntro" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} />ARP- Address Resolution Protocol</div>

                                <p>
                                    <b style={{ fontFamily: 'Alfa Slab One', fontSize: "26pt", color: "#ff5722" }}>Surfing Anonymously</b>
                                    <span className='c4'>
                                        לחץ על כפתורי הניווט על מנת לצפות במצגת
                                    </span>
                                </p>

                                <div className="slideshow-container">

                                    <div className="mySlides fade">
                                        <div className="numbertext">1 / 26</div>
                                        <img src={slide1arp} style={{ width: '100%' }} />
                                        <div className="text">ARP</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">2 / 26</div>
                                        <img src={slide2arp} style={{ width: '100%' }} />
                                        <div className="text">תקשורת ברשת הביתית</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">3 / 26</div>
                                        <img src={slide3arp} style={{ width: '100%' }} />
                                        <div className="text">תקשורת ברשת הביתית</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">4 / 26</div>
                                        <img src={slide4arp} style={{ width: '100%' }} />
                                        <div className="text">תקשורת ברשת הביתית</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">5 / 26</div>
                                        <img src={slide5arp} style={{ width: '100%' }} />
                                        <div className="text">ARP טבלת המטמון של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">6 / 26</div>
                                        <img src={slide6arp} style={{ width: '100%' }} />
                                        <div className="text">ARP טבלת המטמון של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">7 / 26</div>
                                        <img src={slide7arp} style={{ width: '100%' }} />
                                        <div className="text">ARP טבלת המטמון של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">8 / 26</div>
                                        <img src={slide8arp} style={{ width: '100%' }} />
                                        <div className="text"> ARP כיצד עובד הפרוטוקול של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">9 / 26</div>
                                        <img src={slide9arp} style={{ width: '100%' }} />
                                        <div className="text">ARP כיצד עובד הפרוטוקול של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">10 / 26</div>
                                        <img src={slide10arp} style={{ width: '100%' }} />
                                        <div className="text">ARP כיצד עובד הפרוטוקול של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">11 / 26</div>
                                        <img src={slide11arp} style={{ width: '100%' }} />
                                        <div className="text">ARP כיצד עובד הפרוטוקול של</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">12 / 26</div>
                                        <img src={slide12arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">13 / 26</div>
                                        <img src={slide13arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">14 / 26</div>
                                        <img src={slide14arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">15 / 26</div>
                                        <img src={slide15arp} style={{ width: '100%' }} />
                                        <div className="text">Port flooding</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">16 / 26</div>
                                        <img src={slide16arp} style={{ width: '100%' }} />
                                        <div className="text">Port flooding</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">17 / 26</div>
                                        <img src={slide17arp} style={{ width: '100%' }} />
                                        <div className="text">מתעדכנת B טבלת המטמון של מחשב </div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">18 / 26</div>
                                        <img src={slide18arp} style={{ width: '100%' }} />
                                        <div className="text">מתעדכנת B טבלת המטמון של מחשב</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">19 / 26</div>
                                        <img src={slide19arp} style={{ width: '100%' }} />
                                        <div className="text">ARP Response</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">20 / 26</div>
                                        <img src={slide20arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">21 / 26</div>
                                        <img src={slide21arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">22 / 26</div>
                                        <img src={slide22arp} style={{ width: '100%' }} />
                                        <div className="text">MAC Address Table Update</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">23 / 26</div>
                                        <img src={slide23arp} style={{ width: '100%' }} />
                                        <div className="text">Forward the ARP Response</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">24 / 26</div>
                                        <img src={slide24arp} style={{ width: '100%' }} />
                                        <div className="text">Forward the ARP Response</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">25 / 26</div>
                                        <img src={slide25arp} style={{ width: '100%' }} />
                                        <div className="text">מתעדכנת A טבלת המטמון של מחשב</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">26 / 26</div>
                                        <img src={slide26arp} style={{ width: '100%' }} />
                                        <div className="text">Summary</div>
                                    </div>

                                    <a className="prev" onClick={() => { this.plusSlides(-1) }}>❮</a>
                                    <a className="next" onClick={() => { this.plusSlides(1) }}>❯</a>

                                </div>


                                <div style={{ textAlign: 'center' }}>
                                    <span className="dot" onClick={() => { this.currentSlide(1) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(2) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(3) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(4) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(5) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(6) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(7) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(8) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(9) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(10) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(11) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(12) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(13) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(14) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(15) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(16) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(17) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(18) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(19) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(20) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(21) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(22) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(23) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(24) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(25) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(26) }}></span>
                                </div>





                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 24
                                    let completedIndex = 1
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
