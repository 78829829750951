import React, { Component } from 'react';
import './proxychainsinstallation.css';
import companyLogo from './../../../../../assets/Images/proxy-chains.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class ProxychainsInstallation extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ProxychainsInstallation" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>התקנת Proxychains</b>
                                </p>
                                <p>
                                    <b>נתקין תלותיות</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo apt install tor proxychains4" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>נחליף להיות משתמש מנהל מערכת</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo -i" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>לאחר שאנחנו מנהלי מערכת, ראשית עלינו לאפשר שירות Tor עם:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="systemctl start tor" newline="/home/moshe" />
                                </p>

                                <p className='tor-status-img'>
                                    <b>נבדוק את הסטטוס של תהליך Tor:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="systemctl status tor" newline="/home/moshe" />
                                </p>

                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = 5
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
