import React, { Component } from 'react';
import './fileeditors.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class FileEditors extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper fileeditors">
                                <h4>עורכי קבצים</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>Nano</b>
                                    <span>
                                        ישנן מספר דרכים לערוך קובץ. אחד מעורכי הטקסט הנפוצים ביותר לכך הוא Vi ו- Vim. לעתים רחוקות יותר, יש את עורך הננו. ראשית נעסוק כאן בעורך הננו, מכיוון שזה מעט קל יותר להבנה. אנו יכולים ליצור קובץ חדש ישירות עם עורך ה- Nano על ידי ציון שם הקובץ ישירות כפרמטר הראשון. במקרה זה, אנו יוצרים קובץ חדש בשם notes.txt.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nano users.txt" />
                                    <span>
                                        כעת עלינו לראות מה שנקרא "pager" פתוח, ונוכל להזין או להוסיף באופן חופשי כל טקסט. ה - Shell שלנו אמור להיראות בערך ככה.
                                    </span>
                                    <div className='para-after-image-editors-nano'></div>
                                    <b>הסבר על קיצורי הדרך</b>
                                    <span>
                                        להלן נראה שתי שורות עם תיאורים קצרים. הקרטה (^) מייצגת את המפתח "[CTRL]" שלנו. לדוגמא, אם נלחץ על [CTRL + W], שורה "חיפוש:" מופיעה בתחתית העורך, שם נוכל להזין את המילה או את המילים שאנחנו מחפשים. אם כעת נחפש את המילה "אנו" ונלחץ על [ENTER], הסמן יעבור למילה הראשונה שתואמת.
                                        כדי לקפוץ להתאמה הבאה עם הסמן, אנו לוחצים שוב על [CTRL + W] ומאשרים עם [ENTER] ללא כל מידע נוסף.
                                        כעת אנו יכולים לשמור את הקובץ על ידי לחיצה על [CTRL + O] ולאשר את שם הקובץ באמצעות [ENTER].לאחר ששמרנו את הקובץ, נוכל להשאיר את העורך עם [CTRL + X].ישנם קבצים רבים במערכות לינוקס שיכולים למלא תפקיד חיוני עבורנו כבודקי חדירה שזכויותיהם לא נקבעו כהלכה על ידי המנהלים. קבצים כאלה עשויים לכלול את הקובץ "etc /passwd/".
                                    </span>
                                </p>

                                <p>
                                    <b>Vim</b>
                                    <span>
                                        Vim מציע שישה מצבים בסיסיים שמקלים על העבודה שלנו והופכים את העורך הזה לעוצמתי
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="vim" />

                                    <div className='para-after-image-editors-vim'></div>
                                    <div id="linux-components">
                                        <Table className="styled-table" style={{ width: '72%' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                    <TableCell align="center" style={{ width: '31%' }}> מצב</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} > במצב רגיל, כל הקלטים נחשבים כפקודות עורך. כך שאין הכנסת התווים שהוזנו למאגר העורך, כפי שקורה ברוב העורכים האחרים. לאחר הפעלת העורך, אנו בדרך כלל במצב רגיל.</TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  Normal</TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} >  למעט כמה יוצאים מן הכלל, כל התווים שהוזנו מוכנסים למאגר.</TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  Insert </TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} >המצב החזותי משמש לסימון חלק ורציף של הטקסט, שיודגש ויזואלית. על ידי מיקום הסמן, אנו משנים את האזור הנבחר. לאחר מכן ניתן לערוך את האזור המודגש בדרכים שונות, כגון מחיקה, העתקה או החלפה.                                                    </TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  Visual</TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} > זה מאפשר לנו להזין פקודות בשורה אחת בתחתית העורך. זה יכול לשמש למיון, החלפה של קטעי טקסט או למחיקה, למשל.</TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  Command</TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} > במצב החלפה, הטקסט החדש שהוזן יחליף את תווי הטקסט הקיימים אלא אם כן אין עוד תווים ישנים במיקום הסמן הנוכחי. לאחר מכן יתווסף הטקסט החדש שהוזן.                                                    </TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  Replace</TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} > Pip הוא מתקין חבילות Python המומלץ להתקנת חבילות Python שאינן זמינות בארכיון דביאן. זה יכול לעבוד עם מאגרי בקרת גרסאות (כרגע רק מאגרי Git, Mercurial ו- Bazaar), יומני פלט בהרחבה ומונעים התקנות חלקיות על ידי הורדת כל הדרישות לפני תחילת ההתקנה.</TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  pip</TableCell>
                                                </TableRow>

                                            </TableBody>
                                            <TableBody>

                                                <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                    <TableCell align="center" style={{ color: '#7dacb5' }} >Git היא מערכת בקרת גרסאות מהירה ומרחבת ומבוזרת עם מערכת פקודות עשירה באופן יוצא דופן המספקת גם פעולות ברמה גבוהה וגם גישה מלאה לפנימיות.</TableCell>
                                                    <TableCell align="center" style={{ color: '#93E304' }}>  git</TableCell>
                                                </TableRow>

                                            </TableBody>


                                        </Table>
                                    </div>

                                    <b>הקדמה</b>
                                    <span>
                                        כשיש לנו את עורך Vim פתוח, נוכל לעבור למצב פקודה על ידי הקלדת ":" ואז הקלדת "q" כדי לסגור את Vim. כלי vimtutor נועד ללימוד והכרת העורך, להפעלתו הקלד פשוט מילה זו בטרמינל. זה אולי נראה מאוד קשה ומסובך בהתחלה, אבל זה ירגיש ככה לזמן קצר בלבד. היעילות שאנו מרוויחים מ- Vim לאחר שהתרגלנו אליה היא עצומה.
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 13
                                        let completedIndex = 67
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
