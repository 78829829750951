import React, { Component } from 'react';
import './structural.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from './../ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Structural extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4>היכרות עם המעטפת (Shell) תיאור מהיר</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b></b>
                                    <span>
                                        קל להבין את מבנה ה- bash הכוללת כברירת מחדל מידע כגון <span className='bold'>המשתמש</span><span className='bold'>, שם המארח</span><span className='bold'> וספריית העבודה הנוכחית </span>. הפורמט יכול להראות בערך כך:
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="<current working directory>" cursor="" />
                                <p>
                                    <b>User</b>
                                    <span>
                                        ספריית הבית של משתמש מסומנת בטילדה <span className='bold blue medium-text'>~</span>  והיא תיקיית ברירת המחדל כשאנחנו נכנסים.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="" />

                                <p>
                                    <b>Root User</b>
                                    <span>
                                        <span className='red-color'>סימן הדולר</span>, במקרה זה, מייצג משתמש. ברגע שאנחנו מתחברים כשורש, התו מתחלף ל- hash <span className='red-color bold'>#</span> ונראה כך:
                                    </span>

                                </p>
                                <ShellTerminal user="root@webschool26" location="#" cursor="" />
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 1
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = 1

                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>

                        )
                    }
                }
            </Consumer>)
    }

}
