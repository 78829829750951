import React, { Component } from 'react';
import './kill.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class Kill extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper kill-process">
                                <h4>Kill a Process</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>תהליך יכול להיות במצבים הבאים:</b>
                                    <p className='list-items'>

                                        <span>תהליך רץ</span>
                                        <span>ממתין (ממתין לאירוע או משאב מערכת)</span>
                                        <span>תהליך עצר</span>
                                        <span>זומבי (נעצר אבל עדיין יש לו ערך בטבלת התהליך).</span>
                                    </p>
                                    <span>
                                        ניתן לשלוט על תהליכים באמצעות kill, pkill, pgrep ו- killall. כדי לקיים אינטראקציה עם תהליך, עלינו לשלוח אליו אות. אנו יכולים להציג את כל האותות באמצעות הפקודה הבאה:
                                    </span>
                                    <div id="kill-l-image"></div>

                                </p>
                                <p>
                                    <b>הסבר קצר</b>
                                    <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > זה נשלח לתהליך כאשר הטרמינל ששולט בו סגור.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGHUP</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  נשלח כאשר משתמש לוחץ על [Ctrl] + C במסוף השליטה כדי להפריע לתהליך</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGINT </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >נשלח כאשר משתמש לוחץ על [Ctrl] + D כדי לצאת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGQUIT</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > הרוג מיד תהליך ללא פעולות ניקוי.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGKILL</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סיום התוכנית.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGTERM</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > עצור את התוכנית. אי אפשר לטפל בזה יותר.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGSTOP</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >נשלח כאשר משתמש לוחץ על [Ctrl] + Z כדי לבקש השעיה של שירות. המשתמש יכול להתמודד עם זה לאחר מכן.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  SIGTSTP</TableCell>
                                            </TableRow>

                                        </TableBody>


                                    </Table>
                                    </div>
                                    <b>
                                       "להרוג" תהליך עם הפקודה kill
                                    </b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="kill 9 <PID>" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 10
                                        let completedIndex = 63
                                        let subjectsAndSubTopicsCompletedIndex = 4

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
