import React, { Component } from 'react';
import './googlealerts.css';
import companyLogo from './../../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class GoogleAlerts extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper GoogleAlerts" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Google Alerts</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        Google Alerts הוא שירות זיהוי והתראה על שינוי תוכן, המוצע על ידי Google. השירות שולח מיילים למשתמש כאשר הוא מוצא תוצאות חדשות - כגון דפי אינטרנט, מאמרים בעיתונים, בלוגים או מחקר מדעי - התואמות את מונחי החיפוש של המשתמש.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page">
                                    <span className="c4">
                                        נוכל ליצור התראות כאשר היעד נמצא ברשת באמצעות התראות של גוגל!
                                    </span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 20
                                    let completedIndex = 2
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
