import React, { Component } from 'react';
import './redirect.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Redirect extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper redirects">
                                <h4>הפניות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>הפנה מחדש את STDOUT לקובץ</b>
                                    <span>
                                        כעת אנו יכולים לראות כי כל השגיאות (STDERR) שהוצגו בעבר עם "הרשאה נדחתה" אינן מוצגות עוד. התוצאה היחידה שאנו רואים כעת היא הפלט הסטנדרטי (STDOUT), אותו אנו יכולים להפנות גם לקובץ עם השם results.txt שיכיל רק פלט רגיל ללא השגיאות הסטנדרטיות.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -name shadow 2>/dev/null > results.txt" />

                                </p>
                                <p>
                                    <b>הפנה מחדש את STDOUT ו-STDERR לקבצים נפרדים</b>
                                    <span>
                                    היינו צריכים לשים לב שלא השתמשנו במספר לפני הסימן הגדול מ- (>) בדוגמה האחרונה. זאת משום שהפננו את כל השגיאות הסטנדרטיות ל"התקן null "לפני כן, והפלט היחיד שאנו מקבלים הוא הפלט הסטנדרטי (FD 1 - STDOUT). כדי לדייק זאת, ננתב את השגיאה הסטנדרטית
                                        (FD 2 - STDERR) ואת הפלט הסטנדרטי (FD 1 - STDOUT) לקבצים שונים.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -name shadow 2> stderr.txt 1> stdout.txt" />
                                    <span>
                                        <b>מה מכיל stdout.txt</b>
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat stdout.txt" />
                                    <span className='terminal-output'>/etc/shadow</span>
                                    <span>
                                        <b>מה מכיל stderr.txt</b>
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat stderr.txt" />
                                    <span className='terminal-output'>
                                        <span>find '/etc/private: Permission denied</span>
                                        <span>find '/etc/ssl: Permission denied</span>
                                        <span>find '/etc/ssh: Permission denied</span>
                                    </span>
                                </p>
                                <p>
                                    <b>הפנה מחדש STDIN</b>
                                    <span>
                                    כפי שכבר ראינו, בשילוב עם מתארי הקבצים, אנו יכולים להפנות שגיאות ופלט עם תו גדול יותר (>). זה עובד גם עם הסימן הנמוך יותר (&lt;). עם זאת, הסימן הנמוך מ- משמש כקלט רגיל (FD 0 - STDIN). ניתן לראות בתווים אלה "כיוון" בצורה של חץ שאומר לנו "מאיפה" ולאן "יש להפנות את הנתונים. אנו משתמשים בפקודה cat כדי להשתמש בתוכן הקובץ "stdout.txt"
                                    </span>
                                    <span> כ- STDIN.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat < stdout.txt" />
                                </p>

                                <p>
                                    <b>הפנה מחדש את STDOUT והוסף לקובץ</b>
                                    <span>
                                        כאשר אנו משתמשים בסימן גדול מ- (&gt;) כדי להפנות מחדש את ה- STDOUT שלנו, קובץ חדש נוצר אוטומטית אם הוא עדיין לא קיים. אם קובץ זה קיים, הוא יוחלף מבלי לבקש אישור. אם אנו רוצים להוסיף את STDOUT לקובץ הקיים, נוכל להשתמש בסימן הכפול הגדול מ- (&gt;&gt;).
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -name passwd >> stdout.txt 2./dev/null" />
                                </p>
                                <p>
                                    <b>הפנה מחדש את זרם STDIN לקובץ</b>
                                    <span>
                                        אנו יכולים גם להשתמש בתווים הנמוכים כפולים נמוכים יותר (&lt;&lt;) כדי להוסיף את הקלט הסטנדרטי שלנו דרך זרם. אנו יכולים להשתמש בפונקציה מה שמכונה (End-Of-File EOF) של קובץ מערכת לינוקס, המגדיר את סוף הקלט. בדוגמה הבאה נשתמש בפקודה cat כדי לקרוא את קלט הזרמתנו דרך הזרם ולהפנות אותו לקובץ בשם "stream.txt".
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat << EOF > stream.txt" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 6
                                        let completedIndex = 37
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>


                        )
                    }
                }
            </Consumer>)
    }

}
