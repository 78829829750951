import React, { Component } from 'react';
import './scanningparamerters.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import ShellTerminal from '../../../../Linux/Shell/ShellTerminal/ShellTerminal';
export default class ScanningParamerters extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ScanningParamerters" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>אפשרויות של פרמטר הסריקה IP/Domain</b>
                                </p>

                                <p>
                                    <b>סריקת כתובות IP יחידה לפי כתובת</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo nmap 192.168.1.260 " newline="/home/moshe" />
                                </p>

                                <p>
                                    <b>סריקת כתובות IP יחידה לפי שם דומיין</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo nmap scanme.nmap.org " newline="/home/moshe" />
                                </p>

                                <p>
                                    <b>סריקת כתובות IP מרובות</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="nmap 10.12.2.18 10.12.2.19 10.12.2.20 " newline="/home/moshe" />
                                </p>

                                <p>
                                    <b>סריקת כתובות IP מרובות - טווח</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo nmap 10.12.2.18-20 " newline="/home/moshe" />
                                </p>
                                <p className="c9">
                                    <b>סריקת כתובות IP מרובות - מתוך קובץ</b>
                                    <span className="c4">
                                        במהלך מבחן חדירה פנימי, לא נדיר שמספקים לנו רשימת IP עם המארחים שאנחנו צריכים לבדוק. Nmap נותן לנו גם אפשרות לעבוד עם רשימות ולקרוא את המארחים מרשימה זו במקום להגדיר אותם באופן ידני או להקליד אותם.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p>
                                    <b>רשימה כזו יכולה להראות בערך כך:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat hosts.lst " newline="/home/moshe" />
                                </p>
                                <p className="c9" style={{width:'80%', textAlign:'left'}}>
                                    <span className="c4">192.168.1.238</span>
                                    <span className="c4">192.168.1.60</span>
                                </p>

                                <p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo nmap -iL hosts.lst " newline="/home/moshe" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 15
                                    let subjectsAndSubTopicsCompletedIndex = 5
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
