import React, { Component } from 'react';
import './column.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Column extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column column">
                                <h4>סינון תוכן - column</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>column</b>
                                    <span>
                                        מכיוון שלתוצאות כאלה לרוב יכול להיות ייצוג לא ברור, עמודת הכלים מתאימה היטב להציג תוצאות כאלה בצורה טבלאית באמצעות "-t".
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin' | tr ':' ' | column -t" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 48
                                        let subjectsAndSubTopicsCompletedIndex = 9

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
