import React, { Component } from 'react';
import './files.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Files extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex
                        return (
                            <div className="paper medium-display-column files">
                                <h4>עבודה עם קבצים </h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>צור, העבר, העתק או שנה שם</b>
                                    <span>
                                        בסעיף זה נלמד כיצד לייצר קבצים , להעביר אותם למיקום אחר ( בדומה לגזור והדבק ) או להעתיק אותם למקום נוסף (בדומה להעתק הדבק) או לשנות את שמם
                                    </span>
                                    <b> יצירת קובץ</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="touch <name>" />

                                    <b> העברת קובץ למיקום אחר (כמו גזור הדבק)</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mv <file name> <new location>" />

                                    <b> העתקת קובץ למיקום נוסף (כמו העתק הדבק)</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cp <file name> <new location>" />

                                    <b> שנה שם קובץ</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mv <file name> <new file name>" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 3
                                    let completedIndex = 14
                                    
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, subjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
