import React, { Component } from "react";
import './verticalmenu.css';

import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "../../Context";

export default class VerticalMenu extends Component {

    render() {
        return (
            <Consumer>
                {
                    c => {

                        let selectdItem = c.subject // className={(selectdItem == item.item) ? 'selected' : ''}

                        return (

                            <div id="sidebar-menu" className="vertical-menu-container">
                                <ul className="metismenu" id="side-menu">

                                    <li className="">
                                        <a className="" >
                                            <span>Modules</span>
                                        </a>
                                        <nav>
                                            <ul className="sub-menu mm-collapse">
                                                {c.courseVerticalMenu.map((item, index) =>

                                                    <Link key={"Link" + item + index} to="/" >
                                                        <li key={item + index} onClick={(e) => {

                                                            c.actions.setSelectedMenuItemsCubes(e.target.innerText, index)
                                                            c.actions.setSubTopicNew(e.target.innerText)
                                                            // clear all the selected menu items
                                                            let lis = document.querySelectorAll('ul.sub-menu.mm-collapse>a>li')
                                                            lis.forEach(element => {
                                                                if (element.classList.contains("mm-active"))
                                                                    element.classList.remove("mm-active")
                                                            });


                                                            e.target.classList.add("mm-active")
                                                            c.actions.setMaxTestTotalPoints()
                                                        }}>
                                                            <FontAwesomeIcon icon={item.icon} /> {item.item}
                                                        </li>
                                                    </Link>
                                                )}

                                            </ul>
                                        </nav>

                                    </li>

                                </ul>
                            </div>

                        )

                    }
                }
            </Consumer >
        )
    }
}