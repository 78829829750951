import React, { Component } from 'react';
import './shellintroduction.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from './../ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class ShellIntroduction extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div id="shell-introduction" className="paper medium-display-column">
                                <h4>היכרות עם המעטפת (Shell)</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>טרמינל</b>
                                    <span>
                                        מסוף לינוקס, הנקרא גם מעטפת או שורת פקודה, מספק ממשק קלט / פלט (I / O) מבוסס טקסט בין המשתמשים והליבה עבור מערכת מחשב. המונח קונסולה הוא גם אופייני אך אינו מתייחס לחלון אלא ל - מסך במצב טקסט. בחלון המסוף ניתן לבצע פקודות לשליטה במערכת.
                                    </span>
                                </p>

                                <ShellTerminal user=":webschool26@ubuntu" location="~" cursor="" />
                                <p>
                                    <b>סוגי מעטפות</b>
                                    <span>
                                        המעטפת הנפוצה ביותר ב - לינוקס היא <span className='bold'>Bourne-Again Shell BASH</span> והיא חלק מפרויקט GNU. כל מה שאנחנו עושים דרך ה- GUI אנחנו יכולים לעשות עם ה - Shell. המעטפת נותנת לנו הרבה יותר אפשרויות לתקשר עם תוכניות ותהליכים כדי לקבל מידע מהר יותר. חוץ מזה, תהליכים רבים ניתנים לאוטומציה בקלות באמצעות סקריפטים קטנים יותר או גדולים יותר, אשר מקלים על העבודה הידנית.

                                        מלבד בש, קיימים גם Shells אחרים כמו Tshsh / Csh, Ksh, Zsh, Shell Fish ועוד.
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 1
                                    let completedIndex = 4
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
