import React, { Component } from 'react';
import './folders.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Folders extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper medium-display-column folders">
                                <h4>עבודה עם תיקיות </h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>צור, העבר, העתק או שנה שם</b>
                                    <span>
                                        בסעיף זה נלמד כיצד לייצר תיקיות , להעביר אותם למיקום אחר ( בדומה לגזור והדבק ) או להעתיק אותם למקום נוסף (בדומה להעתק הדבק) או לשנות את שמם
                                    </span>
                                    <b> יצירת תיקייה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mkdir <directory name>" />

                                    <b> יצירת תיקיה ותתי תיקיות בתוכה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mkdir -p <name1/name2/name3>" />

                                    <b> העברת תיקייה למיקום אחר (כמו גזור הדבק)</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mv <dir name> <new location>" />

                                    <b> העתקת תיקייה למיקום נוסף (כמו העתק הדבק)</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cp -r <dir name> <new location>" />

                                    <b> שנה שם תיקייה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="mv <dir name> <new dir name>" />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 3
                                    let completedIndex = 16
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
