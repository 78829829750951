import React, { Component } from 'react';
import './pwd.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class PWD extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper windows-explorer">
                            <h4>ניווט </h4>
                            <div className="img-div"><img src={companyLogo} /></div> 
                            <p>
                                <b>windows סייר הקבצים של </b>
                                <span>
                                    עד היום היית רגיל ללחוץ על כפתור החלונות והמקש R על מנת לפתוח את סייר הקבצים של מערכת ההפעלה.
                                </span>
                                <span>
                                    אנחנו נרצה להשתמש בטרמינל על מנת להשיג את אותה התוצאה, רשימה של קבצים ותיקיות אשר מופיעים במיקום מסויים.
                                </span>
                            </p>

                            <p>
                                <b>Terminal</b>
                                <span>
                                    אם נפתח את הטרמינל ( אייקון של מסך שחור) נוכל לראות שהוא פתח את הטרמינל וזה נראה כך:
                                </span>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="" />
                                <span>
                                    זה בעצם פתח את ספריית הבית של המשתמש הנוכחי (גם במערכת ההפעלה של חלונות זה כך אם תפתחו את מסוף הפקודה)
                                </span>
                                <span>ישנו את סימן הטילדה ~ שזוהי בעצם ספריית הבית של המשתמש.</span>
                            </p>

                            <p>
                                <b>pwd - Print Working Directory </b>
                                <span>
                                אם נקליד את הפקודה pwd נקבל את השם המלא של המיקום הנוכחי שבקרה הזה זה בעצם הנתיב המלא של ספריית הבית של המשתמש במקרה הזה
                                </span>
                                <span>
                                אם שם המשתמש היה moshe מה שיוצג יהיה <span className='bold blue medium-text'>home/moshe/</span>
                                </span>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="pwd" newline="/home/moshe"/>
                                
                                <span>
                                
                                </span>
                                
                            </p>
                            <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 2
                                        let completedIndex = 8
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                        </div>
                        )
                    }
                }
            </Consumer>)
    }

}
