import React, { Component } from 'react';
import './exploitdatabase.css';
import companyLogo from './../../../../../../assets/Images/dbexploit.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class ExploitDatabase extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ExploitDatabase" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>The Exploit Database</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        כאשר האקר אומר שישנה חולשה שניתן לנצל , ישנה חובת היתכנות לכך, הדבר הזה נקרא בקיצור <b style={{ display: 'inline-block' }}>POC</b> שאלו ראשי תיבות של <b style={{ display: 'inline-block', marginTop: '0px' }}>Proof Of Concept</b>.
                                        אתר זה מכיל בתוכו מאגר של חולשות באפליקציות ובאתרים אשר ניתנות לניצול עם הוכחת ההיתכנות לכך , ממש עם דרך ניצול החולשה בקוד או בכל דרך אחרת, הוא מכיל בתוכו:
                                    </span>
                                </p>


                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Exploits</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Shellcode</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>0days</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Remote Exploits</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Local Exploits</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}> Web Apps</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Vulnerability Reports</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Security Articles</span>
                                    <span style={{ fontSize: '17pt', color: '#7E440C' }}>Tutorials and more.</span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        בהמשך הנושאים כאן נלמד לדוגמה על כלי אונליין בשם wpscan שיכול לסרוק תוספים אשר מותקנים בוורדפרס ולזהות האם הם פגיעים, אם לדוגמה נמצא תוסף אשר הוא פגיע נוכל לחזור למסד הנתונים המופיע כאן ולנסות ולהבין את דרך ניצול החולשה של אותו התוסף!
                                    </span>
                                </p>
                                
                                <p className="c9 links">
                                    <a href="https://www.exploit-db.com/" target='_blank'>https://www.exploit-db.com/</a>
                                </p>

                                <p className="c9 image-on-page-exploit-db-web-exp">
                                    <span className="c4">
                                        בתמונה זו תוכל לראות את האתר ותיבות טקסט המסבירות את מבניות הטבלה:
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>


                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 21
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer >)
    }

}
