import React, { Component } from 'react';
import './lsintroduction.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class LsIntroduction extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ls-introduction">
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <div className='ls-result'>
                                    <h4>ls הפקודה</h4>
                                    
                                    <p>
                                        <b>ls</b>
                                        <span>
                                            אם תפתח את הטרמינל ותריץ את הפקודה ls, תוכל לראות את רשימת הקבצים והתיקיות במיקומך הנוכחי, ממש כפי שהיית פותח סייר קבצים!
                                        </span>

                                    </p>

                                </div>
                                <div className='para-after-image'>
                                    <p>כאן תוכל לראות תיקיות <span className='bold blue medium-text'>( פונט בצבע כחול )</span></p>
                                    <p> כאן תוכל לראות קבצים ( פונט בצבע לבן )</p>
                                    <p>קבצי הרצה <span className='bold green medium-text'>( פונט בצבע ירוק )</span></p>
                                    <p>האם באמת עליי לזכור צבעים של פונטים בכדי להבין באיזה סוג קובץ מדובר?</p>
                                    <p>עבור לסעיף הבא (שימוש עם הפרמטר l-) שם תקבל את תשובתך!</p>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 2
                                        let completedIndex = 9
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
