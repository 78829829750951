import React, { Component } from 'react';
import './architecture.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'


import { Consumer } from "./../../../../../Context/";
export default class Architecture extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column architecuter">
                                <h4>ארכיטקטורת לינוקס</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>חומרה</b>
                                    <span>
                                        הרמה הנמוכה ביותר של ארכיטקטורת לינוקס היא שכבת החומרה. שכבה זו כוללת את הרכיבים הפיזיים של המחשב, כגון הכונן הקשיח, זיכרון RAM, לוח אם, מעבד, ממשקי רשת וציוד היקפי. רכיבים אלה הם החלקים המוחשיים של המערכת שלך עליהם בנויה שאר הארכיטקטורה.
                                    </span>

                                    <b>גרעין</b>
                                    <span>
                                        ממשק ישיר עם שכבת החומרה הוא הקרנל, הלב של מערכת ההפעלה לינוקס. כחלק הליבה של מערכת ההפעלה, הקרנל אחראי למשימות ברמה נמוכה כגון ניהול דיסקים, תזמון משימות, ניהול זיכרון ושליטה בציוד היקפי.
                                        ליבת לינוקס היא ליבה מונוליטית, כלומר היא כוללת מנהלי התקנים, מערכות קבצים, קריאות לשרת מערכת ועוד, והכל בקובץ בינארי סטטי יחיד. מכיוון שהקרנל מקיים אינטראקציה ישירה עם החומרה של המערכת, זה חיוני מבחינת ביצועי ויציבות המערכת.
                                    </span>

                                    <b>מעטפת</b>
                                    <span>
                                        שכבה אחת למעלה מהגרעין היא הקליפה. במונחים הפשוטים ביותר, המעטפת היא ממשק משתמש המאפשר למשתמשים ליצור אינטראקציה עם הקרנל. בלינוקס, רוב האינטראקציות עם המעטפת מתרחשות בממשק שורת פקודה (CLI), שבו משתמשים מקלידים פקודות המתפרשות על ידי המעטפת.
                                        קיימות מספר מעטפות שונות זמינות בלינוקס, כל אחת עם התכונות והתחביר הייחודיים שלה, כגון ה-Bourne Again Shell (bash), ה-C Shell (csh) ו-Z Shell (zsh).
                                    </span>

                                    <b>יישומים</b>
                                    <span>
                                        השכבה העליונה ביותר של ארכיטקטורת לינוקס מורכבת מיישומים. אלו הן התוכנות שאתה, כמשתמש, מתקשר איתן ישירות. הם נעים מיישומי מערכת כמו מנהלי קבצים, עורכי טקסט ומנהלי רשת, ועד ליישומי משתמשים כמו דפדפנים.
                                        בעוד יישומים מתקשרים עם החומרה דרך הקרנל, הם מקיימים אינטראקציה עם המשתמש דרך המעטפת. לדוגמה, כאשר אתה מפעיל פקודה לפתיחת קובץ בעורך טקסט, המעטפת מפרשת את הפקודה שלך, הליבה שואבת את הקובץ מהחומרה, ועורך הטקסט (היישום) מציג אותו.
                                    </span>

                                    <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        // subjectsAndSubTopicsIndex = 0 in this case we are at this row:
                                        // { Course: 'Linux', subIndex: 0, section: 'documentation', subject: 'introduction', subTopics: ['Core principles', 'Linux Architecture', 'The folder structure in Linux', 'Components'], allComponents: [CorePrinciples, Architecture, FolderStructure, Components], completed: ['', '', '', ''] },
                                        // this row contains 4 components, so the subjectsAndSubTopicsIndex = 0
                                        // will be in those 4 compnents
                                        // in brief this is the row index in subjectsAndSubTopics array of objects
                                        let subjectsAndSubTopicsIndex = 0
                                        let completedIndex = 1
                                        let subjectsAndSubTopicsCompletedIndex = 1
                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                                </p>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}

