import React, { Component } from "react";
import './terminal.css';
import { Consumer } from "../Context";
export default class Terminal extends Component {
    render() {

        return (
            <Consumer>
                {
                    c => {
                        let answerData;

                        return (
                            <div id="terminal-container">
                                <div className="Cur_Input_Line">
                                    <span className="Ter_Green">Linuxer</span>
                                    <span className="Ter_Gray">:</span>
                                    <span className="Ter_Blue">~/Desktop/Website</span>
                                    <span className="Ter_Gray">$</span>
                                    <div className="cursor">
                                        <input type="text" id="Terminal-ID" autoComplete="off" className="Ter_Input" ref={input => input && input.focus()} onKeyDown={(event) => {

                                            let Input_Cnt = 0;
                                            let Focus_ele = document.activeElement.className;

                                            // console.log(Focus_ele);
                                            // console.log(event);

                                            let Text = document.querySelector(".Ter_Input").clientWidth;
                                            // console.log(Text);

                                            let Is_Typing = (Focus_ele === "Ter_Input");



                                            if (Is_Typing) {
                                                if (event.code === 'Enter') {

                                                    if (c.qustions[c.qustionNumber].answer === event.target.value) {
                                                        answerData = {
                                                            userAnswer: event.target.value,
                                                            qustionNumber: c.qustionNumber,
                                                            points: 10,
                                                            correct: true,
                                                            checkmarkShape: "M14.1 27.2l7.1 7.2 16.7-16.8"
                                                        }

                                                    }

                                                    else {
                                                        answerData = {
                                                            userAnswer: event.target.value,
                                                            qustionNumber: c.qustionNumber,
                                                            points: 0,
                                                            correct: false,
                                                            checkmarkShape: "M16 16 36 36 M36 16 16 36"
                                                        }

                                                    }

                                                    // console.dir(answerData)
                                                    c.actions.showCheckmark(answerData)
                                                }


                                                else if ((event.code === 'Backspace')) {
                                                    if (Input_Cnt > 0) Input_Cnt = Input_Cnt - 1;
                                                    else
                                                        Input_Cnt = Input_Cnt + 1;

                                                }
                                            }
                                        }
                                        } />
                                    </div>


                                </div>
                                <div id="checkmark-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" className={'checkmark ' + c.checkmarkVisiability}>
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check" fill="none" d={(c.qustions.length != 0) ? c.qustions[(c.qustionNumber === 0) ? c.qustionNumber : c.qustionNumber].checkmarkShape : ''} />
                                    </svg>
                                </div>

                            </div>
                        )

                    }


                }
            </Consumer>
        )
    }
}