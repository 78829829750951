import React, { Component } from 'react';
import './grep.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class Grep extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column grep">
                                <h4>סינון תוכן - grep</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>grep</b>
                                    <span>
                                        לעתים קרובות יותר, נחפש רק תוצאות ספציפיות המכילות תבניות שהגדרנו. אחד הכלים הנפוצים ביותר לכך הוא grep, המציע תכונות רבות ושונות. בהתאם לכך, אנו יכולים לחפש משתמשים עם הגדרת ברירת המחדל של מעטפת  "bin/ bash/" כדוגמה.
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep '/bin/bash'" />
                                <p>
                                    <b>grep -E (--regex=PATTERNS)</b>
                                    <span>
                                        כדי לפרש את התבנית כביטוי רגולרי מורחב, השתמש באפשרות -E (או --extended-regexp). ביטויים רגולריים מורחבים כוללים את כל המטא-תווים הבסיסיים, יחד עם מטא-תווים נוספים ליצירת דפוסי חיפוש מורכבים וחזקים יותר.
                                        בדוגמה הזאת ישנו קובץ אשר מכיל בתוכו בנוסף לטקסט גם כתובות IP, נרצה ללכוד את כתובות ה - IP מתוך הקובץ
                                    </span>
                                    <span></span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat ip.txt | grep -E '[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}'" />
                                <p>
                                    <b>grep -i (--ignore-case)</b>
                                    <span>אם תבצע חיפוש לא רגיש לאותיות גדולות באמצעות האפשרות -i, הוא יתאים הן לאותיות גדולות והן לאותיות קטנות:</span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="grep -i Zebra /usr/share/words" />
                                <p>
                                    <b>grep -o (--only-matching)</b>
                                    <span>הדפס רק את החלקים התואמים (לא ריקים) של התאמה</span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="grep -o Zebra /usr/share/words" />
                                <p>
                                    <span>אם יש את המילה Zebra הוא יציג רק אותה</span>

                                    <b>grep -v</b>
                                    <span>
                                        אפשרות נוספת היא להוציא תוצאות ספציפיות. לשם כך, האפשרות "-v" משמשת עם grep. בדוגמה הבאה, אנו לא כוללים את כל המשתמשים שהשביתו את המעטפת הסטנדרטית בשם "/ bin / false" או "/ usr / bin / nologin".
                                    </span>
                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin'" />
                                <p>
                                    <b>Regular expression Character classes</b>
                                    <span>במודלים של סייבר ישנו המודל של brute force attack, שבו מנסים באמצעות קובץ סיסמאות לנסות לפרוץ לחשבון מסוים, יש מושג שנקרא מדיניות הסיסמה כגון : הסיסמה חייבת להכיל 2 תווים אלפאנומריים ספרה , תו מיוחד וכולי, לכן ישנם מחלקות של תבניות כפי שנסקור בטבלה הבאה:</span>
                                </p>
                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> מחלקת תווים</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > אותיות גדולות</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:upper:]] </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  אותיות קטנות</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> [[:lower:]]  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >רווח כולל שורה חדשה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:space:]]</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > התאם תו ספרתי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:digit:]]</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > כל ספרה הקסדצימלית</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:xdigit:]]</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו גרפי למעט תווי "מילה". </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:punct:]]</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו אלפביתי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:alpha:]]</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו אלפאנומרי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:alnum:]]</TableCell>
                                            </TableRow>

                                        </TableBody>

                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >התאם תו "מילה" (אלפאנומרי פלוס _)</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  [[:word:]]</TableCell>
                                            </TableRow>

                                        </TableBody>

                                    </Table>
                                </div>
                                <p></p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="grep -Eio '[[:alnum:]]{2}:[[:alnum:]]{2}:[[:alnum:]]{2}' ip.txt | sort | uniq " />
                                <p></p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 44
                                        let subjectsAndSubTopicsCompletedIndex = 5

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer >)
    }

}
