import React, { Component } from 'react';
import './codeexample.css';
import { Consumer } from "./../Context/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser';

export default class CodeExample extends Component {

    constructor(props) {

        super(props)
        this.state = {
            codeExampleIndex: props.codeExampleIndex,
        }
    }

    componentDidMount = () => {


    }

    copyButtonClicked = () => {

        let copyText = document.getElementById("codebox-code").innerText

        // Copy the text inside the text field to the browser cache
        navigator.clipboard.writeText(copyText);
    }

    render() {



        return (
            <Consumer>
                {
                    c => {

                        let code = c.codeExmple[this.state.codeExampleIndex].code;
                        let splitedCode = code.split(";")
                        let HTMLCode = splitedCode.map(line => `<span>${line}</span>`)
                        let finalCode = HTMLCode.join(";")
                        //remove the ; /[|&;$%@"<>()+,]/g
                        let filterdCode = finalCode.replace(/;/g, ""); 
                        let htmlObject = { __html: filterdCode };

                        try {

                        }
                        catch (err) {

                        }

                        return (
                            <div className="card code-panel">
                                <div className="card-header example-header"><div className="example-caption">Example: Regex Substitution in JavaScript</div>
                                    <button className="copy-btn pull-right" title="Copy example code" onClick={() => {
                                        this.copyButtonClicked()
                                    }}>
                                        Copy <FontAwesomeIcon icon={faCopy} style={{ fontSize: '13pt', paddingLeft: '5px' }} />
                                    </button>
                                </div>
                                <div className="panel-body">
                                    <pre className=" language-js" tabindex="0">
                                        <code className="language-js" id="codebox-code">
                                            {parse(htmlObject.__html)}
                                        </code>
                                    </pre>
                                </div>

                            </div>

                        )

                    }
                }
            </Consumer >)
    }

}
