
import './App.css';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Header from './components/Heading/Heading';
import { Provider } from './components/Context';
import Dashboard from './components/Home/Dashboard/Dashboard';
import Subjects from './components/Subjects/Subjects';
import Progress from './components/Progress/Progress';
import TopicViewer from './components/TopicViewer/TopicViewer';
// import TestInstructions from './components/Test/TestInstructions/TestInstructions';

function App() {


  return (
    <div className="App App-header">
      <Router>
        <Provider>
          <Header />
          {/* <Subjects />
          <Progress /> */}
          <Routes>
            <Route path="/topic-viewer" element={<TopicViewer />} />
            <Route path="/" element={<Dashboard />} />
            {/* <Route path="/Test" element={<TestInstructions />} /> */}

            
          </Routes>
        </Provider>
      </Router>

    </div>
  );
}

export default App;
