import React, { Component } from 'react';
import './usersactions.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer }  from "./../../../../../Context/";
export default class UsersActions extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className='paper user-actions'>
                            <h4>ניהול המשתמשים</h4>
                            <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>ניהול משתמשים</b>
                                    <span>
                                        ניהול משתמשים הוא חלק חיוני בניהול לינוקס. יצירת משתמשים חדשים הוספת משתמשים אחרים לקבוצות ספציפיות. אפשרות נוספת היא לבצע פקודות כמשתמש אחר. זה, בתורו, מאפשר לנו לאסוף מידע מקומי נוסף על המכונה, שיכול להיות חשוב מאוד.
                                    </span>
                                </p>
                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`ttrow-1`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >ביצוע פקודה עם משתמש מנהל</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  sudo</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-2`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > כלי השירות su מבקש אישורי משתמש מתאימים באמצעות PAM ועובר למזהה משתמש זה (המשתמש המוגדר כברירת מחדל הוא משתמש-העל). </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  su </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-3`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >יוצר משתמש חדש או מעדכן פרטי משתמש חדשים המוגדרים כברירת מחדל.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  useradd</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-4`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מוחק חשבון משתמש וקבצים קשורים.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  userdel</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-5`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > משנה חשבון משתמש.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  usermod</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-6`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מוסיף קבוצה למערכת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  addgroup</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`ttrow-7`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מוחק קבוצה מהמערכת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  delgroup</TableCell>
                                            </TableRow>

                                            <TableRow key={`ttrow-8`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >שינוי סיסמת משתמש</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  passwd</TableCell>
                                            </TableRow>


                                        </TableBody>


                                    </Table>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 8
                                        let completedIndex = 52
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
