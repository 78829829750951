import React, { Component } from 'react';
import './hackersos.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class HackersOS extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper HackersTypes" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>מערכות ההפעלה:</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        מערכות ההפעלה נועדו להקל על ההשתמשות עם המחשב (החומרה).
                                    </span>
                                    <span className='c4'>
                                        ישנן כל מיני גרסאות של מערכות ההפעלה חלונות או מערכות ההפעלה של לינוקס:
                                    </span>
                                </p>
                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>Ubunto</span>
                                    <span style={{ fontSize: '17pt' }}>Fedora</span>
                                    <span style={{ fontSize: '17pt' }}>Red Hat Linux</span>
                                    <span style={{ fontSize: '17pt' }}>Parrot</span>
                                    <span style={{ fontSize: '17pt' }}>Kali</span>
                                </p>
                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        מדוע מערכות הפעלה רבות כל כך?
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4">
                                        ובכן תחשוב על כך שאתה עוסק בתור שיפוצניק, אתה יודע לבצע מספר רב של תיקונים, החל מתיקוני גבס וכלה בחשמל.
                                    </span>

                                    <p className="c5"><span className="c0"></span></p>

                                    <span className="c4">
                                        אם היום אתה עובד על קירות גבס, בארגז הכלים שלך אתה תקח:
                                        מברגה, ברגי פח, גבס ועוד..
                                    </span>

                                    <p className="c5"><span className="c0"></span></p>

                                    <span className="c4">
                                        אם מחר אתה תעבוד על תיקוני חשמל, בארגז הכלים שלך אתה תקח:
                                        טסטר, מהדקי חשמל ועוד..
                                    </span>

                                    <p className="c5"><span className="c0"></span></p>

                                    <span className="c4">
                                        כך גם עם מערכות ההפעלה של לינוקס, כך אחת טומנת בחובה כלים אשר ישמשו אותך לביצוע פעולות מסוימות, אם אתה מחפש:
                                    </span>
                                </p>
                                <p className="c9" >
                                    <span className="c1 c15">
                                        הפצה יציבה וידידותית למשתמש, אז Linux Mint היא אפשרות טובה. אם אתה מחפש הפצה חזקה ועשירה בתכונות, אז Fedora היא אפשרות טובה.
                                    </span>
                                </p>
                                <p className=' c9 hackers-os-img'>
                                    <b className='c1'>מערכות ההפעלה של ההאקרים</b>
                                    <span className='c4'>
                                    Parrot או Kali ואם אתה מעוניין להיות האקר ( כל כובע שתבחר ) אתה תשמש במערכת ההפעלה של 
                                    </span>
                                </p>



                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 17
                                    let completedIndex = 1
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
