import React from "react";
import './progressbar.css';


import { Consumer } from './../../../Context'
import { LinearProgress } from '@mui/material';


export default function ProgressBar(props) {


    let state = {
        numberOfSubTopics: props.numberOfSubTopics,
        completedSubTopics: props.completedSubTopics
    }



    return (

        <Consumer>

            {
                c => {

                    let progressBarValue = (props.completedSubTopics != 0) ? props.completedSubTopics * 100 / props.numberOfSubTopics : 0
                    // console.log(props.numberOfSubTopics, props.completedSubTopics * 100, progressBarValue);
                    return (

                        <div className="progress-bar bg-success" >
                            <LinearProgress variant="determinate" value={progressBarValue} />
                        </div>

                    )

                }
            }
        </Consumer >
    )
}
