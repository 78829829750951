import React, { Component } from 'react';
import './type.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Type extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper find-type">
                                <h4>Type Parameter</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>מה אתה מחפש, קובץ או תיקייה</b>
                                    <span>
                                        ובכן בסעיף הקודם דיברנו היכן אני מחפש, בסעיף הזה נראה מה אני מחפשת תיקייה או קובץ!
                                    </span>
                                    <span>אני מחפש קובץ (f - file)</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type f" />
                                    <span>אני מחפש תיקייה (d - directory)</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find . -type d" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 23
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
