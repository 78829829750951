import React, { Component } from 'react';
import './findsummary.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class FindSummary extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper find-summary">
                                <h4>סיכום פקודת find</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>

                                    <b>לסיכום אם נריץ את הפקודה הבאה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find / -type f iname *.conf -user root -size +20k -newermt 2024-07-03 -exec ls -al {} \; 2>/dev/null" />
                                </p>
                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > בזאת אנו מגדירים את סוג האובייקט שחיפשת. במקרה זה, 'f' מייצג 'קובץ'.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -type f</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  עם '-שם' אנו מציינים את שם הקובץ אותו אנו מחפשים. הכוכבית (*) מייצגת את 'כל' הקבצים עם סיומת '.conf'</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -name *.conf </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >אפשרות זו מסננת את כל הקבצים שבעליהם הוא משתמש הבסיס.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -user root</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > לאחר מכן נוכל לסנן את כל הקבצים שנמצאו ולציין כי אנו רוצים לראות רק את הקבצים הגדולים מ- 20 KiB.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -size +20k</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > עם אפשרות זו, אנו קובעים את התאריך. יוצגו רק קבצים חדשים יותר מהתאריך שצוין.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -newermt 2020-03-03</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > אפשרות זו מבצעת את הפקודה שצוינה, תוך שימוש בסוגריים המתולתלים מצייני מיקום לכל תוצאה. הקו האחורי בורח מהדמות הבאה להתפרש על ידי ה shell מכיוון שאם לא כן, נקודה-פסיק תסיים את הפקודה ולא תגיע לניתוב מחדש.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -exec ls -al { } \;</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >זוהי הפניה STDERR למכשיר האפס ', אליו נחזור בחלק הבא. ניתוב מחדש זה מבטיח שלא יוצגו שגיאות במסוף. הפניה מחדש לא יכולה להיות אפשרות לפקודת 'מצא'.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  &gt;2/dev/null</TableCell>
                                            </TableRow>

                                        </TableBody>


                                    </Table>
                                </div>

                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 5
                                        let completedIndex = 33
                                        let subjectsAndSubTopicsCompletedIndex = 12

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
