import React, { Component } from 'react';
import './whonixinstallation.css';
import companyLogo from './../../../../../assets/Images/800px-Whonix-concept-illustration.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class WhonixInstallation extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper WhonixInstallation">
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>התקנת Whonix</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        פתח את מסוף הפקודה בלינוקס והרץ את הפקודות הבאות:
                                    </span>
                                </p>
                                <p>
                                    <b>הורדה דרך מסוף הפקודה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="curl --tlsv1.3 --output whonix-xfce-installer-cli --url https://www.whonix.org/dist-installer-cli" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>הפעל את תוכנית ההתקנה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="bash ./whonix-xfce-installer-cli" newline="/home/moshe" />
                                </p>
                                <p >
                                    <b>הורדת onion</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="torsocks curl --output whonix-xfce-installer-cli --url http://www.dds6qkxpwdeubwucdiaord2xgbbeyds25rbsgr73tbfpqpt4a6vjwsyd.onion/dist-installer-cli" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>הפעל את תוכנית ההתקנה</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="bash ./whonix-xfce-installer-cli --onion" newline="/home/moshe" />
                                </p>
                                
                                <p className='c9 list-items'>
                                    <b>Whonix Default Passwords</b>
                                    <span style={{ fontSize: '17pt' }}>Default username: user.</span>
                                    <span style={{ fontSize: '17pt' }}>Default password: changeme.</span>  
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 2
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
