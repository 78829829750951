import React, { Component } from 'react';
import './processintroduction.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class ProcessIntroduction extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column">
                                <h4>ניהול שירות ותהליכים</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>demons</b>
                                    <span>
                                        באופן כללי, ישנם שני סוגים של שירותים: פנימיים, השירותים הרלוונטיים הנדרשים בעת הפעלת המערכת, אשר למשל מבצעים משימות הקשורות לחומרה ושירותים המותקנים על ידי המשתמש, הכוללים בדרך כלל את כל שירותי השרת. שירותים כאלה פועלים ברקע ללא כל אינטראקציה של המשתמש. אלה נקראים גם שדים ומזוהים על ידי האות 'd' בסוף שם התוכנית, למשל, sshd או systemd.
                                    </span>
                                </p>

                                <p>
                                    <b>PID</b>
                                    <span>
                                        רוב הפצות לינוקס עברו כעת למערכת. שד זה הוא תהליך ראשוני שהתחיל תחילה וכך יש לו מזהה תהליך PID 1. שד זה מפקח ודואג להפעלה והפסקה מסודרים של שירותים אחרים. לכל התהליכים יש PID מוקצה שניתן להציג תחת / proc / עם המספר המתאים. לתהליך כזה יכול להיות מזהה תהליך הורה (PPID), המכונה תהליך הילד.
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 10
                                        let completedIndex = 59
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
