import React, { Component } from 'react';
import './hidden.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Hidden extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ls-a">
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <div className='ls-a-result'>
                                    <h4>ls -a הפקודה</h4>
                                    
                                    <p>
                                        <b>ls -a</b>
                                        <span>
                                            אם תפתח את הטרמינל ותריץ את הפקודה <span className='bold green medium-text'>ls -a</span>, תוכל לראות את גם את רשימת הקבצים והתיקיות הנסתרים במיקומך הנוכחי,קובץ או תיקייה נסתרת תתחיל עם נקודה לפני שם הקובץ או התיקייה!
                                        </span>

                                    </p>

                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 2
                                        let completedIndex = 11
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
