import React, { Component } from 'react';
import './waybackmachine.css';
import companyLogo from './../../../../../../assets/Images/waybackmachine.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class WaybackMachine extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper WaybackMachine" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>The WayBack Machine</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        The WayBack Machine הוא כלי מקוון המאפשר למשתמשים לגשת לגרסאות מארכיון של אתרי אינטרנט מהעבר, המספק ציר זמן של צילומי מצב לניתוח ההתפתחות והצמיחה של אתר אינטרנט, כמו גם לאחזר מידע שאולי כבר לא יהיה זמין באתר הנוכחי .
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>


                                <p className="c9">
                                    <span className="c4">
                                        ובכן אתה בטח שואל את עצמך איך זה עוזר באיסוף מודיעין על היעד ? תאר לך שאתה רואה אתר שנבנה בשנות ה 90 אז לא הייתה מודעות כה רבה לאבטחת מידע כפי שיש היום!
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        היתכן שמישהו אולי שם באחד הקבצים סיסמאות, או שמות משתמשים בתור הערה באיזה קובץ, נשמע מעניין הלא כן?
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-waybackmachine-tcb">
                                    <span className="c4">
                                        בתמונה שו תוכלו לראות את הארכיון של אתר המכללה הטכנולוגית, התאריכים אשר מסומנים בעיגולים מראים על שינויים שנעשו באתר ואשר נלקח מהם תצלום של מערכת הקבצים בצד הלקוח.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 21
                                    let completedIndex = 7
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
