import React, { Component } from 'react';
import './darknet.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class DarkNet extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper DarkNet" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>


                                <p className="c5"><span className="c0"></span></p>
                                <p className='c9'>
                                    <span className='c2 c1'>שאלה :</span>
                                </p>
                                <p className="c9" dir="rtl">
                                    <span className="c1 c13 c15">
                                        מה תכיל הרשת האפלה?
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9" >
                                    <span className="c4">

                                    </span>

                                </p>

                                <p className="c9">
                                    <span className="c4">
                                        רכישות של דברים לא חוקיים כגון:
                                    </span>
                                </p>
                                <p className='c9 list-items'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}> סמים 🍀</span>
                                    <span style={{ fontSize: '17pt' }}> כלי נשק ⚔️</span>
                                    <span style={{ fontSize: '17pt' }}>הלבנת כספים 💵💵💵💵💵</span>
                                    <span style={{ fontSize: '17pt' }}>לשכור רוצח שכיר 💀</span>
                                    <span style={{ fontSize: '17pt' }}>השגת זהויות בדויות 🥸</span>
                                    <span style={{ fontSize: '17pt' }}>אתרים מציעים למכירה גרין קארדים 🪪</span>
                                    <span style={{ fontSize: '17pt' }}>רשיונות נהיגה 🚘</span>
                                    <span style={{ fontSize: '17pt' }}>דרכונים 🎫</span>
                                </p>
                                <p className="c9">
                                    <span className="c4 c13">
                                        ישנם שימושים אפלים יותר: סחר בבני אדם או יצוא סמים!
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4">
                                        בעצם אנחנו מדברים על זירת מסחר חדשה,
                                        שוק חדש שבו, פחות או יותר, הזהות של הקונה, של המוכר, וגם הכסף, בעצם מוסתרים מהרשויות!
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c4 c13">
                                    כיצד מגיעים לשם לחץ על כפתור ה - Next ותגלה
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 16
                                    let completedIndex = 2
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
