import React, { Component } from 'react';
import './substitution.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CodeExample from './../../../../CodeExample/CodeExample';
export default class Substitution extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper lookarounds" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>Substitution in Regular Expression</b>
                                    <span>
                                        החלפה ב-Regex מתייחסת לתהליך של החלפת התאמות של דפוס במחרוזת החלפה שצוינה. זה מאפשר לך להפוך או לשנות טקסט על סמך ההתאמות שנמצאו במחרוזת הקלט.
                                    </span>
                                </p>
                                <div id="linux-components-substitution">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> הַחלָפָה</TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}>תיאור</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > number $ </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304', direction: 'rtl' }}> כולל את המחרוזת המשנה האחרונה המותאמת לקבוצת הלכידה שמזוהה לפי מספר, כאשר מספר הוא ערך עשרוני, במחרוזת החלפה  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > &#123; name &#125;$  </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304', direction: 'rtl' }}>  כולל את המחרוזת המשנה האחרונה שהתואמת לקבוצה בעלת השם שמסומנת על ידי (? ) במחרוזת ההחלפה </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > $$  </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}>  כולל מילת "$" בודדת במחרוזת ההחלפה </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > &$ </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}>   כולל עותק של כל ההתאמה במחרוזת ההחלפה </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > `$ </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}>  כולל את כל הטקסט של מחרוזת הקלט לפני ההתאמה במחרוזת ההחלפה </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  '$</TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}> כולל את כל הטקסט של מחרוזת הקלט לאחר ההתאמה במחרוזת ההחלפה  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > +$ </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}>  כולל את הקבוצה האחרונה שנלכדה במחרוזת ההחלפה </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  _$ </TableCell>
                                                <TableCell align="right" style={{ color: '#93E304' }}> כולל את כל מחרוזת הקלט במחרוזת ההחלפה  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </div>
                                <p>
                                    <span>
                                        אתה יכול להחליף מחרוזת באמצעות הפניה לאחור של ביטויים regex באמצעות פונקציה/שיטה להחלפת מחרוזת שסופקה על ידי שפת התכנות או הכלי שבהם אתה משתמש.
                                    </span>

                                    <span>
                                        <b>   הדוגמה הבאה מדגימה כיצד ניתן להחליף מחרוזת באמצעות הפניה לאחור ב-Regex ב-JavaScript:</b>
                                    </span>
                                </p>
                                <CodeExample codeExampleIndex="0" />

                                <p>
                                    <span>
                                        <b> החלף מחרוזת באמצעות הפניה חזרה</b>
                                    </span>
                                    <span>
                                        אתה יכול להחליף מחרוזת על ידי התייחסות לקבוצה התואמת באמצעות $. השתמש במספר הקבוצה התואם המתאים, למשל. $1 לקבוצה הראשונה, $2 לשנייה וכן הלאה.
                                    </span>
                                    <span>
                                        ההפניה הבאה משתמשת בהפניה לאחור של $1, $2, $3 ו-$4 עבור הקבוצות שנתפסו והוספה - בין כל קבוצה מותאמת.
                                    </span>
                                </p>
                                <CodeExample codeExampleIndex="1" />
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 13
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
