import React, { Component } from 'react';
import './shellhelp.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from './../ShellTerminal/ShellTerminal';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class ShellHelp extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4> קבלת עזרה</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b></b>
                                    <span>
                                        תמיד נתקל בכלים שאת הפרמטרים האופציונליים שלהם איננו מכירים מהזיכרון או מכל כלים שטרם ראינו. לכן חיוני לדעת כיצד אנו יכולים לעזור לעצמנו להכיר את הכלים הללו.
                                    </span>
                                </p>

                                <p>
                                    <b>man, help, apropos</b>
                                    <span>
                                        <span className='bold blue medium-text'>man</span> בדפי ה - man, נמצא את המדריכים המפורטים עם הסברים מפורטים.<span className='bold blue medium-text'> &lt;man &lt;tool</span>
                                    </span>

                                </p>
                                <ShellTerminal user="moshe@webschool26" location="[~]" cursor="man curl" />
                                <p>
                                    <b>help</b>
                                    <span>
                                        מאפשר להסתכל במהירות על הפרמטרים האופציונליים מבלי לדפדף בתיעוד המלא.<span className='bold blue medium-text'>  tool&gt; --help&gt;</span>
                                    </span>
                                </p>
                                <ShellTerminal user="root@webschool26" location="#" cursor="curl --help" />


                                <p>
                                    <b>apropos</b>
                                    <span>
                                        כלי נוסף שיכול להיות שימושי הוא apropos. לכל דף ידני יש תיאור קצר זמין.<span className='bold blue medium-text'> &lt;aprops &lt;keyword</span>
                                    </span>
                                </p>
                                <ShellTerminal user="root@webschool26" location="#" cursor="aprops sudo" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 1
                                        let completedIndex = 6
                                        let subjectsAndSubTopicsCompletedIndex = 2
                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>


                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
