import React, { Component } from 'react';
import './packagesactions.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "../../../../../Context";
export default class PackagesActions extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column package-actions">
                                <h4>פעולות על החבילות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <span>
                                        כל הפצה של לינוקס משתמשת במאגרי תוכנה שמתעדכנים לעיתים קרובות. כאשר אנו מעדכנים תוכנית או מתקינים תוכנית חדשה, המערכת תשאול את המאגרים הללו לחבילה הרצויה. ניתן לתייג מאגרים כיציבים, מבחנים או לא יציבים. מרבית הפצות לינוקס משתמשות במאגר היציב ביותר או "הראשי" ביותר. ניתן לבדוק זאת על ידי הצגת תוכן הקובץ /etc/apt/sources.list. רשימת המאגר של מערכת ההפעלה Parrot נמצאת בכתובת /etc/apt/sources.list.d/parrot.list.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/apt/source.list.d/parrot.list" />
                                <p>
                                    <b>APT</b>
                                    <span>
                                        APT משתמש במסד נתונים הנקרא מטמון APT. זה משמש כדי לספק מידע על חבילות המותקנות במערכת שלנו במצב לא מקוון. אנו יכולים לחפש במטמון APT, למשל, כדי למצוא את כל החבילות הקשורות ל- Impacket.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="apt-cache search impackt" />
                                <p>
                                    <b>מידע על חבילות מותקנות</b>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="apt list --installed" />
                                <p>
                                    <b>חבילה חסרה</b>
                                    <span>
                                        אם חסרים לנו כמה חבילות, נוכל לחפש אותה ולהתקין אותה באמצעות הפקודה הבאה.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo apt install <package name> -y" />
                                <p></p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 9
                                        let completedIndex = 57
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
