import React, { Component } from 'react';
import './quantifiers.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import RegExCompnent from '../RegExCompnent/RegExCompnent';
import { Consumer } from "./../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Quantifiers extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper ">
                                <h4>Quantifiers in Regex</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>Quantifiers</b>
                                    <span>
                                        בביטויים רגולריים, מכמתים הם תווים מטא המציינים כמה פעמים יש להתאים את התו או הקבוצה הקודמים.
                                    </span>
                                    <span>
                                        מכמתים מאפשרים לך לציין את מספר המופעים של דמות או קבוצה, מה שמקל על התאמת דפוסים באורכים שונים.
                                    </span>
                                    <span>
                                        עם זאת, חשוב להשתמש בחוכמה במכמתים מכיוון ששימוש לא נכון בהם עלול להוביל לתוצאות שגויות.
                                    </span>
                                </p>
                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}>מכמתים בביטוי רגולרי</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > + מציין שהתו הקודם חייב להופיע לפחות פעם אחת או יותר.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  + </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >? מציין שהתו הקודם הוא אופציונלי. זה אומר שהתו הקודם יכול להופיע אפס או פעם אחת.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ?  </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >תואם לאפס או יותר מהתו הקודם.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  * </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מתאים בדיוק ל-n מופעים של התו הקודם.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> &#123;n&#125; </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מתאים ל-n מופעים או יותר של התו הקודם.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  &#123;n,&#125; </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >התאמה בין n ל-m מופעים של האלמנט הקודם</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  &#123;n,m&#125; </TableCell>
                                            </TableRow>

                                        </TableBody>


                                    </Table>
                                </div>

                                <p>
                                    <b>הסימן <span className='metacharacters'>+</span> משמש למציאת מופע אחד או יותר של התו הקודם. אז, דפוס 'colou+r' מוצא את 'color' ו-'colouuuur' כאשר 'u' קיים לפחות פעם אחת בין 'colo' ל-'r'.</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-1'} patternIndex="4" />

                                <p>
                                    <b> ה <span className="metacharacters">?</span>פועל ככמת אופציונלי. ה-u? בתבנית colou?r הופכת 'u' בתור char אופציונלי שעשוי להגיע לאפס או פעם אחת אבל לא יותר.</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-2'} patternIndex="5" />

                                <p>
                                    <b>מכמת<span className="metacharacters">*</span>מוצא אפס מופעים או יותר של התו הקודם. תבנית colou*r מוצאת את כל השילובים בין אם התרחשה אפס, פעם אחת או יותר.</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-3'} patternIndex="6" />

                                <p>
                                    <b>ה - <span className="metacharacters">colou &#123; 1 &#125;r</span>מוצא בדיוק מופע אחד של 'u' במחרוזת הקלט</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-4'} patternIndex="7" />

                                <p>
                                    <b>ה -<span className="metacharacters">'colou&#123; 1, &#125;r'</span>מוצא מופע אחד או יותר של 'u'.</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-5'} patternIndex="8" />

                                <p>
                                    <b>ה - <span className="metacharacters">'colou&#123; 1,4 &#125;r'</span>מוצא אחד עד ארבע מופעים של 'u' במחרוזת הקלט.</b>
                                </p>
                                <RegExCompnent key={'RegExCompnent-6'} patternIndex="9" />
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
