import React, { Component } from 'react';
import './systeminfo.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class SystemInfo extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4>מידע על המערכת</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <div id="shell-system-inof-linux">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow-1`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  הצג משתמש נוכחי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  whoami</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-2`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מחזיר זיהוי של המשתמש</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  id </TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-3`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מדפיס את שם המחשב</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  hostname</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-4`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מדפיס את שם מערכת ההפעלה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  uname</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-5`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג תיקיית עבודה נוכחית</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  pwd</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-6`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מידע על קונפיגורציית הרשת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ipconfig</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-7`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג טבלת ראוטינג, התקני רשת וממשקים</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ip</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-8`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מראה את סטטוס הרשת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  netstat</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-9`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מידע על סוקטים</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ss</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-10`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג סטטוס של תהליכים</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  ps</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-11`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  הצג משתמש נוכחי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  whoami</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-12`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג מי מחובר למערכת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  who</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-13`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג משתני סביבה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  env</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-14`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מציג התקני בלוק (הארד דיסק)</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  lsblk</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-15`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מציג התקני USB</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  lsusb</TableCell>
                                            </TableRow>
                                        
                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow-16`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מציג קבצים פתוחים</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  lsof</TableCell>
                                            </TableRow>
                                            <TableRow key={`trow-17`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מציג התקני PCI</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  lspci</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 1
                                        let completedIndex = 7
                                        let subjectsAndSubTopicsCompletedIndex = 3

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
