import React, { Component } from 'react';
import './reconnaissanceintroduction.css';
import companyLogo from './../../../../../assets/Images/foot.png';

import slide1recon from './../../../../../assets/Images/slide1-recon.png'
import slide2recon from './../../../../../assets/Images/slide2-recon.png'
import slide3recon from './../../../../../assets/Images/slide3-recon.png'
import slide4recon from './../../../../../assets/Images/slide4-recon.png'
import slide5recon from './../../../../../assets/Images/slide5-recon.png'
import slide6recon from './../../../../../assets/Images/slide6-recon.png'

import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class ReconnaissanceIntroduction extends Component {

    constructor(props) {

        super(props)
        this.state = {
            slideIndex: 1,
        }
    }

    componentDidMount() {

        this.showSlides(this.state.slideIndex);
    }



    plusSlides = (n) => {
        this.showSlides(this.state.slideIndex += n);
    }

    currentSlide = (n) => {
        this.showSlides(this.state.slideIndex = n);
    }

    showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        if (n > slides.length) { this.state.slideIndex = 1 }
        if (n < 1) { this.state.slideIndex = slides.length }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }

        slides[this.state.slideIndex - 1].style.display = "block";
        dots[this.state.slideIndex - 1].className += " active";
    }




    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper SurfingAnonymouslyIntro" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontFamily: 'Alfa Slab One', fontSize: "26pt", color: "#ff5722" }}>Reconnaissance - איסוף מודיעין על היעד</b>
                                    <span className='c4'>
                                        לחץ על כפתורי הניווט על מנת לצפות במצגת
                                    </span>
                                </p>

                                <div className="slideshow-container">

                                    <div className="mySlides fade">
                                        <div className="numbertext">1 / 6</div>
                                        <img src={slide1recon} style={{ width: '100%' }} />
                                        <div className="text">Caption One</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">2 / 6</div>
                                        <img src={slide2recon} style={{ width: '100%' }} />
                                        <div className="text">Caption Two</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">3 / 6</div>
                                        <img src={slide3recon} style={{ width: '100%' }} />
                                        <div className="text">Caption Three</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">4 / 6</div>
                                        <img src={slide4recon} style={{ width: '100%' }} />
                                        <div className="text">Caption Four</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">5 / 6</div>
                                        <img src={slide5recon} style={{ width: '100%' }} />
                                        <div className="text">Caption Five</div>
                                    </div>

                                    <div className="mySlides fade">
                                        <div className="numbertext">6 / 6</div>
                                        <img src={slide6recon} style={{ width: '100%' }} />
                                        <div className="text">Caption Six</div>
                                    </div>



                                    <a className="prev" onClick={() => { this.plusSlides(-1) }}>❮</a>
                                    <a className="next" onClick={() => { this.plusSlides(1) }}>❯</a>

                                </div>


                                <div style={{ textAlign: 'center' }}>
                                    <span className="dot" onClick={() => { this.currentSlide(1) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(2) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(3) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(4) }} ></span>
                                    <span className="dot" onClick={() => { this.currentSlide(5) }}></span>
                                    <span className="dot" onClick={() => { this.currentSlide(6) }}></span>
                                </div>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 19
                                    let completedIndex = 0
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>






                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
