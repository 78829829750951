import React, { Component } from 'react';
import './BettercapInstallation.css';
import companyLogo from './../../../../../../assets/Images/bettercap.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../../Linux/Shell/ShellTerminal/ShellTerminal';
export default class BettercapInstallation extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapInstallation" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap Installation</b>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                <p>
                                    <b>תליתויות</b>
                                    <span>על מנת שתוכל להשתמש ב-betercap, תזדקק לתלותיות הבאות במערכת שלך:</span>
                                </p>
                                <p className='c9 list-items'>
                                    <span style={{ fontSize: '17pt' }}>pkg-config</span>
                                    <span style={{ fontSize: '17pt' }}>libpcap</span>
                                    <span style={{ fontSize: '17pt' }}>libusb-1.0-0 (נדרש על ידי מודול HID)</span>
                                    <span style={{ fontSize: '17pt' }}>libnetfilter-queue (ב-Linux בלבד, נדרש על ידי מודול packet.proxy)</span>
                                </p>

                                <p>
                                    <b>Installing via Go</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="go install github.com/bettercap/bettercap@latest" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>Using Docker</b>
                                    <span>
                                        BetterCAP מרוכזת במכולות באמצעות Alpine Linux - הפצת לינוקס מוכוונת אבטחה וקלת משקל המבוססת על musl libc ו-busybox. תמונת Docker שהתקבלה היא קטנה יחסית וקל לנהל את התלות. מכיוון שהוא משתמש בבנייה מרובה שלבים, נדרשת גרסת Docker גדולה מ-17.05.
                                    </span>
                                    <span>כדי למשוך את הגרסה האחרונה של התמונה:</span>
                                </p>
                                <p>
                                    <b></b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="docker pull bettercap/bettercap" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <span>להריץ:</span>
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="docker run -it --privileged --net=host bettercap/bettercap -h" newline="/home/moshe" />
                                </p>
                                <p>
                                    <b>קומפילציה ממקורות</b>
                                    <span>על מנת להרכיב Bettercap ממקורות, ודא כי:</span>
                                </p>
                                <p className='c9 list-items image-on-page-'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>יש לך סביבת Go &gth;= 1.8 מוגדרת כהלכה.</span>
                                    <span style={{ fontSize: '17pt' }}>GOPATH$ מוגדר ו-GOPATH/bin$ נמצא ב-PATH$.</span>
                                    <span style={{ fontSize: '17pt' }}>עבור חומרה עם משאבים מוגבלים (כמו לוחות Raspberry Pi Zero) אולי תרצה להגדיל את גודל ההחלפה.</span>
                                </p>
                                <p>
                                    <span>תצטרך גם להתקין את התלות:</span>
                                </p>
                                <p className='c9 list-items image-on-page-'>
                                    <b></b>
                                    <span style={{ fontSize: '17pt' }}>build-essential</span>
                                    <span style={{ fontSize: '17pt' }}>libpcap-dev</span>
                                    <span style={{ fontSize: '17pt' }}>libusb-1.0-0-dev (required by the HID module)</span>
                                    <span style={{ fontSize: '17pt' }}>libnetfilter-queue-dev (on Linux only, required by the packet.proxy module)</span>
                                </p>
                                <p>
                                    <span>לאחר שעמדת בתנאים אלה, תוכל להפעיל את הפקודות הבאות כדי להדר ולהתקין bettercap ב-/usr/local/bin/bettercap</span>
                                </p>
                                <p>
                                    
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="go get github.com/bettercap/bettercap && cd $GOPATH/src/github.com/bettercap/bettercap && make build" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo make install" newline="/home/moshe" />
                                </p>
                                <p>
                                    <span className="c4">
                                        <b>Compiling on Android</b>
                                    </span>
                                    <span>Termux Method</span>
                                </p>
                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        הליך זה ו-betercap עצמו דורשים מכשיר מושרש.
                                    </span>
                                </p>
                                <p>
                                    <b>התקן את Termux ומתוך סוג ההנחיה שלו:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="pkg install root-repo" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="pkg install golang git libpcap libusb" newline="/home/moshe" />
                                </p>


                                <p>
                                    <b>יש באג גולאנג ב-termux לגבי נתיב מקודד קשה, התיקון מכוער אבל הוא עובד:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo su && mount -o rw,remount / && mkdir -p /home/builder/.termux-build/_cache/18-arm-21-v2/bin/" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="ln -s `which pkg-config` /home/builder/.termux-build/_cache/18-arm-21-v2/bin/arm-linux-androideabi-pkg-config" newline="/home/moshe" />
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        שיטת פריסת לינוקס מבוססת דביאן (כמו אובונטו)
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        התקן את Linux Deploy, JuiceSSH, ב-Linux Deploy התקן kalilinux_arm (אתה צריך את ה-piggy helper ולהפעיל את ה-SSH) והקלד:
                                    </span>
                                </p>
                                <p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo apt update && sudo apt install golang git build-essential libpcap-dev libusb-1.0-0-dev libnetfilter-queue-dev" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <span>כעת תוכל להמשיך עם הקומפילציה:</span>
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="go get -u github.com/bettercap/bettercap" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <span>לאחר סיום תהליך הבנייה, הקובץ הבינארי ימוקם ב-go/bin/bettercap.</span>
                                </p>

                                <p>
                                    <b>שיטת פריסת לינוקס מבוססת פדורה (כמו Redhat, Centos)</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo dnf update" newline="/home/moshe" />
                                    <p className="c5"><span className="c0"></span></p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo dnf install golang git make automake gcc gcc-c++ kernel-devel libpcap-devel libusb-devel libnetfilter_queue-devel" newline="/home/moshe" />
                                </p>
                               
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 5
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
