import React, { Component } from 'react';
import './BettercapSystemOverview.css';
import companyLogo from './../../../../../../assets/Images/bettercap.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../../Linux/Shell/ShellTerminal/ShellTerminal';
export default class BettercapSystemOverview extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapSystemOverview" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap System Overview</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        help-- עזרה על אופן השימוש בפקודה:
                                    </span>
                                </p>
                                <p>
                                    <b></b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor=" bettercap --help" newline="/home/moshe" />
                                </p>
                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        אנו אמורים לספק את הממשק של כרטיס הרשת שלנו, על מנת להציג  את שם הממשק נקליד:
                                    </span>
                                </p>
                                <p>
                                    <b></b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]" cursor="ifconfig" newline="/home/moshe" result="eth0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
        inet 192.168.63.128  netmask 255.255.255.0  broadcast 192.168.63.255
        inet6 fe80::20c:29ff:fea6:eeb0  prefixlen 64  scopeid 0x20<link>
        ether 00:0c:29:a6:ee:b0  txqueuelen 1000  (Ethernet)
"/>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p>
                                    <b>השימוש בפקודה:</b>
                                    <ShellTerminal user="<username>@<hostname>" location="[#]"
                                        cursor="bettercap -iface eth0"
                                        newline="/home/moshe"
                                        result="bettercap v2.31.0 (built for linux amd64 with go1.16.3) [type 'help' for a list of commands] "
                                        newlineResultCol1=" 192.168.63.0/24 "
                                        newlineResultCol2=" > 192.168.63.128 "
                                        newlineResultCol3=" » "
                                        newlineResultBG1="#F1C232"
                                        newlineResultBG2="#F1C232"
                                        newlineResultBG3=""
                                        textColor1="white"
                                        textColor2="#404040"
                                        textColor3="white"
                                        resultWidth1="11%"
                                        resultWidth2=""
                                        resultWidth3="11%"
                                        marginLeft1=""
                                        marginLeft2=""
                                        marginLeft3=""
                                        paddingLeft1="5px"
                                        paddingLeft2="5px"
                                        paddingLeft3="5px"
                                    />
                                    <p className="c5"><span className="c0"></span></p>
                                    <span>זה פתח לנו את ממשק הפקודה</span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                               
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 6
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
