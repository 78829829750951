import React, { Component } from 'react';
import './searchengines.css';
import companyLogo from './../../../../../../assets/Images/browsers.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class SearchEngines extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper SearchEngines" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>איסוף מודיעין על ישות אנושית לפי תמונה 📸</b>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9">
                                    <span className="c4">
                                        נוכל להשתמש בתמונה של היעד ולהשתמש במנועי החיפוש השונים על מנת להעלות קבצי תמונה, קבצי קול, או ציטוטי טקסט!
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 links">
                                    <span className="c4">
                                        מנועי חיפוש שונים להעלאת תמונה וחיפושה ברשת:
                                    </span>
                                    <a href="https://www.google.com/imghp" target='_blank'> https://www.google.com/imghp</a>
                                    <a href="https://yandex.com/images/" target='_blank'>https://yandex.com/images/</a>
                                    <a href="https://www.bing.com/" target='_blank'>https://www.bing.com/</a>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 searchengines">
                                    <span className="c4">
                                        לחץ על כפתור חיפוש התמונה, ןלאחר מכן תוכל להעלות תמונה מהמחשב האישי שלך או להדביק קישור של תמונה מאתר חיצוני:
                                    </span>
                                </p>

                                <p className="c9 searchengines-upload-img">
                                    <span className="c4">
                                    </span>
                                </p>
                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 20
                                    let completedIndex = 1
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
