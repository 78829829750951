import React, { Component } from 'react';
import './maltego.css';
import companyLogo from './../../../../../../assets/Images/maltego.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Maltego extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper Maltego" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>Maltego</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        Maltego היא תוכנת ניתוח קישורים המשמשת עבור מודיעין בקוד פתוח, פורנזיות וחקירות אחרות, שפותחה במקור על ידי Paterva מפרטוריה, דרום אפריקה.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 links image-on-page-maltego">
                                    <b style={{ fontSize: '17pt' }}>הורדה</b>
                                    <span className="c4">
                                        היכנס לקישור:
                                    </span>
                                    <a>https://www.maltego.com/downloads</a>
                                    <span className='c4'>
                                        והורד את קובץ ההתקנה ובצע את תהליך ההרשמה
                                        בחר את CE שזה ה - (Comunity addition)
                                    </span>
                                </p>


                                <p className="c9 links">
                                    <b style={{ fontSize: '17pt' }}>Transform Hub</b>

                                    <span className='c4'>
                                        עלייך להתקין את ה - Transform Hub שתרצה,  זה רכיב אשר מובנה בכל לקוח של Maltego ומאפשר למשתמשי Maltego להתקין בקלות Transforms שנבנו על ידי ספקי נתונים שונים.
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        ההמרות הסטנדרטיות של Maltego הן טרנספורמציות OSINT ברירת המחדל הנשלחות עם כל לקוח שולחני של Maltego. אלה כוללים טרנספורמציות לאיסוף OSINT ממקורות נפוצים באינטרנט כגון שאילתות בשרתי DNS, מנועי חיפוש, רשתות חברתיות, ממשקי API שונים ומקורות אחרים.
                                    </span>
                                </p>

                                <p className="c9 image-on-page-maltego-Transform-Hub">

                                    <span className="c4">
                                        על מנת להתקין את את רכזות המרת המידע נבחר בתפריט העליון Transform, ומהתפריט השמאלי ביותר נבחר את Transform Hub.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-Maltego-Community">
                                    <span className="c4">
                                        נבחר בעמודת Plans את Maltego Community
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-install-transform">
                                    <b style={{ fontSize: '17pt' }}>Transform Hub Installation</b>
                                    <span className="c4">
                                        אם נעמוד על אחד מרכזות ההמרה, ישנה אפשרות לקבל פירוט על רכזת המידע, או התקנתה בפועל:
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-2-hubs">
                                    <span className="c4">
                                        הבה נתקין את Social Links CE ואת TinEye:
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-Investegate">
                                    <span className="c4">
                                        בתפריט העליון בחר Investegate ולחץ על האיקון New, זה יפתח משטח עבודה שנוכל לגרור אליו רכיבים שבאמצעותם נוכל לאסוף מידע דיגטלי על אנשים:
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-person">
                                    <span className="c4">
                                        בגרף החדש שנפתח נוכל לגרור אליו מהתפריט הצדדי השמאלי לדוגמה את הרכיב Person, נוכל ללחוץ לחיצה כפולה על רכיב זה ולהזין שם של אדם מסויים, ניתן להזין את פרטיו גם בצד הימני כפי שמופיע בתמונה:
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9 image-on-page-maltego-run-transformation">
                                    <b style={{ fontSize: '17pt' }}>הרצת טרנסופרמציות</b>
                                    <span className="c4">
                                        לאחר מכן באמצעות לחיצה ימנית של העכבר על האייקון ניתן לבחור להריץ את כל הטרנספורמציות,  ונוכל לקבל על אותה הישות מידע דיגיטלי:
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 20
                                    let completedIndex = 4
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
