import React, { Component } from 'react';
import './whonix.css';
import companyLogo from './../../../../../assets/Images/800px-Whonix-concept-illustration.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class Whonix extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper Whonix">
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>השתמש ב - Whonix</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">

                                        Whonix היא הפצת לינוקס מבוססת-Kicksecure מוקשחת אבטחה. מטרותיה העיקריות הן לספק פרטיות ואנונימיות חזקה באינטרנט. מערכת ההפעלה מורכבת משתי מכונות וירטואליות, תחנת עבודה ושער Tor המריץ את דביאן. כל התקשורת עוברת דרך Tor.

                                    </span>
                                </p>
                               
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 1
                                    let subjectsAndSubTopicsCompletedIndex = 1
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
