import React, { Component } from 'react';
import './pipes.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Pipes extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper pipes">
                                <h4>Pipes</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>pipes</b>
                                    <span>
                                        דרך נוספת להפנות STDOUT היא להשתמש בצינורות (|). אלה שימושיים כאשר אנו רוצים להשתמש ב- STDOUT מתוכנית אחת לעיבוד אחר. אחד הכלים הנפוצים ביותר הוא grep, בו נשתמש בדוגמה הבאה. Grep משמש לסינון STDOUT על פי התבנית שאנו מגדירים. בדוגמה הבאה, אנו משתמשים בפקודה find כדי לחפש את כל הקבצים בספריית "/ etc /" עם סיומת ".conf". כל שגיאות מנותבות ל"מכשיר)(/dev/null). באמצעות grep, אנו מסננים את התוצאות ומציינים כי רק השורות המכילות את התבנית "systemd" צריכות להיות מוצגות.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -name *.conf 2>/dev/null | grep systemd" />
                                    <span>
                                        ההפניות מחדש פועלות, לא רק פעם אחת. אנו יכולים להשתמש בתוצאות שהתקבלו כדי להפנות אותן לתוכנית אחרת. לדוגמא הבאה נשתמש בכלי שנקרא wc, אשר אמור לספור את המספר הכולל של התוצאות שהתקבלו.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="find /etc/ -name *.conf 2>/dev/null | grep systemd | wc -l" />

                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 6
                                        let completedIndex = 38
                                        let subjectsAndSubTopicsCompletedIndex = 4

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
