import React, { Component } from 'react';
import './BettercapModules.css';
import companyLogo from './../../../../../../assets/Images/bettercap.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class BettercapModules extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper BettercapModules" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>bettercap Modules</b>
                                </p>


                                <p className="c9 image-on-page-bettercap-modules">
                                    <span className="c4">
                                        משום שאין אנו מכירים את אופן התפעול של הכלי, ישנה אפשרות להקליד help, כפי שמופיע בהערה על מנת לקבל את רשימת הפקודות שניתן להריץ, מה שאותי מעניין זה רשימת ה -
                                    </span>
                                    <span className="c4" style={{ backgroundColor: 'black', color: 'red', padding: '0px 5px' }}>
                                        Modules
                                    </span>
                                    <span className="c4">
                                        &nbsp;&nbsp;&nbsp;, כולם לא פעילים למעט
                                    </span>
                                    <span className="c4" style={{ textAlign: 'left', direction: 'ltr', backgroundColor: 'black', color: '#DBB132', padding: '0px 5px' }}>
                                        events.stream </span><span className="c4" style={{ textAlign: 'left', direction: 'ltr', backgroundColor: 'black', color: 'red' }}>&gt;</span>
                                    <span className="c4" style={{ display:'inline-block !important',textAlign: 'left', direction: 'ltr', backgroundColor: 'black', color: '#1BD130' }}>running</span>
                                    <span className="c4" style={{display:'inline-block !important', padding:'0px 5px'}}></span>
                                    <span className="c4">אשר רץ ברקע ואחראי על ניהול אירועים.</span>
                                </p>

                                <p className="c9" >
                                    <span className="c1 c13 c15">

                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9" >
                                    <span className="c1 c13 c15">

                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 25
                                    let completedIndex = 7
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
