import React, { Component } from 'react';
import './components.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Consumer } from "./../../../../../Context/";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Components extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4>רכיבים</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  פיסת קוד שרצה להנחות את תהליך האתחול להפעלת מערכת ההפעלה. אובונטו לינוקס משתמשת ב- GRUB Bootloader.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  Bootloader</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  הגרעין הוא המרכיב העיקרי של מערכת הפעלה. הוא מנהל את המשאבים עבור התקני קלט / פלט במערכת ברמת החומרה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  OS Kernel </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > שירותי רקע מכונים בלינוקס "שדים". מטרתם היא להבטיח כי פונקציות מפתח כגון תזמון, הדפסה ומולטימדיה פועלות כראוי. תוכניות קטנות אלה נטענות לאחר שהתחלנו או התחברנו למחשב.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  Daemons</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מעטפת מערכת ההפעלה או מתורגמן שפת הפקודה (המכונה גם שורת הפקודה) הוא הממשק בין מערכת ההפעלה למשתמש. ממשק זה מאפשר למשתמש לומר למערכת ההפעלה מה לעשות. הקונכיות הנפוצות ביותר הן Bash, Tcsh / Csh, Ksh, Zsh, and Fish.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  OS Shell</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > זה מספק תת-מערכת גרפית (שרת) בשם "X" או "X-server" המאפשרת לתוכניות גרפיות לפעול באופן מקומי או מרחוק במערכת חלונות ה- X.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  Graphics server</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > ידוע גם כממשק משתמש גרפי (GUI). ישנן אפשרויות רבות, כולל GNOME, KDE, MATE, Unity וקינמון. בסביבת שולחן עבודה יש ​​בדרך כלל מספר יישומים, כולל דפדפני קבצים ואינטרנט. אלה מאפשרים למשתמש לגשת ולנהל את התכונות והשירותים החיוניים והגישה הנפוצה של מערכת הפעלה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  Window Manager</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > יישומים או כלי עזר הם תוכניות המבצעות פונקציות מסוימות עבור המשתמש או תוכנית אחרת.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  Utilities</TableCell>
                                            </TableRow>

                                        </TableBody>


                                    </Table>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 0
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = 3

                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                   
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
