import React, { Component } from 'react';
import './filedescriptors.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';

import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class FileDescriptors extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ">
                                <h4>File Descriptors </h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>file Descriptors</b>
                                    <span>
                                        מתאר קבצים (FD) במערכות הפעלה של יוניקס / לינוקס הוא אינדיקטור לחיבור מתוחזק על ידי הליבה לביצוע פעולות קלט / פלט (קלט / פלט). במערכות הפעלה מבוססות Windows זה נקרא Filehandle. זהו החיבור (בדרך כלל לקובץ) ממערכת ההפעלה לביצוע פעולות קלט / פלט
                                    </span>

                                    <b>3 מתארי הקבצים הראשונים</b>
                                    <span>(קלט / פלט של בתים). כברירת מחדל, שלושת מתארי הקבצים הראשונים בלינוקס הם:</span>
                                    <p>

                                        <span className='ltr'> (<span className='bold green medium-text'>r</span>) - STDIN  :&nbsp;&nbsp;&nbsp;&nbsp;  0 (Data Stream for Input)</span>
                                        <span className='ltr'> (<span className='bold green medium-text'>w</span>) - STDOUT :  1 (Data Stream for Output)</span>
                                        <span className='ltr'>(<span className='bold green medium-text'>x</span>) -  STDERR :  2 (Data Stream for Output that relates to an error occurring.)</span>
                                    </p>
                                    <p>
                                        <span>אתה די מכיר היכן אתה באינטרקציה עם הקלט שלך - המקלדת ⌨</span>
                                        <span>אתה די מכיר את היכן מוצג באופן סטנדרטי הפלט שלך - המסך 🖥</span>
                                        <span>ומה שמזהה שגיאה כגון הקלדה לא נכונה של פקודה, או אפילו אם אתה מנסה לבצע משהו על תיקייה או קובץ שאין לך הרשאות עבור הפעולה הזאת - Permission Denied!</span>
                                    </p>
                                    <p>
                                        <b>STDIN and STDOUT</b>
                                        <span>
                                            הבה נראה דוגמא עם cat. בעת הפעלת cat, אנו נותנים לתוכנית ההפעלה את הקלט הסטנדרטי שלנו (STDIN - FD 0), המסומן בירוק, ובו המקרה "SOME INPUT" הוא. ברגע שאישרנו את הקלט באמצעות [ENTER], הוא מוחזר למסוף כפלט רגיל (STDOUT - FD 1), מסומן באדום.
                                        </span>
                                        <div className='para-after-image-stdin-stdout'></div>

                                    </p>

                                    <p>
                                        <b>STDOUT and STDERR</b>
                                        <span>
                                            בדוגמה הבאה, באמצעות פקודת ה- find, נראה את הפלט הסטנדרטי (STDOUT - FD 1) המסומן בירוק ושגיאה סטנדרטית (STDERR - FD 2) מסומנת באדום.
                                        </span>

                                        <div className='para-after-image-stderr'></div>
                                        <p>
                                            <span>
                                                במקרה זה, השגיאה מסומנת ומוצגת עם "הרשאה נדחית". אנו יכולים לבדוק זאת על ידי הפניית מתאר הקבצים לאיתור השגיאות (FD 2 - STDERR) ל "/ dev / null." בדרך זו, אנו מפנים את השגיאות שהתקבלו ל"התקן null ", אשר משליך את כל הנתונים.
                                            </span>
                                            <div className='para-after-image-stderr-redirect'></div>

                                        </p>
                                    </p>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 6
                                        let completedIndex = 36
                                        let subjectsAndSubTopicsCompletedIndex = 2

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
