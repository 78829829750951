import React, { Component } from 'react';
import './whonixconfiguration.css';
import companyLogo from './../../../../../assets/Images/800px-Whonix-concept-illustration.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';

export default class WhonixConfiguration extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper WhonixConfiguration">
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>הגדרת Whonix במכונה הוירטואלית</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        לאחר שהתקנת את ה - Whonix-Gateway ואת Whonix-Workstation וודא שבמכונה הוירטואלית של Whonix-Gateway הגדרות הרשת במתאמים הם:
                                    </span>
                                </p>
                                <div className='Whonix-gateway-Xfce-Setting'></div>
                                <p>
                                    <b>לאחר שהפעלת את Whonix-Gatway בטרמינל הקלד בצע את ההתקנה הבאה:</b>
                                    <ShellTerminal user="[gateway user ~]%" location="" cursor="sudo apt install geoip-bin" newline="[gateway user ~]%" />
                                    <span>לאחר ההתקנה נוכל להשתמש בפקודה geoiplookup</span>
                                </p>
                                <p>
                                    <b>נברר מה כתובת ה - IP שדרכה אנו מתקשרים</b>
                                    <ShellTerminal user="[gateway user ~]%" location="[~]" cursor="curl ip.me" newline="/home/moshe" />

                                </p>
                                <p >
                                    <b>נברר את מיקום כתובת ה - IP</b>
                                    <ShellTerminal user="[gateway user ~]%" location="[~]" cursor="geoiplookup 185.220.101.43" newline="GeoIP Country Edition: DE, Germany" />
                                    <span className='c13' style={{ textAlign: 'left' }}>GeoIP Country Edition: DE, Germany</span>
                                </p>

                                <p>
                                    <b></b>
                                </p>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>השתמש ב -  Whonix  עם Kali Linux</b>

                                    <p className="c5"><span className="c0"></span></p>
                                    <span className='c0' style={{ direction: 'rtl', textAlign: 'right' }}>
                                        השייכת למערת ההפעלה של קאלי  ב וירטואל בוקס  Network - בהגדרות ה
                                    </span>
                                </p>
                                <div className='whonix-virtualbox'></div>
                                <p>
                                    <span className='c0'>
                                        :  הגדר כך  eth1   בממשק  Whonix-Gateway  את ההגדרות שראינו ב
                                    </span>
                                </p>
                                <div className='whonix-virtualbox-eth1'></div>
                                <p>
                                    <span className='c0'>
                                        eth1 מ - ממשק Whonix-Gatway אז בעצם הגדרנו כתובת אייפי של 10.152.152.3 למכונת ה - קאלי, ואת מיסוך הרשת כפי שלקחנו מתוך ה-
                                    </span>
                                </p>
                                <p>
                                    <span className='c0'>
                                        שלנו Gateway  שהוא בעצם ה -  eth1  ואת כתובת ה - אייפי של הממשק
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontSize: '17pt' }}>
                                        אם תקליד בדפדפן של KALI את כתובת הבאה : https://dnsleaktest.com
                                        או curl ip.me תוכל לראות שאתה גולש דרך TOR!!!
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
