import React, { Component } from 'react';
import './shortcuts.css';
import companyLogo from './../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
export default class Shortcuts extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper shortcuts">
                                <h4></h4>
                              
                                <p>
                                    <b className='align-right'>קיצורי דרך</b>
                                    <span>
                                        ישנם קיצורי דרך רבים שבהם נוכל להשתמש כדי להפוך את העבודה עם לינוקס לקלה ומהירה יותר. לאחר שהכרנו את החשובים שבהם והפכנו אותם להרגל, נחסוך לעצמנו הקלדה רבה. חלקם אף יעזרו לנו להימנע משימוש בעכבר במסוף.
                                    </span>
                                    <span>
                                        השלמה אוטומטית
                                        <span className='bold green medium-text'> [TAB] </span>- יוזם השלמה אוטומטית. זה יציע לנו אפשרויות שונות המבוססות על ה- STDIN שאנו מספקים. אלו יכולות להיות הצעות ספציפיות כמו ספריות בסביבת העבודה הנוכחית שלנו, פקודות המתחילות באותו מספר תווים שכבר הקלדנו, או אפשרויות.
                                    </span>
                                </p>
                                <p>
                                    <b>Cursor Movement</b>
                                    <span className='block'>[CTRL] + A - <span className='normal black medium-text'> Move the cursor to the beginning of the current line.</span></span>
                                    <span className='block'>[CTRL] + E - <span className='normal black medium-text'> Move the cursor to the end of the current line.</span></span>
                                    <span className='block'>[CTRL] + [←] / [→] - <span className='normal black medium-text'> Jump at the beginning of the current/previous word.</span></span>
                                    <span className='block'> [ALT] + B / F - <span className='normal black medium-text'> Jump backward/forward one word.</span></span>

                                    <b>Erase The Current Line</b>
                                    <span className='block'>[CTRL] + U - <span className='normal black medium-text'>Erase everything from the current position of the cursor to the beginning of the line.</span></span>
                                    <span className='block'> [Ctrl] + K - <span className='normal black medium-text'> Erase everything from the current position of the cursor to the end of the line.</span></span>
                                    <span className='block'>[Ctrl] + W - <span className='normal black medium-text'> Erase the word preceding the cursor position.</span></span>

                                    <b>Paste Erased Contents</b>
                                    <span className='block'>   [Ctrl] + Y - <span className='normal black medium-text'> Pastes the erased text or word.</span></span>

                                    <b> Ends Task </b>
                                    <span className='block'>[CTRL] + C - <span className='normal black medium-text'> Ends the current task/process by sending the SIGINT signal. For example, this can be a scan that is run by a tool. If we are watching the scan, we can stop it / kill this process by using this shortcut. </span></span>

                                    <b> End-of-File (EOF) </b>
                                    <span className='block'> [CTRL] + D - <span className='normal black medium-text'> Close STDIN pipe that is also known as End-of-File (EOF) or End-of-Transmission.</span></span>

                                    <b> Clear Terminal</b>
                                    <span className='block'> [CTRL] + L - <span className='normal black medium-text'> Clears the terminal. An alternative to this shortcut is the clear command you can type to clear our terminal.</span></span>

                                    <b> Background a Process </b>
                                    <span className='block'> [CTRL] + Z - <span className='normal black medium-text'> Suspend the current process by sending the SIGTSTP signal.</span></span>

                                    <b> Search Through Command History</b>

                                    <span className='block'> [CTRL] + R - <span className='normal black medium-text'> Search through command history for commands we typed previously that match our search patterns.</span></span>
                                    <span className='block'> [↑] / [↓] - <span className='normal black medium-text'> Go to the previous/next command in the command history.</span></span>

                                    <b> Switch Between Applications </b>
                                    <span className='block'> [ALT] + [TAB] - <span className='normal black medium-text'> Switch between opened applications.</span></span>

                                    <b> Zoom </b>
                                    <span className='block'>   [CTRL] + [+] - <span className='normal black medium-text'> Zoom in.</span></span>
                                    <span className='block'> [CTRL] + [-] - <span className='normal black medium-text'> Zoom out.</span></span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 12
                                        let completedIndex = 65
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>   
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
