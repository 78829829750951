import React, { useState } from "react";
import './module.css';
import { useNavigate } from "react-router-dom";


import ProgressBar from "./../ProgressBar/ProgressBar";
import { Consumer } from "../../../Context/";
import Button from '@mui/material/Button';

export default function Module(props) {

    const navigate = useNavigate()

    let state = {
        topic: props.topic,
        status: props.status,
        index: props.index,
        subIndex: props.subIndex,
        numberOfSubTopics: props.numberOfSubTopics,
        completedSubTopics: props.completedSubTopics
    }



    const gotToNewPage = () => {
        // console.log('goto');
        navigate("/topic-viewer");
    }

    return (
        <Consumer>
            {
                c => {
                    // console.log(state.topic);
                    let subTopicsArray = c.subjectsAndSubTopics.filter(i => i.Course == state.topic)
                    // console.log(c.subjectsAndSubTopics)
                    // console.log(subTopicsArray);//numberOfSubTopics
                    const initialValue = 0;
                    const numberOfSubTopics = subTopicsArray.reduce((accumulator, currentValue) => accumulator + currentValue.completed.length, initialValue)
                    const finishedSubTopics = subTopicsArray.reduce((accumulator, currentValue) => accumulator + (currentValue.completed.filter(i => i != '').length), initialValue)
                    const currentProgress = `finished ${finishedSubTopics} / ${numberOfSubTopics}`
                   

                    return (
                        <div className="cube-wrapper-div">
                            <div className={`module-container ${state.topic}`} data-topic={state.topic} data-topic-index={state.index} data-topic-subindex={state.subIndex}>
                            </div>
                            {
                                (state.status == '') ? (
                                    <div className={`subject-cube-footer`} data-topic={state.topic} data-topic-index={state.index} data-topic-subindex={state.subIndex}>
                                        <span className="subject-cube-status">{state.status}</span>
                                        <span className="progressbar-span"><ProgressBar numberOfSubTopics={props.numberOfSubTopics} completedSubTopics={props.completedSubTopics} /></span>
                                        <span className="finished-topics">{currentProgress}</span>
                                        <Button variant="contained" onClick={(e) => {
                                            c.actions.setChoosenCourse(state.topic, state.topicIndex)
                                            c.actions.setChoosenCourseSubIndex(state.subIndex)
                                            c.actions.setSubTopicNew(state.topic)
                                            let docsSubjectAndTopics = c.subjectsAndSubTopics.filter((item, index) => item.section == 'documentation' && item.subIndex == state.subIndex)

                                            let itemIdArray = []
                                            docsSubjectAndTopics.map((item, index) => {
                                                item.subTopics.map((i, index) => {
                                                    let itemId = `${i}${index}`
                                                    itemIdArray.push(itemId)
                                                })
                                            })
                                            c.actions.setItemIdArray(itemIdArray)
                                            // console.log(itemIdArray);
                                            gotToNewPage()
                                        }}>View</Button>

                                    </div>
                                ) : (<div className={`subject-cube-footer`} data-topic={state.topic} data-topic-index={state.index} data-topic-subindex={state.subIndex}><span className="subject-cube-status">{state.status}</span><br/></div>)
                            }
                        </div>

                    )

                }
            }
        </Consumer >
    )

}