import React, { Component } from "react";
import './dashboard.css';

import VerticalMenu from "../VerticalMenu/VerticalMenu";
import ModuleBoard from "../Modules/ModuleBoard/ModuleBoard";

import { NavLink } from "react-router-dom";
import { Menu, MenuItem, Link } from '@mui/material'
import { Consumer } from "../../Context";

export default class Dashboard extends Component {


    render() {

        <Consumer>
            {
                c => {
                    c.actions.resetComponentName()
                }
            }
        </Consumer>
        return (

            <div id='main-dashboard'>

                <VerticalMenu />
                <ModuleBoard />


            </div>

        )
    }



}