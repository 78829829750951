import React from 'react';
import './shellterminal.css';
import { Consumer } from './../../../../../Context';
export default function ShellTerminal(props) {

    return (
        <div className='shell-terminal'>
            <main id="container">
                <div id="terminal">

                    <section id="terminal__bar">
                        <div id="bar__buttons">
                            <button className="bar__button" id="bar__button--exit">&#10005;</button>
                            <button className="bar__button">&#9472;</button>
                            <button className="bar__button">&#9723;</button>
                        </div>
                        <p id="bar__user">webschool26@ubuntu: ~</p>
                    </section>

                    <section id="terminal__body">
                        <div id="terminal__prompt">
                            <span id="terminal__prompt--user">{props.user}</span>
                            <span id="terminal__prompt--location">{props.location}</span>
                            <span id="terminal__prompt--bling">$</span>
                            <span id="terminal__prompt--cursor">{props.cursor}</span>
                        </div>
                        <div id="terminal-result-container">
                            <p className="terminal__result">{(props.result != null) ? props.result : ''}</p>
                            <div class="break"></div>
                            <p className="terminal__result" style={{ backgroundColor: (props.newlineResultBG1 != null) ? props.newlineResultBG1 : '', color: (props.textColor1 != null) ? props.textColor1 : '', width: (props.resultWidth1 != null) ? props.resultWidth1 : '', marginLeft: (props.marginLeft1 != null) ? props.marginLeft1 : '', paddingLeft: (props.paddingLeft1 != null) ? props.paddingLeft1 : '' }}>{(props.newlineResultCol1 != null) ? props.newlineResultCol1 : ''}</p>
                            <p className="terminal__result" style={{ backgroundColor: (props.newlineResultBG2 != null) ? props.newlineResultBG2 : '', color: (props.textColor2 != null) ? props.textColor2 : '', width: (props.resultWidth2 != null) ? props.resultWidth2 : '', marginLeft: (props.marginLeft2 != null) ? props.marginLeft2 : '' , paddingLeft: (props.paddingLeft2 != null) ? props.paddingLeft2 : ''}}>{(props.newlineResultCol2 != null) ? props.newlineResultCol2 : ''}</p>
                            <p className="terminal__result" style={{ backgroundColor: (props.newlineResultBG3 != null) ? props.newlineResultBG3 : '', color: (props.textColor3 != null) ? props.textColor3 : '', width: (props.resultWidth3 != null) ? props.resultWidth1 : '', marginLeft: (props.marginLeft3 != null) ? props.marginLeft3 : '' , paddingLeft: (props.paddingLeft3 != null) ? props.paddingLeft3 : ''}}>{(props.newlineResultCol3 != null) ? props.newlineResultCol3 : ''}</p>
                        </div>

                    </section>
                </div>
            </main>
        </div>
    )

}
