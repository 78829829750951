import React, { Component } from 'react';
import './regexcompnent.css';
import { Alert, AlertTitle } from '@mui/material';

import MatchSpans from './MatchSpans';
import { Consumer } from "./../../../../Context/";
export default class RegExCompnent extends Component {

    constructor(props) {

        super(props)
        this.state = {
            patternIndex: props.patternIndex,
        }
    }

    componentDidMount = () => {


        const txtInputString4 = document.getElementById(`txtInputString4-${this.state.patternIndex}`)
        txtInputString4.focus()
        // console.log('componentDidMount',txtInputString4);

        const result4 = document.getElementById('result4')
        // console.log(result4);
        // console.log(`RegExCompnent  patternIndex ${this.state.patternIndex}`);
    }



    render() {



        return (
            <Consumer>
                {
                    c => {
                        let matchSpans = ''
                        const checkRegEx = (inputStringParam, patternParam) => {

                            try {
                                if (c.RegEx[this.state.patternIndex].pattern != '') {
                                    const regex = RegExp(c.RegEx[this.state.patternIndex].pattern, 'g');

                                    let charecters, pattern, inputString, numberOfMatheces;
                                    pattern = (patternParam != '') ? patternParam : document.getElementById('txtRegexPattern4').value;
                                    inputString = (inputStringParam != '') ? inputStringParam : '';
                                    numberOfMatheces = [...inputString.matchAll(c.RegEx[this.state.patternIndex].pattern)].length;

                                    // console.log(regex, inputString);
                                    // debugger
                                    while ((charecters = regex.exec(inputString)) !== null) {
                                        matchSpans += `${charecters[0]},`
                                    }

                                    const regExObj = { pattern: pattern, inputString: inputString, numberOfMatheces: numberOfMatheces, matchSpans: matchSpans }
                                    c.actions.setRegEx(regExObj, this.state.patternIndex)
                                }
                            } catch (err) {
                                return (
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        This is an error Alert with a scary title.
                                    </Alert>)
                            }


                        }


                        return (
                            <div className="regexcomponent" style={{ border: '1px solid #808080' }}>
                                <div className="mb-1">
                                    <label htmlFor="txtRegexPattern" className="form-label" style={{ width: '130px' }} >Regex Pattern:</label>
                                    <div className="textbox-container">
                                        <span className="slash">/</span>

                                        <input className="form-input patternbox" id="txtRegexPattern4" name="RegexPattern" placeholder="Enter regex pattern..." style={{ width: '100%' }} type="text" defaultValue={c.RegEx[this.state.patternIndex].pattern}
                                            onKeyUp={(e) => {
                                                let pattern, inputString, numberOfMatheces, matchSpans
                                                pattern = document.getElementById('txtRegexPattern4').value;

                                                inputString = c.RegEx[this.state.patternIndex].inputString
                                                numberOfMatheces = c.RegEx[this.state.patternIndex].numberOfMatheces
                                                matchSpans = c.RegEx[this.state.patternIndex].matchSpans

                                                const regExObj = { pattern: pattern, inputString: inputString, numberOfMatheces: numberOfMatheces, matchSpans: matchSpans }
                                                c.actions.setRegEx(regExObj, this.state.patternIndex)

                                            }}
                                        />
                                        <span className="slash">/g</span>
                                    </div>
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="txtInputText" className="form-label" style={{ width: '130px' }} >Input String:</label>

                                    <input className="form-input" id={`txtInputString4-${this.state.patternIndex}`} name="InputString" placeholder="Enter input text..." style={{ width: '78%' }} type="text" defaultValue={c.RegEx[this.state.patternIndex].inputString}
                                        onKeyUp={(e) => { checkRegEx(e.target.value, c.RegEx[this.state.patternIndex].pattern) }}

                                        onFocus={(e) => { checkRegEx(e.target.value, c.RegEx[this.state.patternIndex].pattern) }}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="noofmatches" className="form-label" style={{ width: '130px' }}>No of Match:</label>

                                    <label className="form-input" htmlFor="noofmatches" id="noofmatches4" style={{ width: '78%' }}>{c.RegEx[this.state.patternIndex].numberOfMatheces} </label>
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="result" className="form-label" style={{ width: '130px' }}>Match:</label>

                                    <label className="form-input" htmlFor="result" id="result4" style={{ width: '78%' }}>{<MatchSpans key={React.key} patternIndex={this.state.patternIndex} />}</label>
                                </div>
                            </div>

                        )


                    }
                }
            </Consumer >)
    }

}
