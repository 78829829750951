import React, { Component } from "react";
import './moduleboard.css';

import Module from "../Module/Module";
import { Consumer } from "../../../Context";

export default class ModuleBoard extends Component {


    render() {
        return (

            <Consumer>

                {
                    c => {




                        return (

                            <div className="module-board-container">
                                {
                                    c.selectedMenuItemsCubes.map((item, index) => {

                                        let docsSubjectAndTopics = c.subjectsAndSubTopics.filter((i, index) => i.section === 'documentation' && i.subIndex == item.subIndex)

                                        let numberOfSubTopics = 0
                                        let completedSubTopics = 0

                                        docsSubjectAndTopics.map((item, index) => {
                                            item.completed.map(i => {
                                                if (i != "")
                                                    completedSubTopics++;

                                                numberOfSubTopics++;
                                            })

                                        })

                                        // console.log(numberOfSubTopics, completedSubTopics);


                                        return (<Module key={item.subject} topic={item.subject} status={item.status} index={item.index} subIndex={item.subIndex} numberOfSubTopics={numberOfSubTopics} completedSubTopics={completedSubTopics} />)

                                    }

                                    )
                                }

                            </div>

                        )

                    }
                }
            </Consumer >
        )
    }
}