import React, { Component } from 'react';
import './characters.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import RegExCompnent from '../RegExCompnent/RegExCompnent';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class Characters extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        return (
                            <div className="paper characters" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>Characters in Regular Expression</b>
                                    <span>
                                        בתבנית הביטוי הרגולרי, תו הוא כל דבר מאות בודדת באלפבית ועד ספרה מספרית שברצונך לחפש. זהו הרכיב הפשוט ביותר של דפוס הרגאקס.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        כלול את המילים או תו בודד בתבנית שאתה מחפש בטקסט.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        הדוגמה הבאה בודקת אם מחרוזת מכילה את התו שצוין או לא:
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="11" />
                                <p>
                                    <span>
                                        בדוגמה שלמעלה, תבנית הביטוי הרגולרית מכילה תו יחיד "H" שצריך לחפש במחרוזת הקלט "Hello World!". מכיוון שמחרוזת הקלט מכילה "H", הביטוי הרגולרי יתאים ל-"H".
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        הטקסט הבא מחפש רצף של מספר תווים:
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="12" />
                                <RegExCompnent key={React.key} patternIndex="13" />
                                <RegExCompnent key={React.key} patternIndex="14" />
                                <p>
                                    <span>
                                        שים לב שהביטוי הרגולרי הוא תלוי רישיות כברירת מחדל ברוב שפות התכנות.
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="15" />

                                <p>
                                    <b>חפש דמויות מיוחדות</b>
                                    <span>Regex תומך בתווים המיוחדים הבאים שניתן לכלול כתווים:</span>
                                </p>
                                <p className='list-items'>
                                    <b></b>
                                    <span>newline '\n'</span>
                                    <span>carriage return '\r'</span>
                                    <span>tab '\t'</span>
                                    <span>Whitespace '\s'</span>
                                    <span>Null character '\0'</span>
                                    <span>Form feed '\f'</span>
                                    <span>ASCII character \nnn</span>

                                </p>

                                <p>
                                    <span>
                                        החיפושים הבאים אחר הרווח הלבן במחרוזת:
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="16" />
                                <p>
                                    <b>דמויות בריחה</b>
                                    <span>
                                        לחלק מהדמויות יש משמעות מיוחדת ב-Regex, כגון . $ ^  &#123; [ ( | ) * + ? \. מלבד התווים הללו, לביטויים רגילים אין משמעות מיוחדת; הם מתאימים לעצמם. תוכלו ללמוד על הדמויות הללו בפרקים הבאים.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        עם זאת, לפעמים תרצה לכלול תו מיוחד אחד או יותר ללא משמעות מיוחדת ב-Regex. לדוגמה, ייתכן שתרצה לחפש $ בטקסט אך לא תרצה להתייחס ל-$ כתו מיוחד. לשם כך, עליך להשתמש בקו הנטוי \ כדמות בריחה.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        לדוגמה, הדבר הבא מוצא תו $ במחרוזת הקלט:
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="17" />
                                <p>
                                    <span>
                                        הטבלה הבאה מפרטת את התוצאה של חיפוש ביטויים רגילים של תבניות שונות במחרוזות קלט שונות:
                                    </span>
                                </p>
                                <div id="linux-components-characters">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תבנית ביטוי רגולרי </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}>מחרוזת הבדיקה</TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}>התאמה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "He" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "Hello World!" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "<span className='match'>He</span>llo World!" </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "he" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "Hello World!" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "<span className='match'></span>"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "Helo" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "Hello World!" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "<span className='match'></span>"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "Wor" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> "Hello World!"  </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "Hello<span className='match'>Wor</span>ld!"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "orld" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "Hello World!" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "Hello W<span className='match'>orld</span>!"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "\tW" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "Hello World" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "Hello<span className='match'>W</span>orld"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "abd" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "abcd efg hi" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "<span className='match'></span>"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "b" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> "abcd efg hi"  </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "a<span className='match'>b</span>cd efg hi"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "cde" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "abcd efg hi" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "<span className='match'></span>"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "cd\se" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  "abcd efg hi" </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "ab<span className='match'>cd e</span>fg hi"</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "1" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> "A123-4B31"  </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "A<span className='match'>1</span>23-4B3<span className='match'>1</span> "</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > "4-" </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> "A123-4B31"  </TableCell>
                                                <TableCell align="center" style={{ color: '#e94c4cde' }}>  "A123<span className='match'>4-</span>B31"</TableCell>
                                            </TableRow>

                                        </TableBody>

                                    </Table>
                                </div>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 1
                                    let subjectsAndSubTopicsCompletedIndex = 0
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare

                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, subjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
