import React, { Component } from 'react';
import './matchspans.css';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import parse from 'html-react-parser';

import { Consumer } from "./../../../../Context/";
export default class MatchSpans extends Component {
    constructor(props) {

        super(props)
        
        this.state = {
            patternIndex: props.patternIndex,
        }
    
    }
    componentDidMount() {

        // this.forceUpdate() 
    }

    render() {


        return (
            <Consumer>
                {
                    c => {
                        let htmlObject
                        if (c.RegEx[this.state.patternIndex].matchSpans != '') {
                            const spansMathesArray = c.RegEx[this.state.patternIndex].matchSpans.split(',');
                            spansMathesArray.pop()
                            let inputString = c.RegEx[this.state.patternIndex].inputString;
                            let haystack = inputString.split("")
                            let newHaystack = [];

                            let allMatches, inputStringStack = []
                            
                            try {
                                allMatches = [...inputString.matchAll(c.RegEx[this.state.patternIndex].pattern)]
                            } catch (e) {
                               
                                return (<span></span>)
                            }



                            let i = 0 // this should be outside the map, because the next check should be after the [] index


                            // debugger

                            allMatches.map((str, index) => {

                                let needle = str[0];
                                let needleLength = str[0].length;
                                let needleArray = needle.split("")


                                while (haystack.length > 0) {


                                    let foundCounter = 0;
                                    let needlePointer = 0;

                                    for (; needlePointer < needleLength; needlePointer++) {

                                        if (needleArray[needlePointer] == haystack[needlePointer] && haystack.length >= needleArray.length)
                                            foundCounter++;
                                        else
                                            break;
                                    }
                                    //Match: put []
                                    // debugger
                                    if (foundCounter == needleLength) {

                                        let lastClosingBracketIndex = newHaystack.length
                                        newHaystack.splice(lastClosingBracketIndex + 1, 0, '[')

                                        for (let x = 0; x < needlePointer; x++)
                                            newHaystack.push(haystack.shift())


                                        newHaystack.splice(lastClosingBracketIndex + needlePointer + 2, 0, ']')

                                        break;
                                    } else {

                                        for (let x = 0; x <= needlePointer; x++)
                                            newHaystack.push(haystack.shift())

                                    }


                                }

                                // maybe the matches where at the begining and their are still characters in the haystack, adding them
                                let finelStack = [...newHaystack, ...haystack]
                                // console.log(finelStack);


                                // we need to find the next match after the ] bracket, so we need to cut the array until ]

                                let htmlHaystack = finelStack.map((item, index) => {
                                    if (item == '[')
                                        return '<span class="match">'
                                    else if (item == ']')
                                        return '</span>'
                                    else if (item != ',')
                                        return item
                                })
                                htmlHaystack = htmlHaystack.join(" ");
                                htmlObject = { __html: htmlHaystack };
                               
                            }

                            )

                            return (
                                <div>
                                    {parse(htmlObject.__html) }
                                </div>
                            )
                        } else {
                            return (<div></div>)
                        }

                    }
                }
            </Consumer>)
    }

}
