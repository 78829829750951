import React, { Component } from 'react';
import './wpscan.css';
import companyLogo from './../../../../../../assets/Images/WPScan.png';
import { Consumer } from "../../../../../Context";

import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class WPScan extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper WPScan" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>WPScan</b>
                                </p>


                                <p className="c9">
                                    <span className="c4">
                                        כדי להבין את הכלי הזה, יהיה צורך להבין מהי וורדפרס! מה זה וורדפרס? וורדפרס היא מערכת ניהול תוכן (CMS) המאפשרת לארח ולבנות אתרים. וורדפרס מכילה ארכיטקטורת תוספים ומערכת תבניות, כך שתוכל להתאים אישית כל אתר כך שיתאים לעסק, לבלוג, לתיק העבודות או לחנות המקוונת שלך.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>


                                <p className="c9">
                                    <span className="c4">
                                        וורדפרס משתמשת בתוספים, תוסף וורדפרס הוא תוכנה ש"מתחברת" לאתר הוורדפרס שלך. תוספים יכולים להוסיף פונקציונליות חדשה או להרחיב את הפונקציונליות הקיימת באתר שלך, ולאפשר לך ליצור כמעט כל סוג של אתר אינטרנט, מחנויות מסחר אלקטרוני ועד תיקים לאתרי ספריות.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c4">
                                        זכור שאף תוסף אינו מאובטח ב-100%. עם זאת, ישנם כמה צעדים פשוטים שתוכלו לנסות למנוע מהתוספים של וורדפרס להידבק בתוכנה זדונית.
                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>


                                <p className="c9">
                                    <span className="c4">
                                        בעזרת WPScan, זה כמו שיש לך צוות משלך של מומחי אבטחה וורדפרס!
                                        היה הראשון לדעת על נקודות תורפה המשפיעות על התקנת וורדפרס, התוספים והנושאים שלך.
                                    </span>
                                </p>
                                <p className="c9 links image-on-page-wpscan-website">
                                    <a href="https://wpscan.com/" target='_blank'>https://wpscan.com/</a>
                                </p>

                                <p className="c5"><span className="c0"></span></p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 21
                                    let completedIndex = 8
                                    let subjectsAndSubTopicsCompletedIndex = 3
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
