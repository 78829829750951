import React, { Component } from 'react';
import './vpn.css';
import companyLogo from './../../../../../assets/Images/vpn1.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
export default class VPN extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {

                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper VPN" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>רשת וירטואלית פרטית</b>
                                </p>

                                <p className="c9">
                                    <span className="c4">
                                        אם אתה רוצה לבצע מתקפת סייבר, אתה מתחיל לסרוק את היעד שלך, ואתה לומד עליו מספר דברים, אבל הוא גם לומד עלייך, בכל פעולת סריקה יש את המידע של כתובת ה IP של מי שביצע את הסריקה (&lt;From IP Address&gt;)
                                    </span>
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <p className="c9">
                                    <span className="c4">
                                        אם אתה רוצה להיות אנונימי כשאתה סורק אתר אינטרנט, או כשאתה פורץ, אתה יכול לעשות זאת עם VPN.
                                    </span>
                                </p>

                                <p className="c9">
                                    <b style={{fontSize:'17pt'}}>מה המטרה של VPN?</b>
                                    <span className="c4">
                                        המטרה העיקרית של VPN היא להסתיר את הפעילות המקוונת שלך. לעתים קרובות משתמשים ב-VPN כדי להגן מפני האקרים וחטטנות ברשתות ציבוריות, אך הם גם שימושיים להסתרת כתובת ה-IP שלך, פעילות גלישה ונתונים אישיים בכל רשת Wi-Fi - אפילו בבית
                                    </span>
                                </p>

                                <p className='c9 list-items VPN'>
                                    <b style={{fontSize:'17pt'}}>8 VPN המומלצים לאנשי סייבר</b>
                                    <span style={{ fontSize: '17pt' }}><a href="https://www.expressvpn.com/" target="_blank">ExpressVPN - Our choice.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://nordvpn.com/" target="_blank">NordVPN -  5,200 servers located in 59 countries.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://surfshark.com/" target="_blank">Surfshark - Powerful security.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://privatevpn.com/" target="_blank">PrivateVPN - Cheap option.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://www.cyberghostvpn.com/" target="_blank">CyberGhost -  Dedicated IPs.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://www.ipvanish.com/" target="_blank">IPVanish - Fast connections.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://www.purevpn.com/" target="_blank">PureVPN - Accepts Bitcoins.</a></span>
                                    <span style={{ fontSize: '17pt' }}><a href="https://www.vyprvpn.com/" target="_blank">VyprVPN - Zero logs.</a></span>
                                </p>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 18
                                    let completedIndex = 7
                                    let subjectsAndSubTopicsCompletedIndex = 7
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
