import React, { Component } from 'react';
import './entities.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Consumer } from "./../../../../../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class Entities extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper ugo">
                                <div className='ugo-result'>
                                    <h4>3 הישויות עם ההרשאות שיש להן</h4>
                                    {/* <div className="img-div"><img src={companyLogo} /></div>  */}
                                    <p>

                                        <span>
                                            אם תפתח את הטרמינל ותריץ את הפקודה <span className='bold green medium-text'>ls -l</span>, תוכל לראות את רשימת הקבצים והתיקיות במיקומך הנוכחי, ממש כפי שהיית פותח סייר קבצים, אבל הפעם באופן מפורט!
                                        </span>
                                        <span>
                                        כאשר אתה מתקין את מערכת ההפעלה של לינוקס, אתה יוצר שם משתמש וסיסמה, דע לך שגם נוצרת קבוצה עם שם המשתמש שיצרת.
                                        אז היישות הראשונה היא המשתמש ( בעלי הקבצים ),  היישות השנייה היא הקבוצה שאליה הקבצים או התיקיות שייכים, היישות השלישית היא משתמשים אחרים
                                        </span>
                                    </p>

                                </div>
                                <div className='ugo para-after-image'>
                                    
                                    <p>
                                        תחת לינוקס, הרשאות מוקצות למשתמשים וקבוצות. כל משתמש יכול להיות חבר בקבוצות שונות, וחברות בקבוצות אלה נותנת למשתמש הרשאות ספציפיות נוספות. כל קובץ וספרייה שייכים למשתמש ספציפי ולקבוצה ספציפית. אז ההרשאות למשתמשים וקבוצות שהגדירו קובץ מוגדרות גם עבור הבעלים בהתאמה. כשאנחנו יוצרים קבצים או ספריות חדשים, הם שייכים לקבוצה שאליה אנו שייכים. כל מערכת ההרשאות במערכות לינוקס מבוססת על מערכת המספרים האוקטאלית, ובעצם, ישנם שלושה סוגים שונים של הרשאות שניתן להקצות לקובץ או לספרייה:

                                        <span className='ltr'> (<span className='bold green medium-text'>r</span>) - Read </span>
                                        <span className='ltr'> (<span className='bold green medium-text'>w</span>) - Write</span>
                                        <span className='ltr'>(<span className='bold green medium-text'>x</span>) - Execute</span>

                                    </p>
                                    </div>
                                    <div className='linux-permisssions-image'>

                                    </div>
                                    <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 4
                                        let completedIndex = 18
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
