import React, { Component } from 'react';
import './backgroundaprocess.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class BackgroundAProcess extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper  process-background">
                                <h4>Background a Process</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>העבר תהליך למאחורי הקלעים</b>
                                    <span>
                                        לפעמים יהיה צורך לשים את הסריקה או התהליך שהתחלנו זה עתה ברקע כדי להמשיך ולהשתמש בטרמינל לצורך נוסף. כפי שכבר ראינו, אנו יכולים לעשות זאת באמצעות קיצור דרך [Ctrl + Z]. כפי שהוזכר לעיל, אנו שולחים את אות ה- SIGTSTP לליבה, שמשהה את התהליך.
                                    </span>

                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="ping -c www.webschool26.com" />
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="vim tempfile" />
                                    <p >
                                        <span className='ltr'>
                                            [Ctrl + Z]
                                        </span>
                                        <span className='ltr'>
                                            [2]+  Stopped                 vim tmpfile
                                        </span>
                                    </p>
                                    <b>jobs</b>
                                    <span>כעת ניתן להציג את כל תהליכי הרקע באמצעות הפקודה הבאה.</span>

                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="jobs" />
                                    <p >
                                        <span className='ltr'>
                                            [1]+  Stopped                 ping -c 10 www.webschool26.com
                                        </span>
                                        <span className='ltr'>
                                            [2]+  Stopped                 vim tmpfile
                                        </span>
                                    </p>

                                    <b>bg</b>
                                    <span>קיצור הדרך [Ctrl] + Z משעה את התהליכים והם לא יבוצעו בהמשך. כדי לשמור על פעולתו ברקע, עלינו להזין את הפקודה bg כדי לשים את התהליך ברקע.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="bg" />

                                    <b>&</b>
                                    <span>אפשרות נוספת היא להגדיר אוטומטית את התהליך עם סימן AND (&) בסוף הפקודה.</span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="ping -c 13 www.webschool26.com &" />


                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 10
                                        let completedIndex = 60
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
