import React, { Component } from 'react';
import './nmapcheatsheet.css';
import companyLogo from './../../../../../../../assets/Images/nmap26.png';
import { Consumer } from "../../../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export default class NMAPCheatSheet extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {


                        return (
                            <div className="paper NMAPCheatSheet" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>

                                <p>
                                    <b style={{ fontSize: "17pt", color: "#15aebd" }}></b>
                                </p>


                                <p className="c9">
                                    <span className="c4">

                                    </span>
                                </p>


                                <p className="c5"><span className="c0"></span></p>

                                <div id="">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> אפשרויות סריקה של nmap</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > טווח רשת יעד</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  10.10.10.0/24 </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > משבית את סריקת היציאות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sn</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >משבית את ICMP Echo Requests </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -Pn</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >משבית את רזולוציית DNS. </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -n</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מבצע את סריקת הפינג באמצעות ICMP Echo Requests כנגד המטרה. </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -PE</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג את כל החבילות שנשלחו והתקבלו</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>--packet-trace </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג את הסיבה לתוצאה ספציפית.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --reason</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > משבית בקשות פינג ARP.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>--disable-arp-ping </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סורק את היציאות העליונות שצוינו שהוגדרו כ תכופות ביותר.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --top-ports=&lt;num&gt;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סרוק את כל היציאות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -p-</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סרוק את כל היציאות בין 22 ל-110</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>-p22-110 </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סורק רק את היציאות 22 ו-25 שצוינו.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -p22,25</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סורק את 100 היציאות המובילות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -F</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע TCP SYN-Scan.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sS</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע TCP ACK-Scan</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sA</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע סריקת UDP</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sU</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > סורק את השירותים שהתגלו לאיתור הגרסאות שלהם.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sV</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > בצע סריקת Script עם סקריפטים המסווגים כ"ברירת מחדל".</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -sC</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע סריקת סקריפט באמצעות הסקריפטים שצוינו</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --script &lt;script&gt;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע סריקת זיהוי מערכת הפעלה כדי לקבוע את מערכת ההפעלה של היעד</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>-O </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מבצע זיהוי מערכת הפעלה, זיהוי שירות וסריקות עקבות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>-A </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מגדיר את מספר ה-Decoys האקראיים שישמשו לסריקת המטרה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -D RND:5</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מציין את ממשק הרשת המשמש לסריקה. </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -e</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מציין את כתובת ה-IP המקור עבור הסריקה </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>-S 10.10.10.200 </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציין את יציאת המקור עבור הסריקה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -g</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > פתרון DNS מבוצע באמצעות שרת שמות שצוין.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --dns-server &lt;ns&gt;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>

                                <div id="nmap-cheet-2">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> אפשרויות פלט של nmap</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מאחסן את התוצאות בכל הפורמטים הזמינים החל בשם "שם קובץ".</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -oA filename </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מאחסן את התוצאות בפורמט רגיל בשם "שם קובץ".</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -oN filename </TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מאחסן את התוצאות בפורמט "ניתן לתפוס" עם השם של "שם קובץ". </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -oG filename</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מאחסן את התוצאות בפורמט XML עם השם "שם קובץ".</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -oX filename</TableCell>
                                            </TableRow>
                                        </TableBody>

                                    </Table>
                                </div>

                                <div id="nmap-cheet-3">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> אפשרויות הביצועים של nmap</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מגדיר את מספר הניסיונות החוזרים לסריקות של יציאות ספציפיות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --max-retries &lt;num&gt; </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מציג את מצב הסריקה כל 5 שניות. </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --stats-every=5s </TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מציג פלט מילולי במהלך הסריקה</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  -v/-vv</TableCell>
                                            </TableRow>
                                        </TableBody>

                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מגדיר את ערך הזמן שצוין כפסק זמן RTT ראשוני.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --initial-rtt-timeout 50ms </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מגדיר את ערך הזמן שצוין כפסק זמן מקסימלי של RTT.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> --max-rtt-timeout 100ms </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מגדיר את מספר החבילות שיישלחו בו זמנית </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  --min-rate 300</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableBody>
                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מציין את תבנית התזמון הספציפית </TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}> -T &lt;0-5&gt; </TableCell>
                                            </TableRow>
                                        </TableBody>

                                    </Table>
                                </div>

                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 22
                                    let completedIndex = 16
                                    let subjectsAndSubTopicsCompletedIndex = 6
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
