import React, { Component } from 'react';
import './applications.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { Consumer } from "./../../../../../Context/";
export default class Applications extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column">
                                <h4> תוכניות שונות לניהול חבילות</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>

                                    <span>
                                        אם תוכנה מותקנת נמחקה, מערכת ניהול החבילות לוקחת מחדש את פרטי החבילה, משנה אותה בהתבסס על תצורתה ומוחקת קבצים. ישנן תוכניות שונות לניהול חבילות בהן אנו יכולים להשתמש לצורך כך. הנה רשימה של דוגמאות לתוכניות כאלה:
                                    </span>
                                </p>

                                <div id="linux-components">
                                    <Table className="styled-table" style={{ width: '72%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                <TableCell align="center" style={{ width: '31%' }}> פקודה</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > ה- dpkg הוא כלי להתקנה, בנייה, הסרה וניהול של חבילות דביאן. החזית העיקרית הידידותית יותר לשימוש ב- dpkg היא יכולת</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  dpkg</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >  מספקת ממשק שורת פקודה ברמה גבוהה למערכת ניהול החבילות</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  apt </TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >מהווה חלופה ל- apt והוא ממשק ברמה גבוהה למנהל החבילות</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  aptitude</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > התקן, הגדר, רענן והסר חבילות הצמד. הצמדות מאפשרות הפצה מאובטחת של האפליקציות והשירותים האחרונים עבור הענן, השרתים, שולחנות העבודה ואינטרנט הדברים.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  snap</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > מנהל החבילות הסטנדרטי של רובי</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  gem</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} > Pip הוא מתקין חבילות Python המומלץ להתקנת חבילות Python שאינן זמינות בארכיון דביאן. זה יכול לעבוד עם מאגרי בקרת גרסאות (כרגע רק מאגרי Git, Mercurial ו- Bazaar), יומני פלט בהרחבה ומונעים התקנות חלקיות על ידי הורדת כל הדרישות לפני תחילת ההתקנה.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  pip</TableCell>
                                            </TableRow>

                                        </TableBody>
                                        <TableBody>

                                            <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                <TableCell align="center" style={{ color: '#7dacb5' }} >Git היא מערכת בקרת גרסאות מהירה ומרחבת ומבוזרת עם מערכת פקודות עשירה באופן יוצא דופן המספקת גם פעולות ברמה גבוהה וגם גישה מלאה לפנימיות.</TableCell>
                                                <TableCell align="center" style={{ color: '#93E304' }}>  git</TableCell>
                                            </TableRow>

                                        </TableBody>


                                    </Table>
                                </div>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 9
                                        let completedIndex = 56
                                        let subjectsAndSubTopicsCompletedIndex = 1

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>
                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
