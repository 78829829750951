import React, { Component } from 'react';
import './quizer.css';
import companyLogo from './../../assets/Images/regex.png';
import { Consumer } from "./../Context/";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Questionnaire from '../Questionnaire/Questionnaire';
export default class Quizer extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {
                        let nextsubTopicIndex = parseInt(c.subTopicIndex);
                        let theSubjectsAndSubTopicsIndex = c.subjectsAndSubTopicsIndex;
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex
                        // console.log('Hello', TheSubjectsAndSubTopicsIndex, 'next', nextsubTopicIndex);
                        return (
                            <div className="paper quizer" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>Quizer</b>
                                    <span>
                                        <Questionnaire />
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = theSubjectsAndSubTopicsIndex

                                    let completedIndex = nextsubTopicIndex;
                                    // console.log('completedIndex', completedIndex);
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex 

                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    // console.log('obj', obj, obj.completed[subjectsAndSubTopicsCompletedIndex]);
                                    // console.log(obj);
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare

                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
