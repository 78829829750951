import React, { Component } from 'react';
import './characterclasses.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import ShellTerminal from '../../Linux/Shell/ShellTerminal/ShellTerminal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { Consumer } from "./../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

export default class CharacterClasses extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper regexcc">
                                <h4>מחלקת תווים</h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>סט תווים</b>
                                    <span>
                                        עם "מחלקת תווים", הנקראת גם "סט תווים", אתה יכול להגיד למנוע הרקסים להתאים רק אחת מתוך מספר דמויות. כל שעליך לעשות הוא למקם את התווים שאתה רוצה להתאים בין סוגריים מרובעים. אם אתה רוצה להתאים a או e, השתמש ב-[ae]. אתה יכול להשתמש בזה ב-gr[ae]y כדי להתאים לאפור או אפור. שימושי מאוד אם אינך יודע אם המסמך שאתה מחפש כתוב באנגלית אמריקאית או בריטית.
                                    </span>

                                    <p>
                                        <b>Regular expression Character classes</b>
                                        <span>במודלים של סייבר ישנו המודל של brute force attack, שבו מנסים באמצעות קובץ סיסמאות לנסות לפרוץ לחשבון מסוים, יש מושג שנקרא מדיניות הסיסמה כגון : הסיסמה חייבת להכיל 2 תווים אלפאנומריים ספרה , תו מיוחד וכולי, לכן ישנם מחלקות של תבניות כפי שנסקור בטבלה הבאה:</span>

                                        <div id="linux-components">
                                            <Table className="styled-table" style={{ width: '72%' }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" style={{ width: '31%' }}> תיאור </TableCell>
                                                        <TableCell align="center" style={{ width: '31%' }}> מחלקת תווים</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} > אותיות גדולות</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:upper:]] </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >  אותיות קטנות</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}> [[:lower:]]  </TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >רווח כולל שורה חדשה</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:space:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} > התאם תו ספרתי</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:digit:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} > כל ספרה הקסדצימלית</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:xdigit:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו גרפי למעט תווי "מילה". </TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:punct:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו אלפביתי</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:alpha:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>
                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >כל תו אלפאנומרי</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:alnum:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>

                                                <TableBody>

                                                    <TableRow key={`trow`} style={{ backgroundColor: '#1B2534' }}>
                                                        <TableCell align="center" style={{ color: '#7dacb5' }} >התאם תו "מילה" (אלפאנומרי פלוס _)</TableCell>
                                                        <TableCell align="center" style={{ color: '#93E304' }}>  [[:word:]]</TableCell>
                                                    </TableRow>

                                                </TableBody>

                                            </Table>
                                        </div>
                                    </p>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="grep -Eio '[[:alnum:]]{2}:[[:alnum:]]{2}:[[:alnum:]]{2}:[[:alnum:]]{2}:[[:alnum:]]{2}:[[:alnum:]]{2}' ip.txt | sort | uniq " />
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 7
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
