import React, { Component } from 'react';
import './grouping.css';
import companyLogo from './../../../../../assets/Images/regex.png';
import { Consumer } from "../../../../Context";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import RegExCompnent from '../RegExCompnent/RegExCompnent';

export default class Grouping extends Component {


    render() {


        return (
            <Consumer>
                {
                    c => {
                        let theSubjectsAndSubTopicsCompletedIndex = c.subjectsAndSubTopicsCompletedIndex

                        return (
                            <div className="paper grouping" >
                                <h4></h4>
                                <div className="img-div"><img src={companyLogo} /></div>
                                <p>
                                    <b>Grouping in Regular Expression</b>
                                    <span>
                                        קיבוץ הוא תכונה רבת עוצמה של ביטויים רגולריים שיכולים לפשט דפוס מורכב. לדוגמה, אתה יכול להשתמש בקיבוץ כדי להתאים רצפים חוזרים של תווים, כגון מספרי טלפון או כתובות דואר אלקטרוני.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        ב-Regex, כל תת-תבנית המוקפת בסוגריים () נחשבת לקבוצה. לדוגמה, (xyz) יוצר קבוצה התואמת את הרצף המדויק "xyz".
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="18" />
                                <p>
                                    <span>
                                        אתה יכול לשלב קבוצות עם תווים/תווים מטא אחרים בתבנית רגקס. הבא מוצא את קבוצת xyz שאחריה בא x.
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="19" />
                                <p>
                                    <span>
                                        שימו לב שה-x מחוץ לסוגריים ולכן הוא לא נחשב כתו קבוצתי. ביטוי רגיל יתאים ל'xyzx'.

                                        השימוש הבא בשינוי | דמות מטא:
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="20" />
                                <p>
                                    <span>
                                        התבנית שלעיל (yz|zy) מחפשת רצף 'yz' או 'zy' בטקסט.
                                    </span>
                                </p>
                                <p>
                                    <b>לכידת קבוצות והפניות לאחור</b>
                                    <span>
                                        ניתן ללכוד את הקבוצות בדפוס הרקס. הטקסט המותאם בתוך הסוגריים נזכר וניתן להתייחס אליו מאוחר יותר באמצעות הפניות לאחור. זה מאפשר לך לעשות שימוש חוזר בחלקים שנלכדו בתוך אותה דפוס ביטוי רגולרי או להחליף מחרוזת.
                                    </span>

                                    <span>
                                        לדוגמה, תבנית הביטוי הרגולרי (\d&#123;4&#125;)-(\d&#123;2&#125;) תואמת מחרוזת עם מספר דו ספרתי ואחריו מקף, ולאחר מכן מספר בן ארבע ספרות. הסוגריים סביב כל קבוצה לוכדים את הספרות וזוכרים אותם לשימוש מאוחר יותר.
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="21" />
                                <p>
                                    <b>קבוצות שאינן תופסות - Non-Capturing Groups</b>
                                    <span>
                                        אם אינך צריך ללכוד את החלק המותאם, תוכל ליצור קבוצות שאינן לוכדות באמצעות ?: מיד לאחר סוגרי הפתיחה (?: ).
                                    </span>
                                    <span>
                                        קבוצות שאינן לוכדות הופכות את הביטויים הרגולריים שלך ליעילים יותר על ידי הפחתת כמות הזיכרון הדרושה לאחסון קבוצות שנלכדו. עם זאת, לא ניתן להתייחס לקבוצות שאינן לוכדות מאוחר יותר בביטוי הרגולרי או במחרוזות החלפה.
                                    </span>
                                </p>
                                <RegExCompnent key={React.key} patternIndex="22" />
                                <Button variant="contained" onClick={(e) => {
                                    // the first index is the array of objects in state.subjectsAndSubTopics, 
                                    // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                    let subjectsAndSubTopicsIndex = 15
                                    let completedIndex = 9
                                    let subjectsAndSubTopicsCompletedIndex = theSubjectsAndSubTopicsCompletedIndex
                                    let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                    obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                    c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex, theSubjectsAndSubTopicsCompletedIndex)

                                }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
