import React, { Component } from 'react';
import './wc.css';
import companyLogo from './../../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from '../../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../../Context/";
export default class WC extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper medium-display-column wc">
                                <h4>סינון תוכן - wc</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b>wc</b>
                                    <span>
                                        אחרון חביב, לעתים קרובות יהיה שימושי לדעת כמה התאמות מוצלחות יש לנו. כדי להימנע מספירת השורות או התווים באופן ידני, נוכל להשתמש בכלי wc. עם האפשרות "-l" אנו מציינים שרק השורות נספרות.
                                    </span>

                                </p>
                                <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="cat /etc/passwd | grep -v 'false\|nologin' | tr ':' ' | awk ' {print $1, $NF}' | wc -l" />
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 7
                                        let completedIndex = 50
                                        let subjectsAndSubTopicsCompletedIndex = 11

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
