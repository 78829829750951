import React, { Component } from 'react';
import './security.css';
import companyLogo from './../../../../../assets/Images/linux-logo-tr.png';
import ShellTerminal from './../Shell/ShellTerminal/ShellTerminal';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import { Consumer } from "./../../../../Context";
export default class Security extends Component {

    render() {


        return (
            <Consumer>
                {
                    c => {
                        return (
                            <div className="paper security">
                                <h4>אבטחת לינוקס</h4>
                                <div className="img-div"><img src={companyLogo} /></div> 
                                <p>
                                    <b> מערכת הפעלה מעודכנת עם הגרסה החדשה</b>
                                    <span>
                                        לכל מערכות המחשב סיכון מובנה לחדירה. חלקם מהווים סיכון רב יותר מאחרים, כגון שרת אינטרנט הפונה לאינטרנט המארח מספר יישומי רשת מורכבים. מערכות לינוקס גם נוטות פחות לוירוסים המשפיעים על מערכות ההפעלה של Windows ואינן מציגות משטח התקפה גדול כמו מארחים המחוברים לתחום של Active Directory. עם זאת, חיוני שיהיו יסודות מסוימים כדי לאבטח כל מערכת לינוקס.
                                        חשוב גם לבצע ביקורת מעת לעת על המערכת כדי לוודא שלא קיימות בעיות שיכולות להקל על הסלמת הרשאות, כגון גרעין לא מעודכן, בעיות הרשאות משתמש, קבצים הניתנים לכתיבה עולמית ועבודות cron שהוגדרו בצורה שגויה או שירותים שהוגדרו בצורה שגויה. מנהלים רבים שוכחים מהאפשרות שיש לעדכן ידנית גרסאות גרעין מסוימות.
                                    </span>
                                    <ShellTerminal user="<username>@<hostname>" location="[~]" cursor="apt update && apt dist-upgrade" />

                                </p>
                                <p>
                                    <b>חומת אש</b>
                                    <span>
                                        אם כללי חומת האש אינם מוגדרים כראוי ברמת הרשת, אנו יכולים להשתמש בחומת האש של לינוקס ו / או iptables כדי להגביל את התעבורה אל מארח / יציאתו.
                                    </span>
                                </p>
                                <p>
                                    <b>ssh</b>
                                    <span>
                                        אם SSH פתוח בשרת, יש להגדיר את התצורה כדי לא לאפשר כניסה לסיסמה ולא לאפשר למשתמש הבסיס להתחבר באמצעות SSH.
                                    </span>
                                </p>
                                <p>
                                    <b>root user</b>
                                    <span>
                                        חשוב גם להימנע מהתחברות וניהול של המערכת כמשתמש השורש במידת האפשר ומניהול הולם של בקרת הגישה. יש לקבוע את גישת המשתמשים על פי עיקרון הזכאות הפחותה. לדוגמא, אם משתמש צריך להריץ פקודה כשורש, יש לציין פקודה זו בתצורת sudoers במקום לתת להם זכויות סודו מלאות.
                                    </span>
                                </p>
                                <p>
                                    <b>fail2ban</b>
                                    <span>
                                        מנגנון הגנה נפוץ נוסף שניתן להשתמש בו הוא fail2ban. כלי זה מונה את מספר ניסיונות הכניסה שנכשלו, ואם משתמש הגיע למספר המרבי, המארח שניסה להתחבר יטופל כפי שהוגדר.
                                    </span>
                                </p>
                                <p>
                                    <b>(SELinux) או AppArmor</b>
                                    <span>
                                        אפשרות לנעילת מערכות לינוקס נוספות היא לינוקס משופרת אבטחה (SELinux) או AppArmor. זהו מודול אבטחת ליבה שיכול לשמש למדיניות בקרת גישה לאבטחה. ב- SELinux, כל תהליך, קובץ, ספריה ואובייקט מערכת מקבל תווית. כללי מדיניות נוצרים כדי לשלוט על הגישה בין תהליכים ואובייקטים שכותרתו אלה ואוכפים על ידי הגרעין. המשמעות היא שניתן להגדיר גישה שתשליט אילו משתמשים ויישומים יכולים לגשת לאילו משאבים. SELinux מספקת בקרות גישה גרעיניות מאוד, כגון ציון מי יכול להוסיף לקובץ או להזיז אותו.
                                    </span>
                                </p>
                                <p className='list-items'>
                                    <b></b>
                                    <span>הסרה או השבתה של כל השירותים והתוכנות המיותרות</span>
                                    <span>הסרת כל השירותים המסתמכים על מנגנוני אימות לא מוצפנים</span>
                                    <span>ודא ש-NTP מופעל ו- Syslog פועל</span>
                                    <span>ודא שלכל משתמש יש חשבון משלו</span>
                                    <span>לאכוף את השימוש בסיסמאות חזקות</span>
                                    <span>הגדר הזדקנות סיסמה והגביל את השימוש בסיסמאות קודמות</span>
                                    <span>נעילת חשבונות משתמש לאחר כשלים בכניסה</span>
                                    <span>השבת את כל הקבצים הבינאריים של SUID/SGID לא רצויים</span>
                                </p>
                                <p>
                                    <b>מה הלאה</b>
                                    <span>
                                        רשימה זו אינה שלמה, מכיוון שבטיחות אינה מוצר אלא תהליך. המשמעות היא שתמיד יש לנקוט בצעדים ספציפיים כדי להגן על המערכות בצורה טובה יותר, וזה תלוי במנהלי מערכת עד כמה הם מכירים את מערכות ההפעלה שלהם. ככל שהמנהלים מכירים את המערכת טוב יותר, וככל שהם מאומנים יותר, כך אמצעי האבטחה ואמצעי האבטחה שלהם יהיו טובים ובטוחים יותר.
                                    </span>
                                </p>
                                <Button variant="contained" onClick={(e) => {
                                        // the first index is the array of objects in state.subjectsAndSubTopics, 
                                        // the second index is the index in the completed array in the state.subjectsAndSubTopics index
                                        let subjectsAndSubTopicsIndex = 11
                                        let completedIndex = 64
                                        let subjectsAndSubTopicsCompletedIndex = 0

                                        let obj = c.subjectsAndSubTopics[subjectsAndSubTopicsIndex]
                                        obj.completed[subjectsAndSubTopicsCompletedIndex] = faCheckSquare
                                        c.actions.setCompletedSubTopic(subjectsAndSubTopicsIndex, completedIndex)
                                        
                                    }}>Next  <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '13pt', paddingLeft: '5px' }} /></Button>

                            </div>
                        )
                    }
                }
            </Consumer>)
    }

}
